import router from "@/router";
import store from "@/store";
import ACCESS_ENUM from "@/access/accessEnum";
import checkAccess from "@/access/checkAccess";

/**
 * 权限管理
 * 实现自动登录
 */
router.beforeEach(async (to, from, next) => {
    // console.log("登陆用户信息", store.state.user.loginUser);
    let loginUser = store.state.user.loginUser;
    /**
     * 如果之前没登陆过，自动登录
     * 之前没有登陆过则userRole一定为空
     */
    if (!loginUser || !loginUser.userRole || loginUser.userRole === ACCESS_ENUM.NOT_LOGIN) {
        // 异步是为了等待用户登录成功之后，继续执行后续的代码
        await store.dispatch("user/getLoginUser");
        loginUser = store.state.user.loginUser;
    }

    // setTimeout(()=>{loginUser = store.state.user.loginUser;},0)
    // console.log("路由权限", loginUser)
    // 获取路由需要的权限信息，没配置就是无需登录
    const needAccess = (to.meta?.access as string) ?? ACCESS_ENUM.NOT_LOGIN;
    // 要跳转的页面必须要登陆
    if (needAccess !== ACCESS_ENUM.NOT_LOGIN) {
        // 如果没登陆，跳转到登录页面
        if (!loginUser || !loginUser.userRole || loginUser.userRole === ACCESS_ENUM.NOT_LOGIN) {
            next(`/user/login?redirect=${to.fullPath}`);
            // next();
            return;
        }

        // 如果已经登陆了，但是权限不足，那么跳转到无权限页面
        setTimeout(() => {
            if (!checkAccess(loginUser, needAccess)) {
                next("/noAuth");
                return;
            }
        }, 0)
    }
    next();
});
