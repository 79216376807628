<template>
  <div id="basicLayout">
    <a-layout style="min-height: 100vh">
      <a-layout-header class="header">
        <GlobalHeaderWithoutFooter />
      </a-layout-header>
      <a-layout-content class="content">
        <router-view/>
      </a-layout-content>
    </a-layout>
  </div>
</template>

<style scoped>
  #basicLayout {
  }

  #basicLayout .header {
    box-shadow: #eee 1px 1px 5px;
  }
  #basicLayout .content {
    background: linear-gradient(to right, #fefefe, #fff);
  }
</style>
<script>
  import GlobalHeaderWithoutFooter from "@/components/GlobalHeaderWithoutFooter.vue";

  export default {
    components: {GlobalHeaderWithoutFooter},
  };
</script>

