import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { id: "app" }

import BasicLayout from "@/layouts/BasicLayout";
  import {onMounted} from "vue";
  import {useRoute} from "vue-router";
  import BasicLayoutWithoutFooter from "@/layouts/BasicLayoutWithoutFooter.vue";
  import BasicLayoutWithoutMB from "@/layouts/BasicLayoutWithoutMB.vue";
  import zhCN from 'ant-design-vue/es/locale/zh_CN';
  import dayjs from 'dayjs';
  import 'dayjs/locale/zh-cn';
  
export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {

  dayjs.locale('zh-cn');

  const route = useRoute();

  /**
   * todo
   * 全局初始化函数，全局单次调用的代码写
   * 佛祖保佑
   */
  const doInit = () => {

    /*----------------------------以下为核心代码，观看时请保持敬畏之心----------------------------*/
    console.log(
        '/**\n' +
        ' *                             _ooOoo_\n' +
        ' *                            o8888888o\n' +
        ' *                            88" . "88\n' +
        ' *                            (| -_- |)\n' +
        ' *                            O\\  =  /O\n' +
        ' *                         ____/`---\'\\____\n' +
        ' *                       .\'  \\\\|     |//  `.\n' +
        ' *                      /  \\\\|||  :  |||//  \\\n' +
        ' *                     /  _||||| -:- |||||-  \\\n' +
        ' *                     |   | \\\\\\  -  /// |   |\n' +
        ' *                     | \\_|  \'\'\\---/\'\'  |   |\n' +
        ' *                     \\  .-\\__  `-`  ___/-. /\n' +
        ' *                   ___`. .\'  /--.--\\  `. . __\n' +
        ' *                ."" \'<  `.___\\_<|>_/___.\'  >\'"".\n' +
        ' *               | | :  `- \\`.;`\\ _ /`;.`/ - ` : | |\n' +
        ' *               \\  \\ `-.   \\_ __\\ /__ _/   .-` /  /\n' +
        ' *          ======`-.____`-.___\\_____/___.-`____.-\'======\n' +
        ' *                             `=---=\'\n' +
        ' *          ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^\n' +
        ' *                     佛祖保佑        永无BUG\n' +
        ' *            佛曰:\n' +
        ' *                   写字楼里写字间，写字间里程序员；\n' +
        ' *                   程序人员写程序，又拿程序换酒钱。\n' +
        ' *                   酒醒只在网上坐，酒醉还来网下眠；\n' +
        ' *                   酒醉酒醒日复日，网上网下年复年。\n' +
        ' *                   但愿老死电脑间，不愿鞠躬老板前；\n' +
        ' *                   奔驰宝马贵者趣，公交自行程序员。\n' +
        ' *                   别人笑我忒疯癫，我笑自己命太贱；\n' +
        ' *                   不见满街漂亮妹，哪个归得程序员？\n' +
        '*/\n');
    /*----------------------------以上为核心代码，观看时请保持敬畏之心----------------------------*/
  };

  onMounted(() => {
    doInit();
  });

return (_ctx: any,_cache: any) => {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_a_config_provider = _resolveComponent("a-config-provider")!

  return (_openBlock(), _createBlock(_component_a_config_provider, { locale: _unref(zhCN) }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_unref(route).path.startsWith('/user'))
          ? (_openBlock(), _createBlock(_component_router_view, {
              key: 0,
              style: {"background-image":"linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%)","display":"flex !important","margin":"auto 0"}
            }))
          : (_unref(route).path.startsWith('/view/question/'))
            ? (_openBlock(), _createBlock(BasicLayoutWithoutFooter, { key: 1 }))
            : (_unref(route).path.startsWith('/mine') || _unref(route).path.startsWith('/view/other'))
              ? (_openBlock(), _createBlock(BasicLayoutWithoutMB, { key: 2 }))
              : (_unref(route).path.startsWith('/view/post'))
                ? (_openBlock(), _createBlock(BasicLayoutWithoutMB, { key: 3 }))
                : (_openBlock(), _createBlock(_unref(BasicLayout), { key: 4 }))
      ])
    ]),
    _: 1
  }, 8, ["locale"]))
}
}

})