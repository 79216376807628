<template>
  <div id="userLoginView" class="container my-5">
    <div class="left-column d-none d-lg-block" style="background: none">
      <!--<video class="w-100 h-100" src="http://image.niwenjia.cn/work.mp4" autoplay loop muted></video>-->
      <Vue3Lottie
          animation-link="http://image.niwenjia.cn/evluate.json"
          class=""
          height="500px"
          width="500px"
      />
    </div>
    <div class="right-column card p-0 d-flex" style="height: 500px;background-image: linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%);">
      <div class="card-header"><h3>欢迎回家</h3></div>
      <div style="height: 100px;"/>
      <div class="p-2 d-flex justify-content-center align-content-center">
        <a-form
            :model="form"
            auto-label-width
            label-align="left"
            style="max-width: 480px; margin: 0 auto"
            @submit="handleSubmit"
        >
          <a-form-item field="userAccount" label="账号" tooltip="用于登录，类似微信号，不允许重复">
            <a-input v-model="form.userAccount" class="input-group-text" placeholder="请输入账号名称"/>
          </a-form-item>
          <a-form-item field="pwd" label="密码" tooltip="密码不少于 8 位">
            <a-input-password
                v-model="form.userPassword"
                class="input-group-text"
                placeholder="请输入密码"
            />
          </a-form-item>
          <a-form-item>
            <div class="grid-demo-background">
              <a-space :size="16" direction="vertical" style="display: block;">
                <a-row class="grid-demo">
                  <a-col :span="22">
                    <div>
                      <a-button class="rounded-2" html-type="submit" style="width: 240px" type="primary">
                        <span style="font-weight: 600;">登录</span>
                      </a-button>
                      <p class="my-3">没有账号？ <a href="/user/register">点击注册</a></p>
                    </div>
                  </a-col>
                </a-row>
              </a-space>
            </div>
          </a-form-item>
        </a-form>
      </div>
    </div>
  </div>
</template>

<style scoped>
  #userLoginView {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .left-column, .right-column {
    flex: 1;
  }

  .arco-input-wrapper .arco-input.arco-input-size-large {
    /* prettier-ignore */
    padding-top: 6px;
    /* prettier-ignore */
    padding-bottom: 6px;
    font-size: 14px;
    line-height: 1.5715;
    background-color: white;
  }
</style>

<script lang="ts" setup>
  import {reactive} from "vue";
  import {UserControllerService, UserLoginRequest} from "../../../generated";
  import message from "@arco-design/web-vue/es/message";
  import {useRouter} from "vue-router";
  import {useStore} from "vuex";
  import {Vue3Lottie} from "vue3-lottie";

  /**
   * 表单信息
   */
  const form = reactive({
    userAccount: "",
    userPassword: "",
  } as UserLoginRequest);

  const router = useRouter();
  const store = useStore();

  /**
   * 提交表单
   * @param data
   */
  const handleSubmit = async () => {
    const res = await UserControllerService.userLoginUsingPost(form);
    // 登录成功，跳转到主页
    if (res.code === 0) {
      await store.dispatch("user/getLoginUser");
      await router.push({
        path: "/",
        replace: true,
      });
    } else {
      message.error("登陆失败，" + res.message);
    }
  };
</script>
