<template>
  <div class=""> <span style="color: #656464">{{ `© ${year} ${author} ` }}</span>
    <a class="link-underline" href="http://beian.miit.gov.cn/"
                                               style="text-decoration: none; " target="_blank">{{ record }}</a></div>
</template>

<style scoped>
  #footerInfo{
  }
</style>

<script lang="ts" setup>
  let year = new Date().getFullYear();        // 一般都是最新的一年
  let author = '浙江纺织服装职业技术学院23移动2倪文佳'; // 作者名
  let record = '浙ICP备2024116650号-2';      // 备案号
</script>
