import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"
import _imports_0 from '../assets/ang.svg'


const _hoisted_1 = { class: "button" }
const _hoisted_2 = ["src"]

import {useRouter} from "vue-router";
  import {useStore} from "vuex";
  import ACCESS_ENUM from "@/access/accessEnum";
  import {UserControllerService} from "../../generated";

  
export default /*@__PURE__*/_defineComponent({
  __name: 'GlobalHeaderWithoutFooter',
  setup(__props) {

  const router = useRouter();
  const store = useStore();
  // 获取登录用户
  store.dispatch("user/getLoginUser");
  // console.log("user:",store.state.user.loginUser)
  const toLogin = () => {
    // console.log("信息",store.state.user.loginUser)
    if (store.state.user.loginUser.userRole === ACCESS_ENUM.NOT_LOGIN) {
      router.push({
        path: "/user/login",
      });
    } else {
      router.push({
        path: "/mine",
      });
    }
  }
  // const ans = "show me the code!";
  async function logout() {
    await UserControllerService.userLogoutUsingPost();
    await router.push({
      path: "/user/login",
    });
  }
  const toFavourPosts = () => {
    router.push('/view/favours');
  }
  const doMenuClick = (key: string) => {
    router.push({
      path: "/question",
    });
  };

return (_ctx: any,_cache: any) => {
  const _component_a_menu_item = _resolveComponent("a-menu-item")!
  const _component_a_menu = _resolveComponent("a-menu")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_avatar = _resolveComponent("a-avatar")!
  const _component_a_popover = _resolveComponent("a-popover")!
  const _component_a_row = _resolveComponent("a-row")!

  return (_openBlock(), _createBlock(_component_a_row, {
    id: "globalHeaderWF",
    class: "my-1",
    wrap: false,
    align: "center"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_a_col, { flex: "auto" }, {
        default: _withCtx(() => [
          _createVNode(_component_a_menu, {
            mode: "horizontal",
            onMenuItemClick: doMenuClick
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_menu_item, {
                key: "0",
                style: { padding: 0, marginRight: '38px' }
              }, {
                default: _withCtx(() => _cache[0] || (_cache[0] = [
                  _createElementVNode("div", {
                    class: "title-bar",
                    style: {"font-size":"2ch"}
                  }, [
                    _createElementVNode("img", {
                      class: "logo",
                      src: _imports_0
                    }),
                    _createElementVNode("div", { class: "title" }, "题库")
                  ], -1)
                ])),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_a_col, {
        id: "my",
        class: "pointer",
        flex: "180px",
        onClick: toLogin
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_popover, { position: "bottom" }, {
            content: _withCtx(() => [
              _createElementVNode("div", { class: "p-2" }, [
                _createElementVNode("p", {
                  class: "pointer",
                  style: {"z-index":"10"},
                  onClick: toLogin
                }, " 个人主页 "),
                _createElementVNode("p", {
                  class: "pointer",
                  onClick: toFavourPosts
                }, " 我的收藏 "),
                _createElementVNode("p", {
                  class: "pointer text-danger",
                  onClick: logout
                }, " 退出登录 ")
              ])
            ]),
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, [
                _createVNode(_component_a_avatar, { size: 36 }, {
                  default: _withCtx(() => [
                    _createElementVNode("img", {
                      alt: "avatar",
                      src: _unref(store).state.user?.loginUser?.userAvatar
                    }, null, 8, _hoisted_2)
                  ]),
                  _: 1
                }),
                _createTextVNode(" " + _toDisplayString(_unref(store).state.user?.loginUser?.userName ?? "登录/注册"), 1)
              ])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})