<template>
  <div id="OtherUser">
    <!--介绍板块-->
    <div id="banner" class="container text-center p-4 shadow" style="min-width: 80%;">
      <div class="row align-items-center">
        <div v-if="curUser?.userAvatar" class="col-auto d-flex">
          <a-avatar :size="120" :style="{ backgroundColor: '#14C9C9' }" class="bg-primary-subtle shadow" shape="square">
            <img
                :src="curUser.userAvatar"
                alt="avatar"
            />
          </a-avatar>
        </div>
        <div class="col-auto align-middle text-center">
          <span class="name">
            {{ curUser?.userName ?? "没有" }}
          </span>
          <br>
          <span class="intro">
            <span class="text-dark-emphasis"></span>{{ curUser?.userAccount || "undefinedAndNull" }}
          </span>
          <br>
          <p class="intro">{{ curUser?.userProfile ?? "这个逼很赖，没有写简介" }}</p>
        </div>
      </div>
      <!--一行四个-->
      <div class="row mt-2">
        <div class="col-2">
          <span class="details-title">
            AC数量：
          </span>
          <span class="details-content">
            {{ acCount }}
          </span>
        </div>
        <div class="col-2">
          <span class="details-title">
            发帖数量：
          </span>
          <span class="details-content">
            {{ total }}
          </span>
        </div>
      </div>
    </div>
    <!--历史记录板块-->
    <div class=" d-flex container justify-content-center">
      <div class="my-3 w-75 text-center" >
        <div class="card">
          <div class="card-header container text-primary"><h4>{{curUser?.userName+" " ?? '空空 '}}同学的发帖记录</h4></div>
          <div class="container p-2">
            <a-table
                :ref="tableRef"
                :bordered="true"
                :columns="columns"
                :data="myPosts"
                :loading="isLoading"
                :pagination="{
                 pageSize: searchParams.pageSize,
                 current: searchParams.current,
                 total,
                }"
                :tooltip=true
                class="pointer"
                @row-click="toPostPage"
                @page-change="onPageChange"
            >
              <template #title="{record}">
                <span class="title" @click="toPostPage(record)">{{ record.title }}</span>
              </template>
              <template #createTime="{ record }">
                {{ formatDate(new Date(record.createTime)) }}
              </template>
              <template #updateTime="{ record }">
                {{ formatDate(new Date(record.updateTime)) }}
              </template>
              <template #tagList="{record}">
                <span v-if="record.tagList.length === 0"> 啥也木有！ </span>
                <span v-for="tag in record.tagList" :key="tag.id" class="badge text-bg-secondary mx-1"
                      style="font-weight: 500;">{{ tag }}</span>
              </template>
            </a-table>
            <div v-if="myPosts.length < 8">
              <a-empty description="没有更多了" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
  import store from "@/store";
  import {
    PostControllerService,
    PostQueryRequest,
    PostVO,
    QuestionControllerService,
    UserControllerService
  } from "../../../generated";
  import {defineProps, onMounted, ref, withDefaults} from "vue";
  import message from "@arco-design/web-vue/es/message";
  import formatDate from "../../access/Date";
  import {useRouter} from "vue-router";

  const acCount = ref(0);
  const tableRef = ref();
  const curUser = ref<any>(null); // 初始化为 null 或 {}
  const uid = store.state.user.loginUser.id;
  let total = ref(0);
  const router = useRouter();
  const columns = [
    {
      title: "标题",
      slotName: "title",
      ellipsis: true,
    },
    {
      title: "创建时间",
      slotName: "createTime",
    },
    {
      title: '上次修改时间',
      slotName: 'updateTime',
    },
    {
      title: '标签',
      slotName: "tagList",
      ellipsis: true,
    },
  ];

  interface Props {
    id: string;
  }

  const props = withDefaults(defineProps<Props>(), {
    id: () => "",
  });


  const searchParams = ref<PostQueryRequest>({
    searchText: "",
    tags: [],
    pageSize: 8,
    current: 1,
    userId: props.id as any,
  });


  // message.info("id" + props.id);
  const loginUser = store.state.user.loginUser;
  if (props.id === loginUser.id) {
    router.push({
      path: "/mine",
      replace: true
    });
  }

  const myPosts = ref<PostVO[]>([]);
  const isLoading = ref(false);

  const getCurrentUser = async () => {
    const res = await UserControllerService.getUserVoByIdUsingGet(props.id as any);
    if (res.code !== 0) {
      await router.push({
        path: '/404',
        replace: true
      });
      return;
    }
    curUser.value = res.data;
  };

  const loadData = async () => {
    await getCurrentUser();
    isLoading.value = true;
    const acCountRes = await QuestionControllerService.getAcCountByUserIdUsingGet(props.id as any);
    if (acCountRes.code === 0) {
      acCount.value = acCountRes.data ?? 0;
    } else {
      message.error("加载失败，" + acCountRes.message);
    }
    const res = await PostControllerService.listPostVoByPageUsingPost(searchParams.value);
    if (res.code === 0) {
      myPosts.value = res.data.records;
      total.value = res.data.total;
    } else {
      await router.push({
        path: '/404',
        replace: true
      });
    }
    isLoading.value = false;
  };

  const onPageChange = async (page: number) => {
    searchParams.value = {
      ...searchParams.value,
      current: page
    };
    await loadData();
  };

  const toPostPage = (post: PostVO) => {
    router.push({
      path: `/view/post/${post.id}`,
    });
  };

  onMounted(async () => {
    await loadData();
  });
</script>

<style scoped>
  #banner {
    background: #fcfcfc;
    width: 100%;
  }

  #OtherUser {
    height: 90vh;
    width: 100%;
    padding: 15px;
    background: linear-gradient(to bottom, #409df8, #fff);
  }

  .name {
    font-size: 3ch;
    color: #000000;
    font-weight: 600;
    font-family: "Microsoft YaHei", serif;
  }

  .intro {
    font-family: "Microsoft YaHei", serif;
    font-size: 14px;
    color: #6b7280;
  }

  .details-title {
    font-family: "Microsoft YaHei", serif;
    font-size: 1.5ch;
    color: #6b7280;
  }

  .details-content {
    font-family: "Microsoft YaHei", serif;
    font-size: 2ch;
    color: #000000;
  }

  .pointer {
    cursor: pointer;
  }
</style>
