<template>
  <div>
    <Editor id="md"
            :locale="zhHans"
            :mode="mode"
            :plugins="plugins"
            :uploadImages="uploadImage"
            :value="value"
            class="editos"
            @change="handleChange"/>
    <Viewer
        :locale="zhHans"
        :plugins="plugins"
        :tabindex="2"
        :value="value"
        class="viewer"
    ></Viewer>
  </div>
</template>
<style>
  .code-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f3f4f5;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    padding: 10px;
  }

  .code-action {
    display: flex;
    align-items: center;
  }

  .code-btn-copy {
    background-color: #4caf50;
    border: none;
    border-radius: 5px;
    color: #fff;
    cursor: pointer;
    font-size: 14px;
    margin-right: 10px;
    padding: 0 10px;
    font-family: "Microsoft YaHei", serif;
  }

  .code-btn-copy:hover {
    background-color: #3e8e41;
    font-family: "Microsoft YaHei", serif;
  }

  .code-lang {
    color: #7f8c8d;
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
  }

  .code-block {
    background-color: #272822;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    padding: 10px;
  }

  .code-block pre {
    margin: 0;
  }

  .code-block code {
    font-size: 1.5rem;
    font-family: "Microsoft YaHei", serif !important;
  }

</style>
<script lang='ts' setup>
  import gfm from '@bytemd/plugin-gfm'
  import {Editor} from '@bytemd/vue-next'
  import highlight from '@bytemd/plugin-highlight'
  import "highlight.js/styles/default.css"
  import 'bytemd/dist/index.css'
  import 'katex/dist/katex.css'
  import {defineProps, withDefaults} from "vue";
  import 'highlight.js/styles/a11y-light.css'
  import highlightssr from '@bytemd/plugin-highlight-ssr'
  import breaks from '@bytemd/plugin-breaks'
  import footnotes from '@bytemd/plugin-footnotes'
  import frontmatter from '@bytemd/plugin-frontmatter'
  import gemoji from '@bytemd/plugin-gemoji'
  import mediumZoom from '@bytemd/plugin-medium-zoom'
  import zhHans from "bytemd/lib/locales/zh_Hans.json"
  import 'highlight.js/styles/vs.css'
  import 'juejin-markdown-themes/dist/juejin.min.css'
  import math from "@bytemd/plugin-math";
  import type {BytemdPlugin} from 'bytemd'
  import hljs from 'highlight.js'
  import {FileControllerService} from "../../generated";
  import message from "@arco-design/web-vue/es/message";

  /**
   * 定义组件属性类型
   */
  interface Props {
    value: string;
    mode?: string;
    handleChange: (v: string) => void;
  }

  const plugins = [
    gfm(),
    highlight(),
    highlightssr(),
    breaks(),
    frontmatter(),
    footnotes(),
    gemoji(),
    math(),
    mediumZoom(),
    code(),
  ]

  /**
   * 给组件指定初始值
   */
  const props = withDefaults(defineProps<Props>(), {
    value: () => "",
    mode: () => "split",
    handleChange: (v: string) => {
      // console.log(v);
    },
  });

  const uploadImage = async (files: File[]) => {
    // todo: files 获取的图片文件，这里处理逻辑
    // console.log(typeof files)
    const res = await FileControllerService.uploadImageUsingPost(files[0]);
    // console.log(res)
    if(res.code !== 0){
      message.error("上传失败"+res.message);
    }
    return [
      {
        title: files.map((i) => i.name),
        // todo: 现在是小猫
        url: res.data
      }
    ]
  }

  const copyToClipboard = (text: string) => navigator.clipboard.writeText(text)

  function code(): BytemdPlugin {
    return {
      viewerEffect({markdownBody}): void | (() => void) {
        const codes = markdownBody.querySelectorAll('code')
        codes.forEach((code, key) => {
          let className: string = code.className
          let lan = className.replace('language-', '').toUpperCase()
          const parentNode: any = code.parentNode
          parentNode.removeChild(code)
          const fatherBox = document.createElement('div')
          fatherBox.innerHTML =
              '<div class="code-header"><svg xmlns="http://www.w3.org/2000/svg" width="54" height="14" viewBox="0 0 54 14"><g fill="none" fill-rule="evenodd" transform="translate(1 1)"><circle cx="6" cy="6" r="6" fill="#FF5F56" stroke="#E0443E" stroke-width=".5"></circle><circle cx="26" cy="6" r="6" fill="#FFBD2E" stroke="#DEA123" stroke-width=".5"></circle><circle cx="46" cy="6" r="6" fill="#27C93F" stroke="#1AAB29" stroke-width=".5"></circle></g></svg><div class="code-action"><span class="code-btn-copy" id="code-btn-copy-' +
              key +
              '">Copy</span><span class="code-lang">' +
              lan +
              '</span></div></div><div class="code-block"><code id="code-content-' +
              key +
              '" class="code-content- ' +
              code.className +
              '">' +
              code.innerHTML +
              '</code></div>'
          parentNode.appendChild(fatherBox)
          let btn: any = document.getElementById('code-btn-copy-' + key)
          btn.onclick = () => {
            copyToClipboard(code.innerText)
            btn.innerHTML = 'Copied'
            // console.log("Fuck")
            // console.log(key+"  "+code.innerText)
            // console.log(codes[key])
          }
          document.querySelectorAll('code.code-content-').forEach((el: any) => {
            hljs.highlightElement(el)
          })
        })
      },
    }
  }


</script>