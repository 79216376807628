<template>
  <div id="all" class="layout-demo">
    <a-layout class="card">
      <!--<a-layout-sider theme="dark">Sider</a-layout-sider>-->
      <a-layout-content>
        <a-split
            :style="{
        height: 'calc(90vh - 50px)',
        width: '100%',
        }"
            min="80px"
        >
          <template #first>
            <a-typography-paragraph>
              <a-tabs default-active-key="question">
                <a-tab-pane key="question" title="题目">
                  <a-card v-if="question" :title="question.title">
                    <a-descriptions
                        :column="{ xs: 1, md: 2, lg: 3 }"
                        title="判题条件"
                    >
                      <a-descriptions-item label="时间限制：">
                        {{ question.judgeConfig.timeLimit ?? 0 }}ms
                      </a-descriptions-item>
                      <a-descriptions-item label="内存限制：">
                        {{ question.judgeConfig.memoryLimit ?? 0 }}kb
                      </a-descriptions-item>
                      <a-descriptions-item label="堆栈限制：">
                        {{ question.judgeConfig.stackLimit ?? 0 }}kb
                      </a-descriptions-item>
                    </a-descriptions>
                    <MDViewer :value="question.content || ''" style="color: #2f2f33 !important;"/>
                    <template #extra>
                      <a-space wrap>
                        <a-tag
                            v-for="(tag, index) of question.tags"
                            :key="index"
                            :color="getTagColor(tag)"
                        >{{ tag }}
                        </a-tag>
                      </a-space>
                    </template>
                  </a-card>
                </a-tab-pane>
                <a-tab-pane key="comment" title="评论"> 没写...</a-tab-pane>
                <a-tab-pane key="answer" title="官方题解">
                  <a-card v-if="question" :title="question.title">
                    <a-descriptions
                        :column="{ xs: 1, md: 2, lg: 3 }"
                        title="判题条件"
                    >
                      <a-descriptions-item label="时间限制：">
                        {{ question.judgeConfig.timeLimit ?? 0 }}ms
                      </a-descriptions-item>
                      <a-descriptions-item label="内存限制：">
                        {{ question.judgeConfig.memoryLimit ?? 0 }}kb
                      </a-descriptions-item>
                      <a-descriptions-item label="堆栈限制：">
                        {{ question.judgeConfig.stackLimit ?? 0 }}kb
                      </a-descriptions-item>
                    </a-descriptions>
                    <MDViewerAns :value="question.answer || ''" style="min-height: 80vh;color: #2f2f33 !important;"/>
                    <template #extra>
                      <a-space wrap>
                        <a-tag
                            v-for="(tag, index) of question.tags"
                            :key="index"
                            :color="getTagColor(tag)"
                        >{{ tag }}
                        </a-tag>
                      </a-space>
                    </template>
                  </a-card>
                </a-tab-pane>
                <a-tab-pane key="submit" title="我的提交">
                  <table class="table table-hover">
                    <thead class="bg-secondary-subtle">
                    <tr>
                      <th class="th-bg" scope="col">状态</th>
                      <th class="th-bg" scope="col">语言</th>
                      <th class="th-bg" scope="col">运行时间</th>
                      <th class="th-bg" scope="col">运用内存</th>
                      <th class="th-bg" scope="col">提交时间</th>
                    </tr>
                    </thead>
                    <tbody v-for="(submit,key) in list" :key="key">
                    <tr class="pointer" @click="checkHistorySubmit(submit)">
                      <th :style="{ color: getColor(submit.judgeInfo.message) }">{{ submit.judgeInfo.message }}</th>
                      <td>{{ firstUpperCase(submit.language) }}</td>
                      <td>{{ submit.judgeInfo.time }}ms</td>
                      <td>{{ submit.judgeInfo.memory }}kb</td>
                      <td>{{ formatDate(new Date(submit.updateTime)) }}</td>
                    </tr>
                    </tbody>
                  </table>
                  <!--弹出-->
                  <a-modal v-model:visible="historyVisible" :hide-cancel="true" :ok-text="sure" draggable width="62vw">
                    <template #title>
                      <h4 class="my-1" style="color:#1c80f2">历史提交结果</h4>
                    </template>
                    <div v-if="question && historySubmit" class="w-auto">
                      <div class="container rounded-2 p-2 text-nowrap"
                           style="background: #f6f7f9; width: auto !important;">
                        <div class="row">
                          <div class="col">
                            <div class="row-title">题目<br></div>
                            <span>{{ question.title }}</span>
                          </div>
                          <div class="col">
                            <div class="row-title">用户<br></div>
                            <span>
                  {{ store.state.user.loginUser.userAccount }}
                  &nbsp;
                  {{ store.state.user?.loginUser?.userName }}
                </span>
                          </div>
                          <div class="col">
                            <div class="row-title">提交时间<br></div>
                            <span> {{ formatDate(new Date()) }} </span>
                          </div>
                        </div>
                        <div class="row my-4">
                          <div class="col">
                            <div class="row-title">编译器<br></div>
                            <span>{{ firstUpperCase(historySubmit.language) }}</span>
                          </div>
                          <div class="col">
                            <div class="row-title">内存<br></div>
                            <span>{{ historySubmit.judgeInfo.memory }} / {{ question.judgeConfig.memoryLimit }}kb</span>
                          </div>
                          <div class="col">
                            <div class="row-title">用时<br></div>
                            <span> {{ historySubmit.judgeInfo.time }} / {{ question.judgeConfig.timeLimit }}ms </span>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col">
                            <div class="row-title">状态<br></div>
                            <span :style="{ color: getColor(historySubmit.judgeInfo.message) }"
                                  style="font-weight: bold">{{ historySubmit.judgeInfo.message }}
                           </span>
                          </div>
                          <div class="col">
                            <div class="row-title">提交编号<br></div>
                            <span>{{ historySubmit.id }}</span>
                          </div>
                          <div class="col">
                            <div class="row-title">评测时间<br></div>
                            <span> {{ formatDate(new Date(historySubmit.updateTime)) }} </span>
                          </div>
                        </div>
                      </div>
                      <div class="row my-4" style="max-height: 25vh">
                        <h5>提交代码</h5>
                        <MDViewerQuestionSubmit :value="getMarkdownCode(historySubmit.code, historySubmit.language)"
                                                style="max-height: 25vh"/>
                      </div>
                    </div>
                  </a-modal>
                </a-tab-pane>
              </a-tabs>
            </a-typography-paragraph>
          </template>
          <template #second>
            <a-typography-paragraph style="height: calc(90vh - 65px); overflow: hidden">
              <a-form :model="form" layout="inline">
                <a-form-item
                    field="language"
                    label="编程语言"
                    style="min-width: 240px"
                >
                  <a-select
                      v-model="form.language"
                      :style="{ width: '320px'}"
                      placeholder="选择编程语言"
                      style="height: 30px"
                  >
                    <a-option value="cpp">西瓜瓜</a-option>
                    <a-option value="java">爪哇</a-option>
                    <a-option value="python">蟒蛇3</a-option>
                  </a-select>
                </a-form-item>
              </a-form>
              <CodeEditor
                  :handle-change="changeCode"
                  :language="form.language"
                  :value="form.code as string"
                  style="min-height: calc(90vh - 20px);"
              />
            </a-typography-paragraph>
          </template>
        </a-split>
      </a-layout-content>
    </a-layout>
    <!--绝对定位在底部-->
    <a-layout-footer class="container align-items-center " style="min-width: 100vw;">
      <a-button :loading="isLoading" class="btn btn-primary h-100 align-items-center p-0 w-25" type="primary"
                @click="doSubmit">
        <span>提交{{ form.language }}代码</span>
      </a-button>
      <a-modal v-model:visible="visible" :hide-cancel="true" :ok-text="sure" draggable width="62vw">
        <template #title>
          <h4 class="my-1" style="color:#1c80f2">本次提交结果</h4>
        </template>
        <div v-if="question && lastSubmit" class="w-auto">
          <div class="container rounded-2 p-2 text-nowrap" style="background: #f6f7f9; width: auto !important;">
            <div class="row">
              <div class="col">
                <div class="row-title">题目<br></div>
                <span>{{ question.title }}</span>
              </div>
              <div class="col">
                <div class="row-title">用户<br></div>
                <span>
                  {{ store.state.user.loginUser.userAccount }}
                  &nbsp;
                  {{ store.state.user?.loginUser?.userName }}
                </span>
              </div>
              <div class="col">
                <div class="row-title">提交时间<br></div>
                <span> {{ formatDate(new Date()) }} </span>
              </div>
            </div>
            <div class="row my-4">
              <div class="col">
                <div class="row-title">编译器<br></div>
                <span>{{ firstUpperCase(form.language) }}</span>
              </div>
              <div class="col">
                <div class="row-title">内存<br></div>
                <span>{{ lastSubmit.judgeInfo.memory }} / {{ question.judgeConfig.memoryLimit }}kb</span>
              </div>
              <div class="col">
                <div class="row-title">用时<br></div>
                <span> {{ lastSubmit.judgeInfo.time }} / {{ question.judgeConfig.timeLimit }}ms </span>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <div class="row-title">状态<br></div>
                <span :style="{ color: getColor(lastSubmit.judgeInfo.message) }"
                      style="font-weight: bold">{{ lastSubmit.judgeInfo.message }}
                </span>
              </div>
              <div class="col">
                <div class="row-title">提交编号<br></div>
                <span>{{ curSubId }}</span>
              </div>
              <div class="col">
                <div class="row-title">评测时间<br></div>
                <span> {{ formatDate(new Date()) }} </span>
              </div>
            </div>
          </div>
          <div v-if="form.code && form.language" class="row my-4">
            <h5>提交代码</h5>
            <MDViewerQuestionSubmit :value=" getMarkdownCode(form.code, form.language) "/>
          </div>
        </div>
      </a-modal>
    </a-layout-footer>
  </div>
</template>

<script lang="ts" setup>
  import {defineProps, onMounted, ref, withDefaults} from "vue";
  import message from "@arco-design/web-vue/es/message";
  import CodeEditor from "@/components/CodeEditor.vue";
  import {
    QuestionControllerService,
    QuestionSubmitAddRequest,
    QuestionSubmitControllerService,
    QuestionVO,
  } from "../../../generated";
  import MDViewer from "@/components/MDViewer.vue";
  import {Message} from "@arco-design/web-vue";
  import MDViewerAns from "@/components/MDViewerAns.vue";
  import store from "../../store";
  import MDViewerQuestionSubmit from "@/components/MDViewerQuestionSubmit.vue";
  import formatDate from "../../access/Date";

  const visible = ref(false);
  const sure = ref("确认");

  interface JudgeInfo {
    message: string;
    memory: string;
    time: string;
  }

  interface QuestionSubmitVO {
    id: string;
    language: string;
    code: string;
    judgeInfo: JudgeInfo;
    questionId: string;
    userId: string;
    createTime: string;
    updateTime: string;
  }

  interface Props {
    id: string;
  }

  const props = withDefaults(defineProps<Props>(), {
    id: () => "",
  });

  const question = ref<QuestionVO>();
  const isLoading = ref(false);
  const curSubId = ref(1);
  const uid = store.state.user.loginUser.id;
  const list = ref<QuestionSubmitVO[]>([]);
  const lastSubmit = ref<QuestionSubmitVO>();

  const loadData = async () => {
    const res = await QuestionControllerService.getQuestionVoByIdUsingGet(
        props.id as any
    );
    if (res.code === 0) {
      question.value = res.data;
    } else {
      message.error("加载失败，" + res.message);
    }
    const pageRes = await QuestionSubmitControllerService.listQuestionSubmitByPageUsingPost(
        {
          current: 1,
          pageSize: 20,
          questionId: question.value?.id,
          userId: uid,
        });
    if (pageRes.code === 0) {
      list.value = pageRes.data?.records;
      if (list.value && list.value.length > 0) {
        lastSubmit.value = list.value[0];
        if (lastSubmit.value.judgeInfo.memory == null) {
          // message.error("lastSubmit");
          lastSubmit.value.judgeInfo = {
            message: "COMPILE ERROR",
            memory: "0",
            time: "0",
          };
        }
      }
    } else {
      Message.error("历史记录加载失败，" + pageRes.message);
    }
  };


  const form = ref<QuestionSubmitAddRequest>({
    language: "cpp",
    code: "",
    questionId: parseInt(props.id) as number,
  });

  /**
   * 提交代码
   */
  const doSubmit = async () => {

    if (!question.value?.id) {
      Message.error("题目不存在");
      return;
    }
    isLoading.value = true;
    const res = await QuestionSubmitControllerService.doQuestionSubmitUsingPost({
      ...form.value,
      questionId: question.value.id as number,
    });
    if (res.code === 0) {
      // Message.success("提交成功");
      curSubId.value = res.data;
      const pageRes = await QuestionSubmitControllerService.listQuestionSubmitByPageUsingPost(
          {
            current: 1,
            pageSize: 20,
            questionId: question.value?.id,
            userId: uid,
          });
      if (pageRes.code === 0) {
        list.value = pageRes.data?.records;
        if (list.value && list.value.length > 0) {
          lastSubmit.value = list.value[0];
          if (lastSubmit.value.judgeInfo.memory == null) {
            // message.error("lastSubmit");
            lastSubmit.value.judgeInfo = {
              message: "COMPILE ERROR",
              memory: "0",
              time: "0",
            };
          }
        }
        // console.log(lastSubmit)
      } else {
        Message.error("提交失败，" + pageRes.message);
      }
      visible.value = true;
    } else {
      Message.error("提交失败，" + res.message);
    }
    isLoading.value = false;
  };

  const getColor = (message: string | null) => {
    if (!message) {
      return "black";
    }
    if (message.includes("Accepted")) {
      return "green";
    } else if (message.includes("Wrong Answer")) {
      return "red";
    } else if (message.includes("Time Limit Exceeded")) {
      return "orange";
    } else if (message.includes("Memory Limit Exceeded")) {
      return "#2e468c";
    } else if (message.includes("Runtime Error")) {
      return "blue";
    } else if (message.includes("System Error")) {
      return "blue";
    }
    // 编译错误
    return "#8d44ad";
  }

  /**
   * 页面加载时，请求数据
   */
  onMounted(async () => {
    await loadData();
    // 获取.arco-card-body
    // console.log("fuck"+store.state.user.loginUser.userAccount)
  });

  const changeCode = (value: string) => {
    // console.log(value)
    form.value.code = value;
  };

  const getTagColor = (tag: string) => {
    switch (tag) {
      case '简单':
        return 'green';
      case '中等':
        return 'orangered';
      case '困难':
        return 'red';
      default:
        return 'magenta';
    }
  }

  const getMarkdownCode = (code: string, language: string) => {
    return "```" + language + '\n' + code + "\n```";
  }
  const firstUpperCase = (str: string) => {
    return str.charAt(0).toUpperCase() + str.substring(1);
  }
  // 历史提交详情查看
  const historySubmit = ref<QuestionSubmitVO>();
  const historyVisible = ref(false);
  const checkHistorySubmit = (item: QuestionSubmitVO) => {
    historySubmit.value = item;
    historyVisible.value = true;
  }
</script>

<style scoped>
  .layout-demo :deep(.arco-layout-footer) {
    display: flex;
    height: 4vh;
    position: fixed;
    bottom: 0;
    width: 100%;
    background: #eeeeee;
    box-shadow: #eee 4px 4px 10px;
    align-content: center;
    justify-content: flex-end;
  }

  .layout-demo :deep(.arco-layout-sider) {
    background-color: var(--color-primary-light-3);
  }

  #basicLayout .content[data-v-0949dcaa] {
    padding: 0;
  }

  .modal {
    z-index: 9999 !important;
  }

  .th-bg {
    background-color: #f8f9fa;
  }

  .pointer {
    cursor: pointer;
  }
</style>