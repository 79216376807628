<template>
  <div id="addQuestion" class="" style="margin-top: 50px;">
    <a-form :model="form" auto-label-width class="h-100 w-50 card  shadow">
      <h1 class="card-header" style="text-align: center">修改资料</h1>
      <div class="p-2">
        <a-form-item class="align-content-start" field="userName" label="用户头像">
          <a-upload
              :fileList="file ? [file] : []"
              :show-file-list="false"
              image-preview
              list-type="picture-card"
              @change="onChange"
              @progress="onProgress"
          >
            <template #upload-button>
              <div
                  :class="`arco-upload-list-item${
                  file && file.status === 'error' ? ' arco-upload-list-item-error' : ''
                }`"
              >
                <div
                    v-if="file && file.url"
                    class="arco-upload-list-picture custom-upload-avatar"
                >
                  <img :src="file.url"/>
                  <div class="arco-upload-list-picture-mask">
                    <IconEdit/>
                  </div>
                  <a-progress
                      v-if="file.status === 'uploading' && file.percent < 100"
                      :percent="file.percent"
                      :style="{
                    position: 'absolute',
                    left: '50%',
                    top: '50%',
                    transform: 'translateX(-50%) translateY(-50%)',
                  }"
                      size="mini"
                      type="circle"
                  />
                </div>
                <div v-else class="arco-upload-picture-card">
                  <div class="arco-upload-picture-card-text">
                    <IconPlus/>
                    <div style="margin-top: 10px; font-weight: 600">新头像</div>
                  </div>
                </div>
              </div>
            </template>
          </a-upload>
        </a-form-item>
        <a-form-item class="my-3" field="userName" label="用户名称">
          <a-input v-model="form.userName" placeholder="输入名字"/>
        </a-form-item>
        <a-form-item class="my-3" field="userProfile" label="用户简介">
          <a-input v-model="form.userProfile" placeholder="输入简介"/>
        </a-form-item>
        <div class="center">
          <a-button html-type="submit" type="primary" @click="doSubmit">确认修改</a-button>
        </div>
      </div>
    </a-form>
  </div>
</template>

<script lang="ts" setup>
  import {IconEdit, IconPlus} from '@arco-design/web-vue/es/icon';
  import {useRouter} from "vue-router";
  import store from "@/store";
  import {ref} from "vue";
  import {FileControllerService, UserControllerService} from "../../../generated";
  import message from "@arco-design/web-vue/es/message";

  const router = useRouter();
  const form = ref({
    userName: store.state.user.loginUser.userName,
    userAvatar: store.state.user.loginUser.userAvatar,
    userProfile: store.state.user.loginUser.userProfile,
  });

  const doSubmit = async () => {
    const res = await UserControllerService.updateMyUserUsingPost(form.value);
    if (res.code === 0) {
      if (res.data == true) {
        message.success("修改成功");
        await router.push({
          path: "/mine",
        });
      }
    }
  }

  const file = ref();
  const onChange = async (_: any, currentFile: any) => {
    const res = await FileControllerService.uploadImageUsingPost(currentFile.file);
    // console.log("地址是： " + res.data)
    if (!res) {
      message.error("上传失败")
      return;
    }
    // message.success("上传成功")
    form.value.userAvatar = res.data;
  };
  const onProgress = (currentFile: any) => {
    file.value = currentFile;
  };
</script>

<style scoped>

</style>