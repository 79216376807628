import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, unref as _unref, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["onClick"]
const _hoisted_2 = ["onClick"]
const _hoisted_3 = {
  key: 0,
  class: "w-auto"
}
const _hoisted_4 = {
  class: "container rounded-2 p-2 text-nowrap",
  style: {"background":"#f6f7f9","width":"auto !important"}
}
const _hoisted_5 = { class: "row" }
const _hoisted_6 = { class: "col" }
const _hoisted_7 = { class: "col" }
const _hoisted_8 = { class: "col" }
const _hoisted_9 = { class: "row my-4" }
const _hoisted_10 = { class: "col" }
const _hoisted_11 = { class: "col" }
const _hoisted_12 = { class: "col" }
const _hoisted_13 = { class: "row" }
const _hoisted_14 = { class: "col" }
const _hoisted_15 = { class: "col" }
const _hoisted_16 = { class: "col" }
const _hoisted_17 = {
  class: "row my-4",
  style: {"max-height":"35vh"}
}

import {onMounted, ref} from "vue";
  import {QuestionSubmitControllerService, QuestionVO, UserVO,} from "../../../generated";
  import {Message} from "@arco-design/web-vue";
  import MDViewerQuestionSubmit from "@/components/MDViewerQuestionSubmit.vue";
  import formatDate from "../../access/Date";
  import {useRouter} from "vue-router";

  interface JudgeInfo {
    message: string;
    memory: string;
    time: string;
  }

  interface QuestionSubmitVO {
    id: string;
    language: string;
    code: string;
    judgeInfo: JudgeInfo;
    questionId: string;
    userVO: any;
    questionVO: any;
    userId: string;
    createTime: string;
    updateTime: string;
  }

  
export default /*@__PURE__*/_defineComponent({
  __name: 'QuestionSubmitView',
  setup(__props) {

  const columns = [
    {
      title: "状态",
      slotName: "state",
    },
    {
      title: "题目",
      slotName: "question",
    },
    {
      title: "用户",
      slotName: "user",
    },
    {
      title: "语言",
      slotName: "language",
    },
    {
      title: "运行时间",
      slotName: "runTime",
    },
    {
      title: "运行内存",
      slotName: "memory",
    },
    {
      title: "提交时间",
      slotName: "time",
    },
  ];

  const total = ref(0);
  const sure = ref("确认");
  const isLoading = ref(true);

  const question = ref<QuestionVO>();
  const dataList = ref<QuestionSubmitVO[]>([]);
  const lastSubmit = ref<QuestionSubmitVO>();

  const searchParams = ref({
    questionId: "",
    userId: "",
    current: 1,
    pageSize: 15,
  });
  const loadData = async () => {
    const pageRes = await QuestionSubmitControllerService.listQuestionSubmitByPageUsingPost(searchParams.value as any);
    if (pageRes.code === 0) {
      dataList.value = pageRes.data?.records;
      total.value = pageRes.data.total;
      if (dataList.value && dataList.value.length > 0) {
        lastSubmit.value = dataList.value[0];
        if (lastSubmit.value.judgeInfo.memory == null || lastSubmit.value.judgeInfo.time == null) {
          // message.error("lastSubmit");
          lastSubmit.value.judgeInfo = {
            message: "COMPILE ERROR",
            memory: "0",
            time: "0",
          };
        }
      }
    } else {
      Message.error("提交记录加载失败，" + pageRes.message);
    }
    isLoading.value = false;
  };

  const getColor = (message: string | null) => {
    if (!message) {
      return "black";
    }
    if (message.includes("Accepted")) {
      return "green";
    } else if (message.includes("Wrong Answer")) {
      return "red";
    } else if (message.includes("Time Limit Exceeded")) {
      return "orange";
    } else if (message.includes("Memory Limit Exceeded")) {
      return "#2e468c";
    } else if (message.includes("Runtime Error")) {
      return "blue";
    } else if (message.includes("System Error")) {
      return "blue";
    }
    // 编译错误
    return "#8d44ad";
  }

  /**
   * 页面加载时，请求数据
   */
  onMounted(async () => {
    await loadData();
  });

  const getMarkdownCode = (code: string, language: string) => {
    if (!code || !language) {
      return '# 你不许偷看！';
    }
    return "```" + language + '\n' + code + "\n```";
  }
  const firstUpperCase = (str: string) => {
    return str.charAt(0).toUpperCase() + str.substring(1);
  }
  // 历史提交详情查看
  const historySubmit = ref<QuestionSubmitVO>();
  const historyVisible = ref(false);
  const checkHistorySubmit = (item: QuestionSubmitVO) => {
    historySubmit.value = item;
    historyVisible.value = true;
  }

  const onPageChange = async (page: number) => {
    isLoading.value = true;
    searchParams.value = {
      ...searchParams.value,
      current: page
    }
    await loadData();
  }

  /**
   * 确认搜索，重新加载数据
   */
  const doSubmit = () => {
    // 这里需要重置搜索页号
    searchParams.value = {
      ...searchParams.value,
      current: 1,
    };
  };

  const router = useRouter();
  const toQuestion = (question: QuestionVO) => {
    router.push({
      path: `/view/question/${question.id}`,
    });
  }
  const toOtherUser = (user: UserVO) => {
    router.push({
      path: `/view/other/${user.id}`,
    });
  }


return (_ctx: any,_cache: any) => {
  const _component_a_table = _resolveComponent("a-table")!
  const _component_a_modal = _resolveComponent("a-modal")!
  const _component_a_tab_pane = _resolveComponent("a-tab-pane")!

  return (_openBlock(), _createBlock(_component_a_tab_pane, {
    key: "submit",
    class: "container",
    title: "我的提交"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_a_table, {
        columns: columns,
        data: dataList.value,
        loading: isLoading.value,
        pagination: {
          pageSize: searchParams.value.pageSize,
          current: searchParams.value.current,
          total: total.value,
          showTotal: false
        },
        class: "pointer",
        onCellClick: checkHistorySubmit,
        onPageChange: onPageChange
      }, {
        state: _withCtx(({record}) => [
          _createElementVNode("span", {
            style: _normalizeStyle([{ color: getColor(record.judgeInfo.message) }, {"font-weight":"600"}])
          }, _toDisplayString(record.judgeInfo.message), 5)
        ]),
        question: _withCtx(({record}) => [
          _createElementVNode("a", {
            class: "pointer link-offset-2 link-underline link-underline-opacity-0",
            style: {"color":"#1c80f2","font-weight":"500"},
            onClick: (event) => {event.stopPropagation(); toQuestion(record.questionVO)}
          }, _toDisplayString(record.questionVO.title), 9, _hoisted_1)
        ]),
        user: _withCtx(({ record }) => [
          _createElementVNode("a", {
            class: "pointer link-offset-2 link-underline link-underline-opacity-0",
            style: {"color":"#1c80f2","font-weight":"500"},
            onClick: (event) => {event.stopPropagation();  toOtherUser(record.userVO) }
          }, _toDisplayString(record.userVO.userName), 9, _hoisted_2)
        ]),
        language: _withCtx(({ record }) => [
          _createElementVNode("span", null, _toDisplayString(firstUpperCase(record.language)), 1)
        ]),
        runTime: _withCtx(({record}) => [
          _createElementVNode("span", null, _toDisplayString(record.judgeInfo.time) + "ms", 1)
        ]),
        memory: _withCtx(({record}) => [
          _createElementVNode("span", null, _toDisplayString(record.judgeInfo.memory) + "KB", 1)
        ]),
        time: _withCtx(({record}) => [
          _createElementVNode("span", null, _toDisplayString(_unref(formatDate)(new Date(record.createTime))), 1)
        ]),
        _: 1
      }, 8, ["data", "loading", "pagination"]),
      _createVNode(_component_a_modal, {
        visible: historyVisible.value,
        "onUpdate:visible": _cache[0] || (_cache[0] = ($event: any) => ((historyVisible).value = $event)),
        "hide-cancel": true,
        "ok-text": sure.value,
        draggable: "",
        width: "62vw"
      }, {
        title: _withCtx(() => _cache[1] || (_cache[1] = [
          _createElementVNode("h4", {
            class: "my-1",
            style: {"color":"#1c80f2"}
          }, "历史提交结果", -1)
        ])),
        default: _withCtx(() => [
          (historySubmit.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("div", _hoisted_5, [
                    _createElementVNode("div", _hoisted_6, [
                      _cache[2] || (_cache[2] = _createElementVNode("div", { class: "row-title" }, [
                        _createTextVNode("题目"),
                        _createElementVNode("br")
                      ], -1)),
                      _createElementVNode("span", null, _toDisplayString(historySubmit.value?.questionVO.title), 1)
                    ]),
                    _createElementVNode("div", _hoisted_7, [
                      _cache[3] || (_cache[3] = _createElementVNode("div", { class: "row-title" }, [
                        _createTextVNode("用户"),
                        _createElementVNode("br")
                      ], -1)),
                      _createElementVNode("span", null, _toDisplayString(historySubmit.value.userVO.userName), 1)
                    ]),
                    _createElementVNode("div", _hoisted_8, [
                      _cache[4] || (_cache[4] = _createElementVNode("div", { class: "row-title" }, [
                        _createTextVNode("提交时间"),
                        _createElementVNode("br")
                      ], -1)),
                      _createElementVNode("span", null, _toDisplayString(_unref(formatDate)(new Date(historySubmit.value.createTime))), 1)
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_9, [
                    _createElementVNode("div", _hoisted_10, [
                      _cache[5] || (_cache[5] = _createElementVNode("div", { class: "row-title" }, [
                        _createTextVNode("编译器"),
                        _createElementVNode("br")
                      ], -1)),
                      _createElementVNode("span", null, _toDisplayString(firstUpperCase(historySubmit.value.language)), 1)
                    ]),
                    _createElementVNode("div", _hoisted_11, [
                      _cache[6] || (_cache[6] = _createElementVNode("div", { class: "row-title" }, [
                        _createTextVNode("内存"),
                        _createElementVNode("br")
                      ], -1)),
                      _createElementVNode("span", null, _toDisplayString(historySubmit.value.judgeInfo.memory) + " / " + _toDisplayString(historySubmit.value.questionVO.judgeConfig.memoryLimit) + "kb", 1)
                    ]),
                    _createElementVNode("div", _hoisted_12, [
                      _cache[7] || (_cache[7] = _createElementVNode("div", { class: "row-title" }, [
                        _createTextVNode("用时"),
                        _createElementVNode("br")
                      ], -1)),
                      _createElementVNode("span", null, _toDisplayString(historySubmit.value.judgeInfo.time) + " / " + _toDisplayString(historySubmit.value.questionVO.judgeConfig.timeLimit) + "ms ", 1)
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_13, [
                    _createElementVNode("div", _hoisted_14, [
                      _cache[8] || (_cache[8] = _createElementVNode("div", { class: "row-title" }, [
                        _createTextVNode("状态"),
                        _createElementVNode("br")
                      ], -1)),
                      _createElementVNode("span", {
                        style: _normalizeStyle([{ color: getColor(historySubmit.value.judgeInfo.message) }, {"font-weight":"bold"}])
                      }, _toDisplayString(historySubmit.value.judgeInfo.message), 5)
                    ]),
                    _createElementVNode("div", _hoisted_15, [
                      _cache[9] || (_cache[9] = _createElementVNode("div", { class: "row-title" }, [
                        _createTextVNode("提交编号"),
                        _createElementVNode("br")
                      ], -1)),
                      _createElementVNode("span", null, _toDisplayString(historySubmit.value.id), 1)
                    ]),
                    _createElementVNode("div", _hoisted_16, [
                      _cache[10] || (_cache[10] = _createElementVNode("div", { class: "row-title" }, [
                        _createTextVNode("评测时间"),
                        _createElementVNode("br")
                      ], -1)),
                      _createElementVNode("span", null, _toDisplayString(_unref(formatDate)(new Date(historySubmit.value.updateTime))), 1)
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_17, [
                  _cache[11] || (_cache[11] = _createElementVNode("h5", null, "提交代码", -1)),
                  _createVNode(MDViewerQuestionSubmit, {
                    value: getMarkdownCode(historySubmit.value.code, historySubmit.value.language),
                    style: {"max-height":"35vh"}
                  }, null, 8, ["value"])
                ])
              ]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["visible", "ok-text"])
    ]),
    _: 1
  }))
}
}

})