const CodeInitEnum = {
    java: "public class Main{\n" +
        "    public static void main(String args[]){\n" +
        "        \n" +
        "    }\n" +
        "}",
    cpp: "#include <iostream>\n" +
         "int main()\n{\n" +
         "    \n" +
         "}",
    python:""
};


type Language = keyof typeof CodeInitEnum;
const getCodeInit = (language:Language) => {
    return CodeInitEnum[language] as string;
}

export { getCodeInit };
export default CodeInitEnum;