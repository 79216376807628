import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '../assets/ang.svg'


const _hoisted_1 = { id: "userLayout" }
const _hoisted_2 = { class: "" }
const _hoisted_3 = {
  class: "link-underline",
  style: {"text-decoration":"none"},
  href: "http://beian.miit.gov.cn/",
  target: "_blank"
}


export default /*@__PURE__*/_defineComponent({
  __name: 'UserLayout',
  setup(__props) {

  let year = new Date().getFullYear();        // 一般都是最新的一年
  let author = '浙江纺织服装职业技术学院23移动2倪文佳';					// 作者名
  let record = '浙ICP备2024116650号-2';			// 备案号

return (_ctx: any,_cache: any) => {
  const _component_a_space = _resolveComponent("a-space")!
  const _component_a_layout_header = _resolveComponent("a-layout-header")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_a_layout_content = _resolveComponent("a-layout-content")!
  const _component_a_layout_footer = _resolveComponent("a-layout-footer")!
  const _component_a_layout = _resolveComponent("a-layout")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_a_layout, { style: {"min-height":"100vh"} }, {
      default: _withCtx(() => [
        _createVNode(_component_a_layout_header, { class: "header" }, {
          default: _withCtx(() => [
            _createVNode(_component_a_space, null, {
              default: _withCtx(() => _cache[0] || (_cache[0] = [
                _createElementVNode("img", {
                  class: "logo",
                  src: _imports_0
                }, null, -1),
                _createElementVNode("div", { style: {"font-weight":"bold","font-size":"3ch"} }, "编程题阶", -1)
              ])),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_a_layout_content, { class: "content" }, {
          default: _withCtx(() => [
            _createVNode(_component_router_view)
          ]),
          _: 1
        }),
        _createVNode(_component_a_layout_footer, { class: "footer" }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              _createTextVNode(_toDisplayString(`© ${_unref(year)} ${_unref(author)} `), 1),
              _createElementVNode("a", _hoisted_3, _toDisplayString(_unref(record)), 1)
            ])
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}
}

})