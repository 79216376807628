import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, withCtx as _withCtx, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = {
  id: "addQuestion",
  class: "",
  style: {"margin-top":"50px"}
}
const _hoisted_2 = { class: "p-2" }
const _hoisted_3 = {
  key: 0,
  class: "arco-upload-list-picture custom-upload-avatar"
}
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "arco-upload-list-picture-mask" }
const _hoisted_6 = {
  key: 1,
  class: "arco-upload-picture-card"
}
const _hoisted_7 = { class: "arco-upload-picture-card-text" }
const _hoisted_8 = { class: "center" }

import {IconEdit, IconPlus} from '@arco-design/web-vue/es/icon';
  import {useRouter} from "vue-router";
  import store from "@/store";
  import {ref} from "vue";
  import {FileControllerService, UserControllerService} from "../../../generated";
  import message from "@arco-design/web-vue/es/message";

  
export default /*@__PURE__*/_defineComponent({
  __name: 'UpdateMyView',
  setup(__props) {

  const router = useRouter();
  const form = ref({
    userName: store.state.user.loginUser.userName,
    userAvatar: store.state.user.loginUser.userAvatar,
    userProfile: store.state.user.loginUser.userProfile,
  });

  const doSubmit = async () => {
    const res = await UserControllerService.updateMyUserUsingPost(form.value);
    if (res.code === 0) {
      if (res.data == true) {
        message.success("修改成功");
        await router.push({
          path: "/mine",
        });
      }
    }
  }

  const file = ref();
  const onChange = async (_: any, currentFile: any) => {
    const res = await FileControllerService.uploadImageUsingPost(currentFile.file);
    // console.log("地址是： " + res.data)
    if (!res) {
      message.error("上传失败")
      return;
    }
    // message.success("上传成功")
    form.value.userAvatar = res.data;
  };
  const onProgress = (currentFile: any) => {
    file.value = currentFile;
  };

return (_ctx: any,_cache: any) => {
  const _component_a_progress = _resolveComponent("a-progress")!
  const _component_a_upload = _resolveComponent("a-upload")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_form = _resolveComponent("a-form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_a_form, {
      model: form.value,
      "auto-label-width": "",
      class: "h-100 w-50 card shadow"
    }, {
      default: _withCtx(() => [
        _cache[4] || (_cache[4] = _createElementVNode("h1", {
          class: "card-header",
          style: {"text-align":"center"}
        }, "修改资料", -1)),
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_a_form_item, {
            class: "align-content-start",
            field: "userName",
            label: "用户头像"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_upload, {
                fileList: file.value ? [file.value] : [],
                "show-file-list": false,
                "image-preview": "",
                "list-type": "picture-card",
                onChange: onChange,
                onProgress: onProgress
              }, {
                "upload-button": _withCtx(() => [
                  _createElementVNode("div", {
                    class: _normalizeClass(`arco-upload-list-item${
                  file.value && file.value.status === 'error' ? ' arco-upload-list-item-error' : ''
                }`)
                  }, [
                    (file.value && file.value.url)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                          _createElementVNode("img", {
                            src: file.value.url
                          }, null, 8, _hoisted_4),
                          _createElementVNode("div", _hoisted_5, [
                            _createVNode(_unref(IconEdit))
                          ]),
                          (file.value.status === 'uploading' && file.value.percent < 100)
                            ? (_openBlock(), _createBlock(_component_a_progress, {
                                key: 0,
                                percent: file.value.percent,
                                style: {
                    position: 'absolute',
                    left: '50%',
                    top: '50%',
                    transform: 'translateX(-50%) translateY(-50%)',
                  },
                                size: "mini",
                                type: "circle"
                              }, null, 8, ["percent"]))
                            : _createCommentVNode("", true)
                        ]))
                      : (_openBlock(), _createElementBlock("div", _hoisted_6, [
                          _createElementVNode("div", _hoisted_7, [
                            _createVNode(_unref(IconPlus)),
                            _cache[2] || (_cache[2] = _createElementVNode("div", { style: {"margin-top":"10px","font-weight":"600"} }, "新头像", -1))
                          ])
                        ]))
                  ], 2)
                ]),
                _: 1
              }, 8, ["fileList"])
            ]),
            _: 1
          }),
          _createVNode(_component_a_form_item, {
            class: "my-3",
            field: "userName",
            label: "用户名称"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_input, {
                modelValue: form.value.userName,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((form.value.userName) = $event)),
                placeholder: "输入名字"
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_a_form_item, {
            class: "my-3",
            field: "userProfile",
            label: "用户简介"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_input, {
                modelValue: form.value.userProfile,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((form.value.userProfile) = $event)),
                placeholder: "输入简介"
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createElementVNode("div", _hoisted_8, [
            _createVNode(_component_a_button, {
              "html-type": "submit",
              type: "primary",
              onClick: doSubmit
            }, {
              default: _withCtx(() => _cache[3] || (_cache[3] = [
                _createTextVNode("确认修改")
              ])),
              _: 1
            })
          ])
        ])
      ]),
      _: 1
    }, 8, ["model"])
  ]))
}
}

})