import {createApp} from 'vue';
import App from './App.vue';
import ArcoVue from "@arco-design/web-vue";
import "@arco-design/web-vue/dist/arco.css";
import router from './router';
import store from './store';
import "@/plugins/axios";
import "@/access/index"
import "bytemd/dist/index.css"
import 'ant-design-vue/dist/reset.css';
// 引入Bootstrap CSS
import 'bootstrap/dist/css/bootstrap.min.css'
// 引入Bootstrap JS（bundle版本）
import 'bootstrap/dist/js/bootstrap.bundle.min.js'
import Vue3Lottie from 'vue3-lottie';

const app = createApp(App, {
    productionTip: false
});

app.use(ArcoVue).use(store).use(router).use(Vue3Lottie, { name: 'Vue3Lottie' }).mount('#app');
