import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, vModelText as _vModelText, withDirectives as _withDirectives, unref as _unref, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { id: "home p-2" }
const _hoisted_2 = { class: "w-100 d-flex center" }
const _hoisted_3 = {
  class: "",
  style: {"width":"70%"}
}
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "input-group my-3 w-100 mx-auto" }
const _hoisted_6 = { class: "list-group-item" }
const _hoisted_7 = ["onClick"]
const _hoisted_8 = { key: 0 }
const _hoisted_9 = { key: 1 }
const _hoisted_10 = ["onClick"]
const _hoisted_11 = { key: 0 }
const _hoisted_12 = { key: 1 }
const _hoisted_13 = ["src"]
const _hoisted_14 = ["onClick"]
const _hoisted_15 = ["onClick"]
const _hoisted_16 = ["onClick"]
const _hoisted_17 = {
  class: "card shadow sticky-top d-xl-block d-none",
  style: {"width":"300px","height":"300px","z-index":"0"}
}
const _hoisted_18 = {
  class: "card-header d-flex justify-content-between align-items-center",
  style: {"height":"15%"}
}
const _hoisted_19 = { style: {"font-weight":"bold"} }
const _hoisted_20 = {
  key: 0,
  class: "m-2 badge bg-info"
}
const _hoisted_21 = {
  key: 1,
  class: "m-2 badge bg-warning"
}
const _hoisted_22 = {
  key: 2,
  class: "m-2 badge bg-danger-subtle"
}

import {onMounted, onUnmounted, ref} from 'vue';
  import store from "../store";
  import message from "@arco-design/web-vue/es/message";
  import {useRouter} from "vue-router";
  import {PostControllerService, PostFavourControllerService, PostThumbControllerService} from "../../generated";
  import user from "@/store/user";
  import {IconHeart, IconHeartFill, IconStar, IconStarFill,} from '@arco-design/web-vue/es/icon';
  import formatDate from "@/access/Date";

  interface Post {
    id: number;
    title: string;
    content: string;
    createTime: string;
    tagList: [];
    thumbNum: number;
    favourNum: number;
    user: {
      userAccount: string;
      userName: string;
      userAvatar: string;
      userRole: string;
    };
    hasThumb: boolean;
    hasFavour: boolean;
  }

  
export default /*@__PURE__*/_defineComponent({
  __name: 'HomeView',
  setup(__props) {

  const router = useRouter();
  const userRole = store.state.user.loginUser.userRole;
  const value = ref();

  // const getRole = (userRole = ACCESS_ENUM.NOT_LOGIN) => {
  //   switch (userRole) {
  //     case ACCESS_ENUM.ADMIN:
  //       return "管理员";
  //     case ACCESS_ENUM.USER:
  //       return "用户";
  //     default:
  //       return "游客";
  //   }
  // }

  const total = ref(0);
  const datalist = ref<Post[]>([]);
  const searchParams = ref({
    searchText: "",
    current: 1,
    pageSize: 6,
  });

  const loadData = async () => {
    // 清空现有数据
    if (searchParams.value.current === 1) {
      datalist.value = [];
    }
    const res = await PostControllerService.listPostVoByPageUsingPost(searchParams.value);
    if (res.code === 0) {
      // 过滤掉重复的数据
      const newPosts = res.data.records.filter((newPost: Post) =>
          !datalist.value.some(existingPost => existingPost.id === newPost.id)
      );
      datalist.value.push(...newPosts);
      total.value = res.data.total;
    } else {
      message.error("加载失败," + res.message);
    }
  }


  onMounted(() => {
    loadData();
  });
  const goLearnDevelopment = () => {
    // 开一个新的页面
    window.open('http://image.niwenjia.cn/JavaStudy.pdf');
  }
  const todo = () => {
    message.info("功能暂未开放");
  }

  // 跳转到问题页面
  const toQuestions = () => {
    window.location.href = '/question';
  }

  // 跳转到发布帖子页面
  const toAddPost = () => {
    router.push('/add/post');
  }

  const toPost = (id: string) => {
    router.push({
      path: `/view/post/${id}`,
    });
  };

  const getTime = (time: string) => {
    // 2024-12-06T00:30:36.000+00:00 to 2024-12-06 00:30:36
    return time.split("T")[0];
  }

  // 滑动事件监听
  let shouldMessage = true;
  const handleScroll = () => {
    // 获取滚动容器
    const scrollContainer = document.documentElement;
    // 判断是否已滚动到底部
    if (
        scrollContainer.scrollHeight - scrollContainer.scrollTop <= scrollContainer.clientHeight + 50
    ) {
      // 如果当前数据未加载完，则加载下一页
      if (datalist.value.length < total.value) {
        searchParams.value.current += 1; // 页码加1
        loadData(); // 加载下一页数据
      } else {
        // 如果数据已全部加载完，提示用户
        if (shouldMessage) {
          // message.info('没有更多了~');
          shouldMessage = false;
        }
      }
    }
  };
  const doSearch = async () => {
    shouldMessage = true;
    searchParams.value.current = 1;
    datalist.value = []; // 清空现有数据
    await loadData();
    if (datalist.value.length == 0) {
      // message.info('没有找到');
    }
  }
  onMounted(() => {
    loadData(); // 页面初次加载时，加载第一页数据
    // 为滚动事件绑定监听器
    window.addEventListener('scroll', handleScroll);

    // 监听回车
    window.addEventListener('keyup', (event) => {
      // Message.info("keyup: " + event.key);
      if (event.key === 'Enter') {
        doSearch();
      }
    });
  });

  // 在组件销毁时移除滚动监听，避免内存泄漏
  onUnmounted(() => {
    window.removeEventListener('scroll', handleScroll);
  });

  const images = [
    'http://image.niwenjia.cn/a048cf0442114323b216ab354080d147.png',
    'http://image.niwenjia.cn/d30450707d63479ca94719c5c9d47b03.png',
    'http://image.niwenjia.cn/f557f1455953443ab9da984a3ff60603.png',
  ];
  const handleChange = (value: any) => {
    // console.log(value)
  }

  let count = 1;
  const clickFatCat = () => {
    if (count == 2) {
      message.info("广告位招租~");
      count++;
      return;
    }
    message.clear();
    message.success(`你点了${count++}下超级大肥猫`);
  }

  const onLikeChange = async (item: Post) => {
    const res = await PostThumbControllerService.doThumbUsingPost({postId: item.id});
    if (res.code !== 0) {
      message.error("点赞失败" + res.message);
      return;
    }
    item.thumbNum = item.thumbNum + (item.hasThumb ? -1 : 1);
    item.hasThumb = !item.hasThumb;
    datalist.value = [...datalist.value];
  }
  const onStarChange = async (item: Post) => {
    const res = await PostFavourControllerService.doPostFavourUsingPost({postId: item.id});
    if (res.code !== 0) {
      message.error("收藏失败" + res.message);
      return;
    }
    item.favourNum = item.favourNum + (item.hasFavour ? -1 : 1);
    item.hasFavour = !item.hasFavour;
    datalist.value = [...datalist.value];
  };

  const toFavourPosts = () => {
    router.push('/view/favours');
  }
  const thanks = () => {
    const url = 'http://image.niwenjia.cn/77bb799ad59e4ecabec517ad27778544.jpg';
     window.open(url);
    // window.location.href = url;
  }

return (_ctx: any,_cache: any) => {
  const _component_a_carousel_item = _resolveComponent("a-carousel-item")!
  const _component_a_carousel = _resolveComponent("a-carousel")!
  const _component_a_avatar = _resolveComponent("a-avatar")!
  const _component_a_tag = _resolveComponent("a-tag")!
  const _component_a_comment = _resolveComponent("a-comment")!
  const _component_a_divider = _resolveComponent("a-divider")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_a_carousel, {
          "default-current": 2,
          style: {
              width: '100%',
              height: '40vh',
            },
          class: "d-none d-lg-block",
          onChange: handleChange
        }, {
          default: _withCtx(() => [
            (_openBlock(), _createElementBlock(_Fragment, null, _renderList(images, (image) => {
              return _createVNode(_component_a_carousel_item, { key: image }, {
                default: _withCtx(() => [
                  _createElementVNode("img", {
                    src: image,
                    style: {
                 width: '100%',
                 height: '100%'
            }
                  }, null, 8, _hoisted_4)
                ]),
                _: 2
              }, 1024)
            }), 64))
          ]),
          _: 1
        }),
        _createElementVNode("div", _hoisted_5, [
          _withDirectives(_createElementVNode("input", {
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((searchParams.value.searchText) = $event)),
            "aria-describedby": "search",
            "aria-label": "Recipient's username",
            class: "form-control orm-control-lg border-2 border-primary-subtle rounded-0",
            placeholder: "输入文本以查询",
            type: "text"
          }, null, 512), [
            [_vModelText, searchParams.value.searchText]
          ]),
          _createElementVNode("button", {
            id: "search",
            class: "btn btn-outline-primary",
            type: "button",
            onClick: doSearch
          }, "搜索")
        ]),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(datalist.value, (item, idx) => {
          return (_openBlock(), _createElementBlock("ul", {
            key: idx,
            class: "list-group my-2"
          }, [
            _createElementVNode("li", _hoisted_6, [
              _createVNode(_component_a_comment, {
                author: item.user.userName,
                datetime: _unref(formatDate)(new Date(item.createTime)),
                align: "right"
              }, {
                actions: _withCtx(() => [
                  _createElementVNode("span", {
                    key: "heart",
                    class: "action",
                    onClick: ($event: any) => (onLikeChange(item))
                  }, [
                    (item.hasThumb)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_8, [
                          _createVNode(_unref(IconHeartFill), { style: { color: '#f53f3f' } })
                        ]))
                      : (_openBlock(), _createElementBlock("span", _hoisted_9, [
                          _createVNode(_unref(IconHeart))
                        ])),
                    _createTextVNode(" " + _toDisplayString(item.thumbNum), 1)
                  ], 8, _hoisted_7),
                  _createElementVNode("span", {
                    key: "star",
                    class: "action",
                    onClick: ($event: any) => (onStarChange(item))
                  }, [
                    (item.hasFavour)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_11, [
                          _createVNode(_unref(IconStarFill), { style: { color: '#ffb400' } })
                        ]))
                      : (_openBlock(), _createElementBlock("span", _hoisted_12, [
                          _createVNode(_unref(IconStar))
                        ])),
                    _createTextVNode(" " + _toDisplayString(item.favourNum), 1)
                  ], 8, _hoisted_10)
                ]),
                avatar: _withCtx(() => [
                  _createVNode(_component_a_avatar, {
                    shape: "square",
                    onClick: ($event: any) => (toPost(String(item.id)))
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("img", {
                        src:  item.user.userAvatar,
                        alt: "avatar"
                      }, null, 8, _hoisted_13)
                    ]),
                    _: 2
                  }, 1032, ["onClick"])
                ]),
                content: _withCtx(() => [
                  _createElementVNode("div", {
                    class: "post-content",
                    style: {"max-height":"100px"},
                    onClick: ($event: any) => (toPost(String(item.id)))
                  }, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.tagList, (tag) => {
                      return (_openBlock(), _createBlock(_component_a_tag, {
                        key: tag,
                        class: "d-none d-sm-inline-flex",
                        color: "green",
                        ellipsis: "",
                        style: {"margin-right":"1vh"},
                        tooltip: ""
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(tag), 1)
                        ]),
                        _: 2
                      }, 1024))
                    }), 128)),
                    _createElementVNode("h4", {
                      class: "post-title",
                      onClick: ($event: any) => (toPost(String(item.id)))
                    }, _toDisplayString(item.title), 9, _hoisted_15),
                    _createElementVNode("span", {
                      class: "d-none d-md-block",
                      onClick: ($event: any) => (toPost(String(item.id)))
                    }, _toDisplayString(item.content), 9, _hoisted_16)
                  ], 8, _hoisted_14)
                ]),
                _: 2
              }, 1032, ["author", "datetime"])
            ])
          ]))
        }), 128)),
        _createVNode(_component_a_divider, null, {
          default: _withCtx(() => _cache[1] || (_cache[1] = [
            _createElementVNode("span", { style: {"color":"#6b7280","font-size":"10px"} }, "看到我代表这里已经没有数据了！", -1)
          ])),
          _: 1
        })
      ]),
      _cache[6] || (_cache[6] = _createElementVNode("div", { style: {"width":"1%"} }, null, -1)),
      _createElementVNode("div", _hoisted_17, [
        _createElementVNode("div", _hoisted_18, [
          _createElementVNode("div", null, [
            _createElementVNode("span", _hoisted_19, _toDisplayString(_unref(store).state.user?.loginUser?.userName ?? "未登录"), 1),
            (_unref(userRole) === 'user')
              ? (_openBlock(), _createElementBlock("span", _hoisted_20, "用户"))
              : (_unref(userRole) === 'admin' )
                ? (_openBlock(), _createElementBlock("span", _hoisted_21, "管理员"))
                : (_openBlock(), _createElementBlock("span", _hoisted_22, "游客"))
          ]),
          _createElementVNode("a", {
            class: "link-offset-2 link-underline link-underline-opacity-0",
            href: "#",
            onClick: toQuestions
          }, "去写题")
        ]),
        _createElementVNode("div", {
          class: "row my-2 p-3",
          style: {"height":"30%"}
        }, [
          _createElementVNode("div", {
            class: "col-3 pointer",
            onClick: toAddPost
          }, _cache[2] || (_cache[2] = [
            _createElementVNode("div", { class: "" }, [
              _createElementVNode("div", {
                class: "icon",
                style: {"background":"#cce1ff"}
              }, [
                _createElementVNode("svg", {
                  height: "32",
                  "p-id": "30714",
                  t: "1733798152431",
                  version: "1.1",
                  viewBox: "0 0 1024 1024",
                  width: "32",
                  xmlns: "http://www.w3.org/2000/svg"
                }, [
                  _createElementVNode("path", {
                    d: "M584.353684 80.842105H112.815158A58.960842 58.960842 0 0 0 53.894737 139.776v825.290105A58.960842 58.960842 0 0 0 112.828632 1024h707.274105a58.960842 58.960842 0 0 0 58.933895-58.933895V375.673263L584.353684 80.842105z",
                    fill: "#0076F3",
                    opacity: ".3",
                    "p-id": "30715"
                  }),
                  _createElementVNode("path", {
                    d: "M665.195789 0H193.657263A58.960842 58.960842 0 0 0 134.736842 58.933895v825.290105A58.960842 58.960842 0 0 0 193.670737 943.157895h707.274105a58.960842 58.960842 0 0 0 58.933895-58.933895V294.831158L665.195789 0z",
                    fill: "#0076F3",
                    "p-id": "30716"
                  }),
                  _createElementVNode("path", {
                    d: "M665.195789 0v235.749053a58.960842 58.960842 0 0 0 58.933895 58.947368H959.865263L665.195789 0z",
                    fill: "#49A2FF",
                    "p-id": "30717"
                  }),
                  _createElementVNode("path", {
                    d: "M724.129684 294.696421L959.865263 530.445474V294.696421z",
                    fill: "#0068D6",
                    "p-id": "30718"
                  }),
                  _createElementVNode("path", {
                    d: "M726.568421 363.789474a20.210526 20.210526 0 0 1 0 40.421052H377.263158a20.210526 20.210526 0 0 1 0-40.421052h349.305263zM718.901895 498.526316a20.210526 20.210526 0 0 1 0 40.421052H377.263158a20.210526 20.210526 0 0 1 0-40.421052h341.638737zM592.842105 633.263158a20.210526 20.210526 0 0 1 0 40.421053H377.263158a20.210526 20.210526 0 0 1 0-40.421053h215.578947z",
                    fill: "#FFFFFF",
                    "p-id": "30719"
                  })
                ])
              ]),
              _createElementVNode("p", { class: "bottom-text center" }, "发帖子")
            ], -1)
          ])),
          _createElementVNode("div", {
            class: "col-3 pointer",
            onClick: goLearnDevelopment
          }, _cache[3] || (_cache[3] = [
            _createElementVNode("div", { class: "" }, [
              _createElementVNode("div", {
                class: "icon",
                style: {"background":"#fffbcc"}
              }, [
                _createElementVNode("svg", {
                  height: "32",
                  "p-id": "11233",
                  t: "1733797672478",
                  version: "1.1",
                  viewBox: "0 0 1024 1024",
                  width: "32",
                  xmlns: "http://www.w3.org/2000/svg"
                }, [
                  _createElementVNode("path", {
                    d: "M503.296 228.352l-38.912-122.88h-437.76v811.52H1000.96V228.352h-497.664z m-154.112 442.368l-74.752 71.68-164.352-158.72 170.496-165.376 74.752 71.68-96.768 93.184 90.624 87.552z m97.792 33.792l-74.752-71.68 193.024-187.392 74.752 71.68-193.024 187.392zM747.52 742.4l-74.752-71.68 90.112-87.04-96.768-93.184 74.752-71.68L911.36 583.68l-163.84 158.72z",
                    fill: "#fca400",
                    "p-id": "11234"
                  })
                ])
              ]),
              _createElementVNode("p", {
                a: "",
                class: "bottom-text center"
              }, "学开发")
            ], -1)
          ])),
          _createElementVNode("div", {
            class: "col-3 pointer",
            onClick: toFavourPosts
          }, _cache[4] || (_cache[4] = [
            _createElementVNode("div", { class: "" }, [
              _createElementVNode("div", {
                class: "icon",
                style: {"background":"#f4e3fc"}
              }, [
                _createElementVNode("svg", {
                  "data-spm-anchor-id": "a313x.search_index.0.i3.228c3a81lguDvp",
                  height: "40",
                  "p-id": "3168",
                  t: "1733899558331",
                  version: "1.1",
                  viewBox: "0 0 1136 1024",
                  width: "32",
                  xmlns: "http://www.w3.org/2000/svg"
                }, [
                  _createElementVNode("path", {
                    class: "",
                    d: "M1115.391709 368.15586l-138.028541-19.652149-30.776008-4.634941-143.49777-21.876922a48.759578 48.759578 0 0 1-30.776008-16.685787 34.391262 34.391262 0 0 1-7.415906-10.753063l-5.561929-11.031159L625.01496 13.953675a24.565187 24.565187 0 0 0-44.310036 0L446.384337 285.932008 440.915106 296.685071a54.13611 54.13611 0 0 1-7.415905 10.753063A48.388783 48.388783 0 0 1 402.630494 324.494717l-143.775867 20.579137-30.776008 4.634941-135.896467 18.539764a24.935982 24.935982 0 0 0-13.812124 42.456059L185.529862 514.805391V376.498754a15.6661 15.6661 0 0 1 9.269881-13.719425 15.944197 15.944197 0 0 1 16.314992 1.668578l149.430496 116.800511 6.118122 4.913038a34.669358 34.669358 0 0 0 7.137809 4.264145 29.941718 29.941718 0 0 0 21.228029 0l86.209901-26.882657 18.539764-5.840025 83.428936-26.233766a15.480703 15.480703 0 0 1 19.095957 20.39374l-67.762836 184.563347a30.961405 30.961405 0 0 0 3.244458 28.643935l23.174705 30.776008 15.388004 22.247716L652.731906 825.068336a14.739112 14.739112 0 0 1 0 18.539763 13.904823 13.904823 0 0 1-12.607039 6.118122h-18.539764l-178.352526-3.707952a30.961405 30.961405 0 0 0-25.955669 12.143545l-106.603642 143.868566-7.04511 9.269882 23.638199-12.607039L580.426828 865.948515a49.130374 49.130374 0 0 1 46.349409 0l253.160473 133.671696 26.233766 13.534027a22.525813 22.525813 0 0 0 22.433114 0 23.823596 23.823596 0 0 0 13.626726-25.86297l-35.31825-206.718365-7.601303-42.456059L889.021194 676.750226a49.501169 49.501169 0 0 1 13.255931-46.349409l225.721623-220.623188a24.935982 24.935982 0 0 0-12.607039-41.621769z m-845.135127 636.933581l-4.913038-17.798173a22.989307 22.989307 0 0 0 4.913038 17.798173z",
                    "data-spm-anchor-id": "a313x.search_index.0.i0.228c3a81lguDvp",
                    fill: "#d398e0",
                    "p-id": "3169"
                  }),
                  _createElementVNode("path", {
                    class: "",
                    d: "M300.754493 778.718927l7.04511-42.270662 10.753063-61.552015a49.686567 49.686567 0 0 0-14.09022-44.495433L185.529862 514.805391v59.141846a40.880179 40.880179 0 0 1 0 8.250195 30.683309 30.683309 0 0 1-12.328943 17.24198l-77.588911 50.891651-16.036896 10.474967-72.675873 48.944976a15.6661 15.6661 0 0 0 3.707952 27.809645l187.251613 61.644714a30.683309 30.683309 0 0 1 20.022945 20.857235l10.753063 37.913816 7.415906 25.862971 30.776007 101.9687zM278.22868 1011.856455a26.511862 26.511862 0 0 1-7.972098-6.767014l1.853976 6.210821a15.017209 15.017209 0 0 0 14.461016 11.031159 14.461016 14.461016 0 0 0 12.885135-6.118122l4.078748-5.283832h-2.224771a23.360102 23.360102 0 0 1-23.082006 0.926988z",
                    "data-spm-anchor-id": "a313x.search_index.0.i2.228c3a81lguDvp",
                    fill: "#d398e0",
                    "p-id": "3170"
                  }),
                  _createElementVNode("path", {
                    d: "M417.276908 857.605621a30.868707 30.868707 0 0 1 26.141066-12.050846l178.90872 6.488917h18.539763a13.904823 13.904823 0 0 0 12.60704-6.118122 14.739112 14.739112 0 0 0 0-18.539764l-75.549537-105.027761-15.388004-22.155018-23.082006-30.776007a30.683309 30.683309 0 0 1-3.707953-28.643935l67.392041-184.841444A15.388004 15.388004 0 0 0 584.134781 435.733299l-83.428937 26.141066-18.539764 5.840026-86.858792 27.160754a30.961405 30.961405 0 0 1-21.22803 0 33.464273 33.464273 0 0 1-7.137809-4.356845l-6.118122-4.913037-149.0597-116.800511a15.758799 15.758799 0 0 0-25.306777 11.958147V519.162235l118.005596 111.238582a49.686567 49.686567 0 0 1 14.09022 44.124638l-10.753063 61.552015-7.04511 42.177962-35.410949 206.996462 4.913038 17.890872a29.478224 29.478224 0 0 0 7.972098 6.767014 23.730898 23.730898 0 0 0 23.082006 0h2.224771l7.045111-9.269882z",
                    fill: "#76AADC",
                    "p-id": "3171"
                  }),
                  _createElementVNode("path", {
                    class: "selected",
                    d: "M417.276908 857.605621a30.868707 30.868707 0 0 1 26.141066-12.050846l178.90872 6.488917h18.539763a13.904823 13.904823 0 0 0 12.60704-6.118122 14.739112 14.739112 0 0 0 0-18.539764l-75.549537-105.027761-15.388004-22.155018-23.082006-30.776007a30.683309 30.683309 0 0 1-3.707953-28.643935l67.392041-184.841444A15.388004 15.388004 0 0 0 584.134781 435.733299l-83.428937 26.141066-18.539764 5.840026-86.858792 27.160754a30.961405 30.961405 0 0 1-21.22803 0 33.464273 33.464273 0 0 1-7.137809-4.356845l-6.118122-4.913037-149.0597-116.800511a15.758799 15.758799 0 0 0-25.306777 11.958147V519.162235l118.005596 111.238582a49.686567 49.686567 0 0 1 14.09022 44.124638l-10.753063 61.552015-7.04511 42.177962-35.410949 206.996462 4.913038 17.890872a29.478224 29.478224 0 0 0 7.972098 6.767014 23.730898 23.730898 0 0 0 23.082006 0h2.224771l7.045111-9.269882z",
                    "data-spm-anchor-id": "a313x.search_index.0.i4.228c3a81lguDvp",
                    fill: "#bf5cd6",
                    "p-id": "3172"
                  })
                ])
              ]),
              _createElementVNode("p", { class: "bottom-text center" }, "收藏夹")
            ], -1)
          ])),
          _createElementVNode("div", {
            class: "col-3 pointer",
            href: " ",
            onClick: thanks
          }, _cache[5] || (_cache[5] = [
            _createElementVNode("div", { class: "" }, [
              _createElementVNode("div", {
                class: "icon",
                style: {"background":"#ffeaeb"}
              }, [
                _createElementVNode("svg", {
                  class: "",
                  height: "32",
                  "p-id": "7279",
                  t: "1734007329627",
                  version: "1.1",
                  viewBox: "0 0 1024 1024",
                  width: "32",
                  xmlns: "http://www.w3.org/2000/svg"
                }, [
                  _createElementVNode("path", {
                    d: "M564.162534 162.117228c208.181243 0 377.548274 169.367032 377.548275 377.547913s-169.367032 377.548274-377.548275 377.548274S186.61426 747.846384 186.61426 539.665141 355.981292 162.117228 564.162534 162.117228m0-39.741886c-230.093575 0-417.29016 187.194418-417.29016 417.289799 0 230.095742 187.196585 417.29016 417.29016 417.29016s417.289799-187.194418 417.289799-417.29016c0-230.095381-187.196224-417.289799-417.289799-417.289799z",
                    fill: "#fc3e3e",
                    "p-id": "7280"
                  }),
                  _createElementVNode("path", {
                    class: "selected",
                    d: "M488.652807 46.865615c-230.093575 0-417.29016 187.194418-417.29016 417.29016s187.196585 417.289799 417.29016 417.289799 417.29016-187.194418 417.29016-417.289799-187.196585-417.29016-417.29016-417.29016z m169.458065 515.915161c19.075484 0 34.539251 15.460155 34.539251 34.541058 0 19.073678-15.464129 34.531665-34.539251 34.531665h-141.700807v54.31916c0 13.197323-15.462322 23.889781-34.541418 23.889781-19.075484 0-34.539251-10.692458-34.539251-23.889781v-54.31916H319.190769c-19.07151 0-34.535639-15.457987-34.535639-34.531665 0-19.080902 15.464129-34.541057 34.535639-34.541058h128.138627v-64.668075l-0.890828-2.268612H319.190769c-19.07151 0-34.535639-15.461961-34.535639-34.533471 0-19.079096 15.464129-34.541419 34.535639-34.541418h100.19894L356.301354 265.667428c-1.137196-5.639017-1.692066-10.719551-1.692067-15.240879 1.113715-20.32322 11.852773-31.042771 32.1778-32.1778 12.407644 0 22.01348 7.358538 28.789693 22.017093l67.739732 179.511843 66.04586-179.511843c7.886315-14.658916 18.047022-22.017093 30.483927-22.017093 20.321053 2.278004 31.593307 12.989608 33.869866 32.1778 0 4.521329-1.139002 9.601863-3.3863 15.240879l-63.090162 161.102133h110.871892c19.075484 0 34.539251 15.461961 34.539251 34.541418 0 19.07151-15.464129 34.533471-34.539251 34.533472h-137.920751l-3.780056 9.652075v57.28425h141.700084z",
                    "data-spm-anchor-id": "a313x.search_index.0.i11.11373a81rD6i2U",
                    fill: "#fc3e3e",
                    "p-id": "7281"
                  })
                ])
              ]),
              _createElementVNode("p", { class: "bottom-text center" }, "积功德")
            ], -1)
          ]))
        ]),
        _createElementVNode("div", { class: "rounded h-50 w-100 p-1" }, [
          _createElementVNode("img", {
            alt: "超级大肥猫",
            class: "rounded h-100 w-100",
            src: "http://snc013327.hn-bkt.clouddn.com/0a8b42538fbd4e929a15ac03c7a75dcb.png",
            onClick: clickFatCat
          })
        ])
      ])
    ])
  ]))
}
}

})