<template>
  <div id="userLayout">
    <a-layout style="min-height: 100vh">
      <a-layout-header class="header">
        <a-space>
          <img class="logo" src="../assets/ang.svg"/>
          <div style="font-weight: bold; font-size: 3ch;">编程题阶</div>
        </a-space>
      </a-layout-header>
      <a-layout-content class="content">
        <router-view/>
      </a-layout-content>
      <a-layout-footer class="footer">
        <div class="">{{`© ${year} ${author} ` }}<a class="link-underline" style="text-decoration: none; " href="http://beian.miit.gov.cn/" target="_blank">{{ record }}</a></div>
      </a-layout-footer>
    </a-layout>
  </div>
</template>

<style scoped>
  #userLayout {
    text-align: center;
    //background-image: linear-gradient(135deg, #ffffff 10%, #65f7fa 100%);
    //background: url("../assets/img_6.png") no-repeat 0 0 / 100% 100%;
  }

  #userLayout .logo {
    width: 64px;
    height: 64px;
  }

  #userLayout .header {
    margin-top: 16px;
  }

  #userLayout .content {
    padding: 20px;
  }

  #userLayout .footer {
    padding: 16px;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
  }
</style>

<script lang="ts" setup >
  let year = new Date().getFullYear();        // 一般都是最新的一年
  let author = '浙江纺织服装职业技术学院23移动2倪文佳';					// 作者名
  let record = '浙ICP备2024116650号-2';			// 备案号
</script>