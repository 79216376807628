import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "" }
const _hoisted_2 = { style: {"color":"#656464"} }
const _hoisted_3 = {
  class: "link-underline",
  href: "http://beian.miit.gov.cn/",
  style: {"text-decoration":"none"},
  target: "_blank"
}


export default /*@__PURE__*/_defineComponent({
  __name: 'FooterInfo',
  setup(__props) {

  let year = new Date().getFullYear();        // 一般都是最新的一年
  let author = '浙江纺织服装职业技术学院23移动2倪文佳'; // 作者名
  let record = '浙ICP备2024116650号-2';      // 备案号

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("span", _hoisted_2, _toDisplayString(`© ${_unref(year)} ${_unref(author)} `), 1),
    _createElementVNode("a", _hoisted_3, _toDisplayString(_unref(record)), 1)
  ]))
}
}

})