<template>
  <a-tab-pane key="submit" class="container" title="我的提交">

    <a-table
        :columns="columns"
        :data="dataList"
        :loading=isLoading
        :pagination="{
          pageSize: searchParams.pageSize,
          current: searchParams.current,
          total,
          showTotal: false
        }"
        class="pointer"
        @cell-click="checkHistorySubmit"
        @page-change="onPageChange"
    >
      <template #state="{record}">
        <span :style="{ color: getColor(record.judgeInfo.message) }"
              style="font-weight: 600;">{{ record.judgeInfo.message }}</span>
      </template>
      <template #question="{record}">
        <a class="pointer link-offset-2 link-underline link-underline-opacity-0"
           style="color: #1c80f2;font-weight: 500;"
           @click="(event) => {event.stopPropagation(); toQuestion(record.questionVO)}">{{
            record.questionVO.title
                                                                                        }}</a>
      </template>
      <template #user="{ record }">
        <a class="pointer link-offset-2 link-underline link-underline-opacity-0"
           style="color: #1c80f2;font-weight: 500;"
           @click="(event) => {event.stopPropagation();  toOtherUser(record.userVO) }">
          {{ record.userVO.userName }}</a>
      </template>
      <template #language="{ record }">
        <span> {{ firstUpperCase(record.language) }}</span>
      </template>
      <template #runTime="{record}">
        <span>{{ record.judgeInfo.time }}ms</span>
      </template>
      <template #memory="{record}">
        <span>{{ record.judgeInfo.memory }}KB</span>
      </template>
      <template #time="{record}">
        <span>{{ formatDate(new Date(record.createTime)) }}</span>
      </template>
    </a-table>
    <!--弹出-->
    <a-modal v-model:visible="historyVisible" :hide-cancel="true" :ok-text="sure" draggable width="62vw">
      <template #title>
        <h4 class="my-1" style="color:#1c80f2">历史提交结果</h4>
      </template>
      <div v-if="historySubmit" class="w-auto">
        <div class="container rounded-2 p-2 text-nowrap"
             style="background: #f6f7f9; width: auto !important;">
          <div class="row">
            <div class="col">
              <div class="row-title">题目<br></div>
              <span>{{ historySubmit?.questionVO.title }}</span>
            </div>
            <div class="col">
              <div class="row-title">用户<br></div>
              <span>
                  {{ historySubmit.userVO.userName }}
                </span>
            </div>
            <div class="col">
              <div class="row-title">提交时间<br></div>
              <span> {{ formatDate(new Date(historySubmit.createTime)) }} </span>
            </div>
          </div>
          <div class="row my-4">
            <div class="col">
              <div class="row-title">编译器<br></div>
              <span>{{ firstUpperCase(historySubmit.language) }}</span>
            </div>
            <div class="col">
              <div class="row-title">内存<br></div>
              <span>{{ historySubmit.judgeInfo.memory }} / {{
                  historySubmit.questionVO.judgeConfig.memoryLimit
                    }}kb</span>
            </div>
            <div class="col">
              <div class="row-title">用时<br></div>
              <span> {{ historySubmit.judgeInfo.time }} / {{ historySubmit.questionVO.judgeConfig.timeLimit }}ms </span>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <div class="row-title">状态<br></div>
              <span :style="{ color: getColor(historySubmit.judgeInfo.message) }"
                    style="font-weight: bold">{{ historySubmit.judgeInfo.message }}
                           </span>
            </div>
            <div class="col">
              <div class="row-title">提交编号<br></div>
              <span>{{ historySubmit.id }}</span>
            </div>
            <div class="col">
              <div class="row-title">评测时间<br></div>
              <span> {{ formatDate(new Date(historySubmit.updateTime)) }} </span>
            </div>
          </div>
        </div>
        <div class="row my-4" style="max-height: 35vh">
          <h5>提交代码</h5>
          <MDViewerQuestionSubmit :value="getMarkdownCode(historySubmit.code, historySubmit.language)"
                                  style="max-height: 35vh"/>
        </div>
      </div>
    </a-modal>
  </a-tab-pane>
</template>

<script lang="ts" setup>
  import {onMounted, ref} from "vue";
  import {QuestionSubmitControllerService, QuestionVO, UserVO,} from "../../../generated";
  import {Message} from "@arco-design/web-vue";
  import MDViewerQuestionSubmit from "@/components/MDViewerQuestionSubmit.vue";
  import formatDate from "../../access/Date";
  import {useRouter} from "vue-router";

  const columns = [
    {
      title: "状态",
      slotName: "state",
    },
    {
      title: "题目",
      slotName: "question",
    },
    {
      title: "用户",
      slotName: "user",
    },
    {
      title: "语言",
      slotName: "language",
    },
    {
      title: "运行时间",
      slotName: "runTime",
    },
    {
      title: "运行内存",
      slotName: "memory",
    },
    {
      title: "提交时间",
      slotName: "time",
    },
  ];

  const total = ref(0);
  const sure = ref("确认");
  const isLoading = ref(true);

  interface JudgeInfo {
    message: string;
    memory: string;
    time: string;
  }

  interface QuestionSubmitVO {
    id: string;
    language: string;
    code: string;
    judgeInfo: JudgeInfo;
    questionId: string;
    userVO: any;
    questionVO: any;
    userId: string;
    createTime: string;
    updateTime: string;
  }

  const question = ref<QuestionVO>();
  const dataList = ref<QuestionSubmitVO[]>([]);
  const lastSubmit = ref<QuestionSubmitVO>();

  const searchParams = ref({
    questionId: "",
    userId: "",
    current: 1,
    pageSize: 15,
  });
  const loadData = async () => {
    const pageRes = await QuestionSubmitControllerService.listQuestionSubmitByPageUsingPost(searchParams.value as any);
    if (pageRes.code === 0) {
      dataList.value = pageRes.data?.records;
      total.value = pageRes.data.total;
      if (dataList.value && dataList.value.length > 0) {
        lastSubmit.value = dataList.value[0];
        if (lastSubmit.value.judgeInfo.memory == null || lastSubmit.value.judgeInfo.time == null) {
          // message.error("lastSubmit");
          lastSubmit.value.judgeInfo = {
            message: "COMPILE ERROR",
            memory: "0",
            time: "0",
          };
        }
      }
    } else {
      Message.error("提交记录加载失败，" + pageRes.message);
    }
    isLoading.value = false;
  };

  const getColor = (message: string | null) => {
    if (!message) {
      return "black";
    }
    if (message.includes("Accepted")) {
      return "green";
    } else if (message.includes("Wrong Answer")) {
      return "red";
    } else if (message.includes("Time Limit Exceeded")) {
      return "orange";
    } else if (message.includes("Memory Limit Exceeded")) {
      return "#2e468c";
    } else if (message.includes("Runtime Error")) {
      return "blue";
    } else if (message.includes("System Error")) {
      return "blue";
    }
    // 编译错误
    return "#8d44ad";
  }

  /**
   * 页面加载时，请求数据
   */
  onMounted(async () => {
    await loadData();
  });

  const getMarkdownCode = (code: string, language: string) => {
    if (!code || !language) {
      return '# 你不许偷看！';
    }
    return "```" + language + '\n' + code + "\n```";
  }
  const firstUpperCase = (str: string) => {
    return str.charAt(0).toUpperCase() + str.substring(1);
  }
  // 历史提交详情查看
  const historySubmit = ref<QuestionSubmitVO>();
  const historyVisible = ref(false);
  const checkHistorySubmit = (item: QuestionSubmitVO) => {
    historySubmit.value = item;
    historyVisible.value = true;
  }

  const onPageChange = async (page: number) => {
    isLoading.value = true;
    searchParams.value = {
      ...searchParams.value,
      current: page
    }
    await loadData();
  }

  /**
   * 确认搜索，重新加载数据
   */
  const doSubmit = () => {
    // 这里需要重置搜索页号
    searchParams.value = {
      ...searchParams.value,
      current: 1,
    };
  };

  const router = useRouter();
  const toQuestion = (question: QuestionVO) => {
    router.push({
      path: `/view/question/${question.id}`,
    });
  }
  const toOtherUser = (user: UserVO) => {
    router.push({
      path: `/view/other/${user.id}`,
    });
  }

</script>

<style scoped>
  .layout-demo :deep(.arco-layout-footer) {
    display: flex;
    height: 4vh;
    position: fixed;
    bottom: 0;
    width: 100%;
    background: #eeeeee;
    box-shadow: #eee 4px 4px 10px;
    align-content: center;
    justify-content: flex-end;
  }

  .layout-demo :deep(.arco-layout-sider) {
    background-color: var(--color-primary-light-3);
  }

  #basicLayout .content[data-v-0949dcaa] {
    padding: 0;
  }

  .modal {
    z-index: 9999 !important;
  }

  .th-bg {
    background-color: #f8f9fa;
  }

  .pointer {
    cursor: pointer;
  }
</style>