import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "userLoginView",
  class: "container my-5"
}
const _hoisted_2 = {
  class: "left-column d-none d-lg-block",
  style: {"background":"none"}
}
const _hoisted_3 = {
  class: "right-column card p-0 d-flex",
  style: {"height":"500px","background-image":"linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%)"}
}
const _hoisted_4 = { class: "p-2 d-flex justify-content-center align-content-center" }
const _hoisted_5 = { class: "grid-demo-background" }

import {reactive} from "vue";
  import {UserControllerService, UserLoginRequest} from "../../../generated";
  import message from "@arco-design/web-vue/es/message";
  import {useRouter} from "vue-router";
  import {useStore} from "vuex";
  import {Vue3Lottie} from "vue3-lottie";

  /**
   * 表单信息
   */
  
export default /*@__PURE__*/_defineComponent({
  __name: 'UserLoginView',
  setup(__props) {

  const form = reactive({
    userAccount: "",
    userPassword: "",
  } as UserLoginRequest);

  const router = useRouter();
  const store = useStore();

  /**
   * 提交表单
   * @param data
   */
  const handleSubmit = async () => {
    const res = await UserControllerService.userLoginUsingPost(form);
    // 登录成功，跳转到主页
    if (res.code === 0) {
      await store.dispatch("user/getLoginUser");
      await router.push({
        path: "/",
        replace: true,
      });
    } else {
      message.error("登陆失败，" + res.message);
    }
  };

return (_ctx: any,_cache: any) => {
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_input_password = _resolveComponent("a-input-password")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_a_space = _resolveComponent("a-space")!
  const _component_a_form = _resolveComponent("a-form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_unref(Vue3Lottie), {
        "animation-link": "http://image.niwenjia.cn/evluate.json",
        class: "",
        height: "500px",
        width: "500px"
      })
    ]),
    _createElementVNode("div", _hoisted_3, [
      _cache[4] || (_cache[4] = _createElementVNode("div", { class: "card-header" }, [
        _createElementVNode("h3", null, "欢迎回家")
      ], -1)),
      _cache[5] || (_cache[5] = _createElementVNode("div", { style: {"height":"100px"} }, null, -1)),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_a_form, {
          model: form,
          "auto-label-width": "",
          "label-align": "left",
          style: {"max-width":"480px","margin":"0 auto"},
          onSubmit: handleSubmit
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_form_item, {
              field: "userAccount",
              label: "账号",
              tooltip: "用于登录，类似微信号，不允许重复"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_input, {
                  modelValue: form.userAccount,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((form.userAccount) = $event)),
                  class: "input-group-text",
                  placeholder: "请输入账号名称"
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_a_form_item, {
              field: "pwd",
              label: "密码",
              tooltip: "密码不少于 8 位"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_input_password, {
                  modelValue: form.userPassword,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((form.userPassword) = $event)),
                  class: "input-group-text",
                  placeholder: "请输入密码"
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_a_form_item, null, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_5, [
                  _createVNode(_component_a_space, {
                    size: 16,
                    direction: "vertical",
                    style: {"display":"block"}
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_row, { class: "grid-demo" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_col, { span: 22 }, {
                            default: _withCtx(() => [
                              _createElementVNode("div", null, [
                                _createVNode(_component_a_button, {
                                  class: "rounded-2",
                                  "html-type": "submit",
                                  style: {"width":"240px"},
                                  type: "primary"
                                }, {
                                  default: _withCtx(() => _cache[2] || (_cache[2] = [
                                    _createElementVNode("span", { style: {"font-weight":"600"} }, "登录", -1)
                                  ])),
                                  _: 1
                                }),
                                _cache[3] || (_cache[3] = _createElementVNode("p", { class: "my-3" }, [
                                  _createTextVNode("没有账号？ "),
                                  _createElementVNode("a", { href: "/user/register" }, "点击注册")
                                ], -1))
                              ])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["model"])
      ])
    ])
  ]))
}
}

})