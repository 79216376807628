import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { id: "addQuestion" }
const _hoisted_2 = { style: {"display":"flex","margin-top":"12px","margin-bottom":"30px","align-items":"center","justify-content":"center"} }
const _hoisted_3 = { style: {"margin-top":"33px","display":"flex","align-items":"center","justify-content":"center"} }

import {onMounted, ref} from 'vue';
  import MDEditor from "@/components/MDEditor.vue";
  import {Message} from "@arco-design/web-vue";
  import {QuestionControllerService} from "../../../generated";
  import {useRoute, useRouter} from "vue-router";
  import message from "@arco-design/web-vue/es/message";
  import checkAccess from "@/access/checkAccess";
  import store from "@/store";
  import ACCESS_ENUM from "@/access/accessEnum";


  
export default /*@__PURE__*/_defineComponent({
  __name: 'UpdateQuestion',
  setup(__props) {

  const form = ref({
    title: "",
    content: "",
    tags: [""],
    answer: "",
    submitNum: 0,
    acceptedNum: 0,
    judgeConfig: {
      memoryLimit: 1000,
      stackLimit: 1000,
      timeLimit: 1000
    },
    judgeCase: [
      {
        input: "",
        output: ""
      },
    ],
  });

  /**
   * 加载题目数据
   */
  const route = useRoute();
  const loadData = async () => {
    const id = route.query.id;
    if (!id) {
      return;
    }
    const res = await QuestionControllerService.getQuestionByIdUsingGet(
        id as any
    );
    if (res.code === 0) {
      form.value = res.data as any;
      // json 转 js 对象
      if (!form.value.judgeCase) {
        form.value.judgeCase = [
          {
            input: "",
            output: "",
          },
        ];
      } else {
        form.value.judgeCase = JSON.parse(form.value.judgeCase as any);
      }
      if (!form.value.judgeConfig) {
        form.value.judgeConfig = {
          memoryLimit: 1000,
          stackLimit: 1000,
          timeLimit: 1000,
        };
      } else {
        form.value.judgeConfig = JSON.parse(form.value.judgeConfig as any);
      }
      if (!form.value.tags) {
        form.value.tags = [];
      } else {
        form.value.tags = JSON.parse(form.value.tags as any);
      }
    } else {
      message.error("加载失败，" + res.message);
    }
  };
  const router = useRouter();
  onMounted(() => {
    // 权限检查
    const loginUser = store.state.user.loginUser;
    if(!checkAccess(loginUser, ACCESS_ENUM.ADMIN)){
      router.push({
        path: '/noAuth',
        replace: true
      });
    }
    loadData();
  });

  const handleAdd = () => {
    if (form.value) {
      form.value.judgeCase.push({
        input: '',
        output: ''
      })
    }
  };
  const handleDelete = (index: number) => {
    if (form.value) {
      form.value.judgeCase.splice(index, 1)
    }
  }
  const doSubmit = async () => {
    if (form.value.title === '') {
      Message.error("标题不能为空")
      return
    }
    if (form.value.content === '') {
      // console.log("form.value", form.value.content)
      Message.error("内容不能为空")
      return
    }
    if (form.value.tags.length === 0) {
      Message.error("标签里面至少要有个难度吧~")
      return
    }
    if (form.value.answer === '') {
      Message.error("题目解析不能为空")
      return
    }
    if (!form.value.judgeConfig.timeLimit) {
      Message.error("时间限制不能为空")
      return
    } else if (form.value.judgeConfig.timeLimit < 500) {
      Message.error("时间限制不能小于500ms")
      return
    }
    if (!form.value.judgeConfig.memoryLimit) {
      Message.error("空间限制不能为空")
      return
    } else if (form.value.judgeConfig.memoryLimit < 500) {
      Message.error("空间限制不能小于500kb")
      return
    }
    if (!form.value.judgeConfig.stackLimit) {
      Message.error("堆栈限制不能为空")
      return
    } else if (form.value.judgeConfig.stackLimit < 500) {
      Message.error("堆栈限制不能小于500kb")
      return
    }
    const res = await QuestionControllerService.updateQuestionUsingPost(form.value);
    if (res.code == 0) {
      Message.success("修改成功")
      // 0.5s后跳转至/mange/question
      setTimeout(() => {
        window.location.href = "/mange/question"
      }, 200)
    } else {
      Message.error("添加失败" + res.message)
      // console.log("添加失败失败具体结果为：", res)
    }
  }

  const onContentChange = (value: string) => {
    form.value.content = value;
    // console.log("debug", value)
    // alert(1)
  };

  const onAnswerChange = (answer: string) => {
    form.value.answer = answer
  };

  setTimeout(() => {
    const mdList = document.getElementsByClassName("bytemd bytemd-split");
    if (mdList[0] && mdList[1]) {
      (mdList[0] as HTMLElement).addEventListener("click", () => {
        (mdList[0] as HTMLElement).style.zIndex = '2';
        // Message.success("z-index修改")
      });
      (mdList[1] as HTMLElement).addEventListener("click", () => {
        (mdList[1] as HTMLElement).style.zIndex = '2';
        // Message.success("z-index修改")
      });
    }
  }, 0);

return (_ctx: any,_cache: any) => {
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_input_tag = _resolveComponent("a-input-tag")!
  const _component_a_textarea = _resolveComponent("a-textarea")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_input_number = _resolveComponent("a-input-number")!
  const _component_a_space = _resolveComponent("a-space")!
  const _component_a_form = _resolveComponent("a-form")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _cache[8] || (_cache[8] = _createElementVNode("h1", { style: {"text-align":"center"} }, "更改题目", -1)),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_a_form, {
        model: form.value,
        "auto-label-width": ""
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_form_item, {
            field: "title",
            label: "标题"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_input, {
                modelValue: form.value.title,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((form.value.title) = $event)),
                placeholder: "输入标题"
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_a_form_item, {
            field: "content",
            label: "题目内容"
          }, {
            default: _withCtx(() => [
              _createVNode(MDEditor, {
                "handle-change": onContentChange,
                value: form.value.content,
                style: {"width":"100%"}
              }, null, 8, ["value"])
            ]),
            _: 1
          }),
          _createVNode(_component_a_form_item, {
            field: "answer",
            label: "官方题解"
          }, {
            default: _withCtx(() => [
              _createVNode(MDEditor, {
                "handle-change": onAnswerChange,
                value: form.value.answer,
                style: {"width":"100%"}
              }, null, 8, ["value"])
            ]),
            _: 1
          }),
          _createVNode(_component_a_form_item, {
            field: "tags",
            label: "标签"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_input_tag, {
                modelValue: form.value.tags,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((form.value.tags) = $event)),
                "allow-clear": "",
                placeholder: "选择标签"
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_a_form_item, {
            "content-flex": false,
            "merge-props": false,
            label: "案例"
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(form.value.judgeCase, (judgeCaseItem, index) => {
                return (_openBlock(), _createBlock(_component_a_form_item, {
                  key: index,
                  label: `案例 ${index}`,
                  field: "judgeCase",
                  "no-style": ""
                }, {
                  default: _withCtx(() => [
                    (_openBlock(), _createBlock(_component_a_form_item, {
                      key: index,
                      field: `form.judgeCase[${index}].input`,
                      label: `输入案例 ${index}`
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_textarea, {
                          modelValue: judgeCaseItem.input,
                          "onUpdate:modelValue": ($event: any) => ((judgeCaseItem.input) = $event)
                        }, null, 8, ["modelValue", "onUpdate:modelValue"])
                      ]),
                      _: 2
                    }, 1032, ["field", "label"])),
                    (_openBlock(), _createBlock(_component_a_form_item, {
                      key: index,
                      field: `form.judgeCase[${index}].output`,
                      label: `输出案例 ${index}`
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_textarea, {
                          modelValue: judgeCaseItem.output,
                          "onUpdate:modelValue": ($event: any) => ((judgeCaseItem.output) = $event)
                        }, null, 8, ["modelValue", "onUpdate:modelValue"])
                      ]),
                      _: 2
                    }, 1032, ["field", "label"])),
                    _createElementVNode("div", _hoisted_2, [
                      _createVNode(_component_a_button, {
                        status: "danger",
                        onClick: ($event: any) => (handleDelete(index))
                      }, {
                        default: _withCtx(() => _cache[5] || (_cache[5] = [
                          _createTextVNode("Delete")
                        ])),
                        _: 2
                      }, 1032, ["onClick"])
                    ])
                  ]),
                  _: 2
                }, 1032, ["label"]))
              }), 128)),
              _createElementVNode("div", _hoisted_3, [
                _createVNode(_component_a_button, {
                  status: "success",
                  type: "outline",
                  onClick: handleAdd
                }, {
                  default: _withCtx(() => _cache[6] || (_cache[6] = [
                    _createTextVNode("新增测试用例")
                  ])),
                  _: 1
                })
              ])
            ]),
            _: 1
          }),
          _createVNode(_component_a_form_item, {
            "content-flex": false,
            "merge-props": false,
            label: "题目限制"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_space, { direction: "vertical" }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_form_item, {
                    field: "timeLimit",
                    label: "时间限制(ms)",
                    "validate-trigger": "input"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_input_number, {
                        modelValue: form.value.judgeConfig.timeLimit,
                        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((form.value.judgeConfig.timeLimit) = $event)),
                        min: "500",
                        mode: "button",
                        placeholder: "输入时间限制(ms)...",
                        size: "large"
                      }, null, 8, ["modelValue"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_a_form_item, {
                    field: "memoryLimit",
                    label: "空间限制(kb)",
                    "validate-trigger": "input"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_input_number, {
                        modelValue: form.value.judgeConfig.memoryLimit,
                        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((form.value.judgeConfig.memoryLimit) = $event)),
                        min: "500",
                        mode: "button",
                        placeholder: "输入空间限制(ms)...",
                        size: "large"
                      }, null, 8, ["modelValue"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_a_form_item, {
                    field: "stackLimit",
                    label: "堆栈限制(kb)",
                    "validate-trigger": "input"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_input_number, {
                        modelValue: form.value.judgeConfig.stackLimit,
                        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((form.value.judgeConfig.stackLimit) = $event)),
                        min: "500",
                        mode: "button",
                        placeholder: "输入栈限制(kb)...",
                        size: "large"
                      }, null, 8, ["modelValue"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_a_form_item, { style: {"display":"flex","width":"100%","align-items":"center","justify-content":"center !important"} }, {
            default: _withCtx(() => [
              _createVNode(_component_a_button, {
                "html-type": "submit",
                type: "primary",
                onClick: doSubmit
              }, {
                default: _withCtx(() => _cache[7] || (_cache[7] = [
                  _createTextVNode("Submit")
                ])),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["model"])
    ])
  ], 64))
}
}

})