<template>
  <div id="questionsView">
    <a-form :model="searchParams" class="w-100 justify-content-center" layout="inline">
      <a-form-item field="title" label="名称" style="min-width: 240px;width: 40%">
        <a-input v-model="searchParams.title" placeholder="请输入名称"/>
      </a-form-item>
      <a-form-item field="tags" label="标签" style="min-width: 240px;width: 40%">
        <a-input-tag v-model="searchParams.tags" placeholder="请输入标签"/>
      </a-form-item>
      <a-form-item style="width: 12%">
        <a-button class="w-100" type="primary" @click="doSubmit">提交</a-button>
      </a-form-item>
    </a-form>
    <a-divider size="0"/>
    <a-table
        :columns="columns"
        :data="datalist"
        :pagination="{pageSize: searchParams.pageSize,
        current: searchParams.current,
        total,
        showTotal: false
        }"
        @page-change="onPageChange"
    >
      <template #title="{record}">
        <span class="title" @click="toQuestionPage(record)">{{ record.title }}</span>
      </template>
      <template #tagList="{record}">
        <a-tag v-for="tag in record.tagList" :key="tag.id" color="green" style="margin-right: 1vh;">
          {{ tag }}
        </a-tag>
      </template>
      <template #authorName="{ record }">
        <span style="font-weight: 600;"> {{ record.user.userName ?? '错误'}} </span>
      </template>
      <template #createTime="{ record }">
        {{ formatDate(new Date(record.createTime)) }}
      </template>
      <!--<template #optional="{ record }">-->
      <!--  <a-space>-->
      <!--    <a-button type="primary" @click="toQuestionPage(record)"> -->
      <!--      做题-->
      <!--    </a-button>-->
      <!--  </a-space>-->
      <!--</template>-->
    </a-table>
  </div>
</template>

<style scoped>
  .title {
    cursor: pointer;
  }

  #questionsView {
    max-width: 1280px;
    margin: 0 auto;
  }
</style>

<script lang="ts" setup>
  import {onMounted, ref, watchEffect} from 'vue';
  import {PostFavourControllerService, PostVO} from "../../../generated";
  import message from "@arco-design/web-vue/es/message";
  import {useRouter} from "vue-router";
  import formatDate from "../../access/Date";


  const total = ref(0);
  const datalist = ref([]);
  const searchParams = ref({
    searchText: "",
    current: 1,
    pageSize: 10,
  });

  const loadData = async () => {
    const res = await PostFavourControllerService.listMyFavourPostByPageUsingPost(searchParams.value);
    if (res.code === 0) {
      datalist.value = res.data.records;
      total.value = res.data.total;
    } else {
      message.error("加载失败," + res.message);
    }
  }
  // 初始化时请求数据
  onMounted(() => {
    loadData();
  });

  const columns = [
    {
      title: "标题",
      slotName: "title",
    },
    {
      title: "标签",
      slotName: "tagList",
    },
    {
      title: "作者",
      slotName: "authorName",
    },
    {
      title: "创建时间",
      slotName: "createTime",
    },
    // {
    //   slotName: "optional",
    // },
  ];

  const router = useRouter();

  const onPageChange = (page: number) => {
    searchParams.value = {
      ...searchParams.value,
      current: page
    }
  }

  // 监听数据变化
  watchEffect(() => {
    loadData();
  });

  /**
   * 跳转到做题页面
   * @param question
   */
  const toQuestionPage = (post: PostVO) => {
    router.push({
      path: `/view/post/${post.id}`,
    });
  };

  /**
   * 确认搜索，重新加载数据
   */
  const doSubmit = () => {
    // 这里需要重置搜索页号
    searchParams.value = {
      ...searchParams.value,
      current: 1,
    };
  };

</script>
