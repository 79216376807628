<template>
  <div>
    <Viewer
        :locale="zhHans"
        :plugins="plugins"
        :tabindex="2"
        :value="value"
        class="viewer"
    />
  </div>
</template>
<style>
  .code-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f3f4f5;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    padding: 10px;
  }

  .code-action {
    display: flex;
    align-items: center;
  }

  .code-btn-copy:hover {
    background-color: #3e8e41;
    font-family: "Microsoft YaHei", serif;
  }

  .code-lang {
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
  }

  .code-block {
    font-size: 3ch;
    background-color: #272822;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    padding: 10px;
  }

  .code-block pre {
    margin: 0;
  }

  .code-block code {
    font-size: 3ch;
    font-family: "Microsoft YaHei", serif !important;
  }

</style>

<script lang='ts' setup>
  import gfm from '@bytemd/plugin-gfm'
  import {Viewer} from '@bytemd/vue-next'
  import highlight from '@bytemd/plugin-highlight'
  import "highlight.js/styles/default.css"
  import 'bytemd/dist/index.css'
  import 'katex/dist/katex.css'
  import {defineProps, withDefaults} from "vue";
  import 'highlight.js/styles/a11y-light.css'
  import highlightssr from '@bytemd/plugin-highlight-ssr'
  import breaks from '@bytemd/plugin-breaks'
  import footnotes from '@bytemd/plugin-footnotes'
  import frontmatter from '@bytemd/plugin-frontmatter'
  import gemoji from '@bytemd/plugin-gemoji'
  import mediumZoom from '@bytemd/plugin-medium-zoom'
  import zhHans from "bytemd/lib/locales/zh_Hans.json"
  import 'highlight.js/styles/vs.css'
  import 'juejin-markdown-themes/dist/juejin.min.css'
  import math from "@bytemd/plugin-math";
  import type {BytemdPlugin} from 'bytemd'
  import hljs from 'highlight.js'

  /**
   * 定义组件属性类型
   */
  interface Props {
    value: string;
  }

  const plugins = [
    gfm(),
    highlight(),
    highlightssr(),
    breaks(),
    frontmatter(),
    footnotes(),
    gemoji(),
    math(),
    mediumZoom(),
    code(),
  ]

  /**
   * 给组件指定初始值
   */
  const props = withDefaults(defineProps<Props>(), {
    value: () => "",
  });

  const copyToClipboard = (text: any) => {
    navigator.clipboard.writeText(text)
  }

  function code(): BytemdPlugin {
    return {
      viewerEffect({markdownBody}): void | (() => void) {
        const codes = markdownBody.querySelectorAll('code')
        codes.forEach((code, key) => {
          let className: string = code.className
          //  首字母大写
          let lan = className.replace('language-', '');
          if (lan.startsWith("hljs ")) {
            lan = lan.replace("hljs ", "");
          }
          const parentNode: any = code.parentNode
          parentNode.removeChild(code)
          const fatherBox = document.createElement('div')
          fatherBox.innerHTML =
              '<div class="code-header"><svg xmlns="http://www.w3.org/2000/svg" width="54" height="14" viewBox="0 0 54 14"><g fill="none" fill-rule="evenodd" transform="translate(1 1)"><circle cx="6" cy="6" r="6" fill="#FF5F56" stroke="#E0443E" stroke-width=".5"></circle><circle cx="26" cy="6" r="6" fill="#FFBD2E" stroke="#DEA123" stroke-width=".5"></circle><circle cx="46" cy="6" r="6" fill="#27C93F" stroke="#1AAB29" stroke-width=".5"></circle></g></svg><div class="code-action"> ' +
              '<span class="code-lang">' +
              lan +
              '</span></div></div><div class="code-block"><code id="code-content-' +
              '" class="code-content- '+
              code.className +
              '">' +
              code.innerHTML +
              '</code></div>'
          parentNode.appendChild(fatherBox)
          document.querySelectorAll('code.code-content-').forEach((el: any) => {
            hljs.highlightElement(el)
          })
        })
      },
    }
  }
</script>
