// initial state
import { StoreOptions } from "vuex";
import ACCESS_ENUM from "@/access/accessEnum";
import { UserControllerService } from "../../generated";

export default {
  namespaced: true,
  state: () => ({
    loginUser: {
      userProfile:"什么都没有捏~",
      userAvatar: "http://snc013327.hn-bkt.clouddn.com/0a8b42538fbd4e929a15ac03c7a75dcb.png",
      userName: "未登录",
      userAccount: "notLogin",
      userRole: ACCESS_ENUM.NOT_LOGIN,
      id: -1,
    },
  }),
  actions: {
    async getLoginUser({ commit, state }, payload) {
      // 从远程请求获取登录信息
      const res = await UserControllerService.getLoginUserUsingGet();
      // console.log("登录信息:",res)
      if (res.code === 0) {
        commit("updateUser", res.data);
      } else {
        commit("updateUser", {
          ...state.loginUser,
          userRole: ACCESS_ENUM.NOT_LOGIN,
        });
      }
    },
  },
  mutations: {
    updateUser(state, payload) {
      state.loginUser = payload;
    },
  },
} as StoreOptions<any>;

