import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  class: "my-0 p-2",
  style: {"background":"#f0f2f5","min-height":"90vh"}
}
const _hoisted_2 = { class: "container p-0" }
const _hoisted_3 = {
  key: 0,
  class: "row m-0"
}
const _hoisted_4 = { class: "col-12 col-xl-8 m-0 p-0" }
const _hoisted_5 = { class: "card" }
const _hoisted_6 = { class: "card-header" }
const _hoisted_7 = {
  class: "m-2",
  style: {"margin-bottom":"0"}
}
const _hoisted_8 = { class: "m-2 text-body-secondary" }
const _hoisted_9 = { class: "card-body p-3" }
const _hoisted_10 = { class: "card-footer bg-light-subtle p-0 m-0" }
const _hoisted_11 = { class: "w-100 rounded-0 list-group text-center list-group-horizontal p-0 row m-0" }
const _hoisted_12 = {
  key: "heart",
  class: "action"
}
const _hoisted_13 = { key: 0 }
const _hoisted_14 = { key: 1 }
const _hoisted_15 = {
  key: "star",
  class: "action"
}
const _hoisted_16 = { key: 0 }
const _hoisted_17 = { key: 1 }
const _hoisted_18 = { class: "rounded-0 list-item-container list-group-item col-3" }
const _hoisted_19 = { class: "list-group p-0" }
const _hoisted_20 = { key: 0 }
const _hoisted_21 = {
  class: "card my-2",
  style: {"background":"#ffffff"}
}
const _hoisted_22 = { class: "d-flex my-2 p-3" }
const _hoisted_23 = ["src"]
const _hoisted_24 = { class: "card w-100 shadow border-0 p-3" }
const _hoisted_25 = {
  class: "d-flex justify-content-between",
  style: {"margin-top":"1rem"}
}
const _hoisted_26 = { class: "d-flex" }
const _hoisted_27 = { class: "p-2" }
const _hoisted_28 = ["src"]
const _hoisted_29 = { class: "rounded-0 list-item-container" }
const _hoisted_30 = { class: "list-group p-0" }
const _hoisted_31 = { key: 0 }
const _hoisted_32 = ["onClick"]
const _hoisted_33 = {
  id: "author",
  class: "col-3",
  style: {"margin-left":"1.5rem","z-index":"0"}
}
const _hoisted_34 = { class: "row card shadow align-items-center sticky-top d-none d-xl-block" }
const _hoisted_35 = { class: "card-header d-flex flex-column" }
const _hoisted_36 = { class: "d-flex text-center" }
const _hoisted_37 = ["src"]
const _hoisted_38 = { class: "d-block" }
const _hoisted_39 = { class: "name mx-3" }
const _hoisted_40 = { class: "center my-0" }
const _hoisted_41 = { class: "intro" }
const _hoisted_42 = {
  key: 0,
  class: "list-group rounded-0 other-post-li p-0 m-0"
}
const _hoisted_43 = ["onClick"]

import {onMounted, ref, watch} from "vue";
  import {
    PostControllerService,
    PostFavourControllerService,
    PostReply,
    PostReplyControllerService,
    PostThumbControllerService,
    PostVO
  } from "../../../generated";
  import message from "@arco-design/web-vue/es/message";
  import MDViewerPost from "@/components/MDViewerPost.vue";
  import {
    IconDelete,
    IconExclamationCircle,
    IconHeart,
    IconHeartFill,
    IconSettings,
    IconShareAlt,
    IconStar,
    IconStarFill,
    IconUnorderedList
  } from "@arco-design/web-vue/es/icon";
  import store from "@/store";
  import formatDate from "@/access/Date";
  import checkAccess from "@/access/checkAccess";
  import ACCESS_ENUM from "@/access/accessEnum";
  import MDViewerForAI from "@/components/MDViewerForAI.vue";
  import {useRouter} from "vue-router";

  interface Props {
    id: string;
  }

  
export default /*@__PURE__*/_defineComponent({
  __name: 'ViewPostView',
  props: {
    id: { default: () => "" }
  },
  setup(__props: any) {

  const router = useRouter();

  const props = __props;

  const post = ref<PostVO>();
  const otherPosts = ref<PostVO[]>();
  const postReplyList = ref<PostReply[]>([]);
  // 监听 props.id 的变化
  watch(() => props.id, async (newId) => {
    // message.info(newId)
    if (newId) {
      await loadData();
    }
  });

  const postReply = ref({
    postId: props.id as any,
    content: "",
  });
  const postReplyQueryRequest = {
    current: 1,
    pageSize: 5,
    postId: props.id as any,
  };
  const loadComponents = async () => {
    postReplyQueryRequest.postId = props.id as any;
    const res = await PostReplyControllerService.listPostReplyPageUsingPost(postReplyQueryRequest)
    if (res.code == 0) {
      postReply.value = res.data.records;
      postReplyList.value = res.data.records;
    } else {
      message.error("加载失败，" + res.message);
    }
  }
  const doClear = () => {
    postReply.value.content = "";
  }
  const doReply = async () => {
    postReply.value.postId = props.id as any;
    if (!postReply.value.content) {
      message.error("请输入评论内容");
      return;
    }
    const res = await PostReplyControllerService.addPostReplyUsingPost({
      postId: props.id as any,
      content: postReply.value.content
    });
    if (res.code == 0) {
      postReply.value.content = "";
      await loadComponents();
      message.success("评论成功");
      return;
    }
    message.error("评论失败，" + res.message);
  }
  const loadData = async () => {
    const res = await PostControllerService.getPostVoByIdUsingGet(
        props.id as any
    );
    if (res.code === 0) {
      post.value = res.data;
    } else {
      await router.push({
        path: '/404',
        replace: true
      });
      return;
    }
    const otherPostsRes = await PostControllerService.listPostByPageUsingPost({
      userId: res.data?.userId,
      current: 1,
      pageSize: 10,
    })
    if (otherPostsRes.code === 0) {
      otherPosts.value = otherPostsRes.data.records;
    } else {
      message.error("加载失败，" + otherPostsRes.message);
    }
    postReplyList.value = [];
    // 只有当帖子存在时才加载评论
    if (post.value) {
      await loadComponents();
    }
  };

  onMounted(async () => {
    await loadData();
    await loadComponents();
  });
  const toPost = async (id = 1) => {
    await router.push({
      path: `/view/post/${id}`,
    });
    await loadData();
    // message.success("帖子加载成功");
    postReplyList.value = [];
    await loadComponents();
    console.log(postReplyList.value)
    // message.success("回复加载成功");
  };

  const onLikeChange = async (item: PostVO | undefined) => {
    if (!item) {
      return;
    }
    const res = await PostThumbControllerService.doThumbUsingPost({postId: item.id});
    if (res.code !== 0) {
      message.error("点赞失败" + res.message);
      return;
    }
    item.thumbNum = item.thumbNum + (item.hasThumb ? -1 : 1) ?? 0;
    item.hasThumb = !item.hasThumb;
  }
  const onStarChange = async (item: PostVO) => {
    if (!item) {
      message.error("收藏失败，帖子为空");
      return;
    }
    const res = await PostFavourControllerService.doPostFavourUsingPost({postId: item.id});
    if (res.code !== 0) {
      message.error("收藏失败" + res.message);
      return;
    }
    item.favourNum = item.favourNum + (item.hasFavour ? -1 : 1);
    item.hasFavour = !item.hasFavour;
  };
  const visible = ref(false);

  const handleOk = () => {
    visible.value = false;
  };
  const handleCancel = () => {
    visible.value = false;
  }
  const shareUrl = ref();
  const doShare = (post: PostVO) => {
    try {
      navigator.clipboard.writeText(`我在浙纺OJ发现了『 ${post?.title} 』这篇神帖，快来看看: ${window.location.href}`).then(() => {
        message.success("复制成功");
      });
    } catch (e) {
      shareUrl.value = (`我在浙纺OJ发现了『 ${post?.title} 』这篇神帖，快来看看: ${window.location.href}`);
      message.error("由于浏览器安全策略复制失败，请手动复制或配置浏览器设置");
      visible.value = true;
    }
  }
  // function LogStr (str: string) {
  //   console.log(str);
  //   return str;
  // }
  const doDelete = async (post: PostVO) => {
    const res = await PostControllerService.deletePostUsingPost({id: post.id});
    if (res.code === 0) {
      await router.push({
        path: "/",
      });
      message.success("删除成功");
    } else {
      message.error("删除失败", res.message);
    }
  }

  const doUpdate = (post: PostVO) => {
    router.push({
      path: "/update/post",
      query: {
        id: post.id
      }
    })
  }

  const toOtherUser = (id: number) => {
    router.push({
      path: `/view/other/${id}`,
    });
  }

  const doDeleteReply = async (id: number) => {
    const res = await PostReplyControllerService.deletePostReplyUsingPost({id: id});
    if (res.code === 0) {
      await loadComponents();
      message.success("删除成功");
    } else {
      message.error("删除失败", res.message);
    }
  }


return (_ctx: any,_cache: any) => {
  const _component_a_tag = _resolveComponent("a-tag")!
  const _component_a_modal = _resolveComponent("a-modal")!
  const _component_a_popover = _resolveComponent("a-popover")!
  const _component_a_avatar = _resolveComponent("a-avatar")!
  const _component_a_textarea = _resolveComponent("a-textarea")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_empty = _resolveComponent("a-empty")!
  const _component_a_divider = _resolveComponent("a-divider")!
  const _component_a_comment = _resolveComponent("a-comment")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (post.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("h1", _hoisted_7, _toDisplayString(post.value.title), 1),
                  _createElementVNode("div", _hoisted_8, " 作者：" + _toDisplayString(post.value.user.userName) + " | 发布日期：" + _toDisplayString(_unref(formatDate)(new Date(post.value.createTime))), 1)
                ]),
                _createElementVNode("div", _hoisted_9, [
                  _createVNode(MDViewerPost, {
                    value:  post.value.content || '',
                    style: {"width":"100%"}
                  }, null, 8, ["value"])
                ]),
                _createElementVNode("div", _hoisted_10, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(post.value.tagList, (item) => {
                    return (_openBlock(), _createElementBlock("span", {
                      key: item,
                      class: "mx-2"
                    }, [
                      _createVNode(_component_a_tag, {
                        class: "rounded-1",
                        style: {"margin-top":"1rem","margin-bottom":"1rem"}
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(item), 1)
                        ]),
                        _: 2
                      }, 1024)
                    ]))
                  }), 128)),
                  _createElementVNode("div", _hoisted_11, [
                    _createElementVNode("span", {
                      class: "rounded-0 list-item-container list-group-item col-3",
                      onClick: _cache[0] || (_cache[0] = ($event: any) => (onLikeChange(post.value)))
                    }, [
                      _createElementVNode("span", _hoisted_12, [
                        (post.value.hasThumb)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_13, [
                              _createVNode(_unref(IconHeartFill), { style: { color: '#f53f3f' } })
                            ]))
                          : (_openBlock(), _createElementBlock("span", _hoisted_14, [
                              _createVNode(_unref(IconHeart))
                            ])),
                        _createTextVNode(_toDisplayString(post.value.thumbNum), 1)
                      ])
                    ]),
                    _createElementVNode("span", {
                      class: "rounded-0 list-item-container list-group-item col-3",
                      onClick: _cache[1] || (_cache[1] = ($event: any) => (onStarChange(post.value)))
                    }, [
                      _createElementVNode("span", _hoisted_15, [
                        (post.value.hasFavour)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_16, [
                              _createVNode(_unref(IconStarFill), { style: { color: '#ffb400' } })
                            ]))
                          : (_openBlock(), _createElementBlock("span", _hoisted_17, [
                              _createVNode(_unref(IconStar))
                            ])),
                        _createTextVNode(" " + _toDisplayString(post.value.favourNum), 1)
                      ])
                    ]),
                    _createElementVNode("span", {
                      class: "rounded-0 list-item-container list-group-item col-3",
                      onClick: _cache[3] || (_cache[3] = ($event: any) => (doShare(post.value)))
                    }, [
                      _createVNode(_unref(IconShareAlt)),
                      _cache[11] || (_cache[11] = _createTextVNode(" 分享 ")),
                      _createVNode(_component_a_modal, {
                        visible: visible.value,
                        "onUpdate:visible": _cache[2] || (_cache[2] = ($event: any) => ((visible).value = $event)),
                        "hide-cancel": true,
                        "ok-text": "确定",
                        onCancel: handleCancel,
                        onOk: handleOk
                      }, {
                        title: _withCtx(() => _cache[10] || (_cache[10] = [
                          _createTextVNode(" 请手动复制如下内容 ")
                        ])),
                        default: _withCtx(() => [
                          _createElementVNode("div", null, _toDisplayString(shareUrl.value), 1)
                        ]),
                        _: 1
                      }, 8, ["visible"])
                    ]),
                    _createVNode(_component_a_popover, {
                      class: "",
                      trigger: "click"
                    }, {
                      content: _withCtx(() => [
                        _createElementVNode("div", _hoisted_19, [
                          _createElementVNode("p", {
                            class: "list-group-item-action list-group-item-light pointer",
                            onClick: _cache[4] || (_cache[4] = ()=>{_unref(message).warning('这个功能懒得写了，建议直接联系管理员~')})
                          }, [
                            _cache[13] || (_cache[13] = _createTextVNode("   ")),
                            _createVNode(_unref(IconExclamationCircle)),
                            _cache[14] || (_cache[14] = _createTextVNode(" 举报    "))
                          ]),
                          (_unref(checkAccess)(_unref(store).state.user.loginUser,_unref(ACCESS_ENUM).ADMIN) || post.value.user.id === _unref(store)?.state.user.loginUser.id)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_20, [
                                _createElementVNode("p", {
                                  class: "list-group-item-action list-group-item-light pointer",
                                  onClick: _cache[5] || (_cache[5] = ($event: any) => (doUpdate(post.value)))
                                }, [
                                  _cache[15] || (_cache[15] = _createTextVNode("   ")),
                                  _createVNode(_unref(IconSettings)),
                                  _cache[16] || (_cache[16] = _createTextVNode(" 修改    "))
                                ]),
                                _createElementVNode("p", {
                                  class: "list-group-item-action list-group-item-light pointer",
                                  onClick: _cache[6] || (_cache[6] = ($event: any) => (doDelete(post.value)))
                                }, [
                                  _cache[17] || (_cache[17] = _createTextVNode("   ")),
                                  _createVNode(_unref(IconDelete)),
                                  _cache[18] || (_cache[18] = _createTextVNode(" 删除    "))
                                ])
                              ]))
                            : _createCommentVNode("", true)
                        ])
                      ]),
                      default: _withCtx(() => [
                        _createElementVNode("span", _hoisted_18, [
                          _createVNode(_unref(IconUnorderedList)),
                          _cache[12] || (_cache[12] = _createTextVNode(" 操作 "))
                        ])
                      ]),
                      _: 1
                    })
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_21, [
                _cache[26] || (_cache[26] = _createElementVNode("div", {
                  class: "card-header",
                  style: {"background":"none"}
                }, [
                  _createElementVNode("h4", null, "评论")
                ], -1)),
                _createElementVNode("div", _hoisted_22, [
                  _createVNode(_component_a_avatar, {
                    size: 60,
                    shape: "square",
                    style: {"margin-right":"1rem"}
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("img", {
                        src: _unref(store).state.user?.loginUser?.userAvatar,
                        alt: "avatar",
                        style: {"height":"60px","width":"60px","max-width":"150px !important"}
                      }, null, 8, _hoisted_23)
                    ]),
                    _: 1
                  }),
                  _createElementVNode("div", _hoisted_24, [
                    _createVNode(_component_a_textarea, {
                      modelValue: postReply.value.content,
                      "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((postReply.value.content) = $event)),
                      "auto-size": {
                      minRows: 3,
                      maxRows: 7
                    },
                      "max-length": 666,
                      placeholder: '来和' + post.value.user?.userName + '同学说点什么吧~',
                      class: "w-100 rounded-2",
                      "show-word-limit": ""
                    }, null, 8, ["modelValue", "placeholder"]),
                    _createElementVNode("div", _hoisted_25, [
                      _createElementVNode("div", _hoisted_26, [
                        _createVNode(_component_a_button, {
                          icon: "smile",
                          style: {"margin-right":"1rem"},
                          type: "text",
                          onClick: doClear
                        }, {
                          default: _withCtx(() => _cache[19] || (_cache[19] = [
                            _createTextVNode("清空")
                          ])),
                          _: 1
                        })
                      ]),
                      _createElementVNode("button", {
                        class: "btn btn-primary",
                        style: {"width":"16%"},
                        type: "button",
                        onClick: doReply
                      }, "发布")
                    ])
                  ])
                ]),
                (!postReplyList.value ||postReplyList.value.length === 0)
                  ? (_openBlock(), _createBlock(_component_a_empty, {
                      key: 0,
                      description: "暂无评论"
                    }))
                  : _createCommentVNode("", true),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(postReplyList.value, (item) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: item.id
                  }, [
                    _createVNode(_component_a_divider),
                    _createElementVNode("div", _hoisted_27, [
                      _createVNode(_component_a_comment, {
                        author: item?.user.userName,
                        datetime: _unref(formatDate)(new Date(item.updateTime as string)),
                        align: "right"
                      }, {
                        avatar: _withCtx(() => [
                          _createVNode(_component_a_avatar, {
                            size: 45,
                            shape: "square",
                            onClick: ($event: any) => (toOtherUser(item?.user.id as number))
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("img", {
                                src: item?.user.userAvatar,
                                alt: "avatar"
                              }, null, 8, _hoisted_28)
                            ]),
                            _: 2
                          }, 1032, ["onClick"])
                        ]),
                        content: _withCtx(() => [
                          _createElementVNode("div", null, [
                            _createVNode(MDViewerForAI, {
                              value: item.content
                            }, null, 8, ["value"])
                          ])
                        ]),
                        actions: _withCtx(() => [
                          _createVNode(_component_a_popover, {
                            class: "",
                            trigger: "click"
                          }, {
                            content: _withCtx(() => [
                              _createElementVNode("div", _hoisted_30, [
                                _createElementVNode("p", {
                                  class: "list-group-item-action list-group-item-light pointer",
                                  onClick: _cache[8] || (_cache[8] = ()=>{_unref(message).warning('这个功能懒得写了，建议直接联系管理员~')})
                                }, [
                                  _cache[21] || (_cache[21] = _createTextVNode("   ")),
                                  _createVNode(_unref(IconExclamationCircle)),
                                  _cache[22] || (_cache[22] = _createTextVNode(" 举报    "))
                                ]),
                                (_unref(checkAccess)(_unref(store).state.user.loginUser,_unref(ACCESS_ENUM).ADMIN) || item.userId === _unref(store)?.state.user.loginUser.id)
                                  ? (_openBlock(), _createElementBlock("div", _hoisted_31, [
                                      _createElementVNode("p", {
                                        class: "list-group-item-action list-group-item-light pointer",
                                        onClick: ($event: any) => (doDeleteReply(item.id as any))
                                      }, [
                                        _cache[23] || (_cache[23] = _createTextVNode("   ")),
                                        _createVNode(_unref(IconDelete)),
                                        _cache[24] || (_cache[24] = _createTextVNode(" 删除    "))
                                      ], 8, _hoisted_32)
                                    ]))
                                  : _createCommentVNode("", true)
                              ])
                            ]),
                            default: _withCtx(() => [
                              _createElementVNode("span", _hoisted_29, [
                                _createVNode(_unref(IconUnorderedList)),
                                _cache[20] || (_cache[20] = _createTextVNode(" 操作 "))
                              ])
                            ]),
                            _: 2
                          }, 1024)
                        ]),
                        _: 2
                      }, 1032, ["author", "datetime"])
                    ])
                  ]))
                }), 128)),
                (postReplyList.value && postReplyList.value.length > 0)
                  ? (_openBlock(), _createBlock(_component_a_divider, {
                      key: 1,
                      margin: 10,
                      class: "my-3"
                    }, {
                      default: _withCtx(() => _cache[25] || (_cache[25] = [
                        _createElementVNode("span", {
                          class: "text-secondary",
                          style: {"font-size":"10px"}
                        }, "已经到底了~", -1)
                      ])),
                      _: 1
                    }))
                  : _createCommentVNode("", true)
              ])
            ]),
            _createElementVNode("div", _hoisted_33, [
              _createElementVNode("div", _hoisted_34, [
                _createElementVNode("div", _hoisted_35, [
                  _createElementVNode("div", _hoisted_36, [
                    _createVNode(_component_a_avatar, {
                      size: 60,
                      class: "",
                      shape: "square",
                      style: {"margin-right":"0.5rem"},
                      onClick: _cache[9] || (_cache[9] = ($event: any) => (toOtherUser(post.value.user?.id as number)))
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("img", {
                          src: post.value.user?.userAvatar,
                          alt: "avatar",
                          style: {"max-width":"150px !important","max-height":"150px !important"}
                        }, null, 8, _hoisted_37)
                      ]),
                      _: 1
                    }),
                    _createElementVNode("div", _hoisted_38, [
                      _createElementVNode("h5", _hoisted_39, _toDisplayString(post.value.user?.userName), 1),
                      _createElementVNode("div", _hoisted_40, [
                        _createElementVNode("span", _hoisted_41, _toDisplayString(post.value.user.userProfile ?? "这个b没有简介"), 1)
                      ])
                    ])
                  ])
                ]),
                (otherPosts.value)
                  ? (_openBlock(), _createElementBlock("ul", _hoisted_42, [
                      _cache[27] || (_cache[27] = _createElementVNode("li", { class: "list-group-item other-post-li-title" }, "作者分享", -1)),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(otherPosts.value, (item) => {
                        return (_openBlock(), _createElementBlock("li", {
                          key: item.id,
                          class: "list-group-item list-group-item-action text-truncate pointer",
                          onClick: ($event: any) => (toPost(item.id))
                        }, _toDisplayString(item.title), 9, _hoisted_43))
                      }), 128))
                    ]))
                  : _createCommentVNode("", true)
              ])
            ])
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}
}

})