<template>
  <div id="basicLayout">
    <a-layout style="min-height: 100vh">
      <a-layout-header class="header">
        <GlobalHeader />
      </a-layout-header>
      <a-layout-content class="content p-2">
        <router-view/>
      </a-layout-content>
      <a-layout-footer class="footer">
        <FooterInfo />
      </a-layout-footer>
    </a-layout>
  </div>
</template>

<style scoped>
  #basicLayout {
  }

  #basicLayout .header {
    margin-bottom: 16px;
    box-shadow: #eee 1px 1px 5px;
  }

  #basicLayout .content {
    background: linear-gradient(to right, #fefefe, #fff);
    margin-bottom: 16px;
  }

  #basicLayout .footer {
    padding: 16px;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
  }
</style>
<script>
  import GlobalHeader from "@/components/GlobalHeader";
  import FooterInfo from "@/components/FooterInfo.vue";

  export default {
    components: {FooterInfo, GlobalHeader},
  };
</script>

