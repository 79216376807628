import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { id: "OtherUser" }
const _hoisted_2 = {
  id: "banner",
  class: "container text-center p-4 shadow",
  style: {"min-width":"80%"}
}
const _hoisted_3 = { class: "row align-items-center" }
const _hoisted_4 = {
  key: 0,
  class: "col-auto d-flex"
}
const _hoisted_5 = ["src"]
const _hoisted_6 = { class: "col-auto align-middle text-center" }
const _hoisted_7 = { class: "name" }
const _hoisted_8 = { class: "intro" }
const _hoisted_9 = { class: "intro" }
const _hoisted_10 = { class: "row mt-2" }
const _hoisted_11 = { class: "col-2" }
const _hoisted_12 = { class: "details-content" }
const _hoisted_13 = { class: "col-2" }
const _hoisted_14 = { class: "details-content" }
const _hoisted_15 = { class: "d-flex container justify-content-center" }
const _hoisted_16 = { class: "my-3 w-75 text-center" }
const _hoisted_17 = { class: "card" }
const _hoisted_18 = { class: "card-header container text-primary" }
const _hoisted_19 = { class: "container p-2" }
const _hoisted_20 = ["onClick"]
const _hoisted_21 = { key: 0 }
const _hoisted_22 = { key: 0 }

import store from "@/store";
  import {
    PostControllerService,
    PostQueryRequest,
    PostVO,
    QuestionControllerService,
    UserControllerService
  } from "../../../generated";
  import {onMounted, ref} from "vue";
  import message from "@arco-design/web-vue/es/message";
  import formatDate from "../../access/Date";
  import {useRouter} from "vue-router";

  interface Props {
    id: string;
  }

  
export default /*@__PURE__*/_defineComponent({
  __name: 'OtherUserView',
  props: {
    id: { default: () => "" }
  },
  setup(__props: any) {

  const acCount = ref(0);
  const tableRef = ref();
  const curUser = ref<any>(null); // 初始化为 null 或 {}
  const uid = store.state.user.loginUser.id;
  let total = ref(0);
  const router = useRouter();
  const columns = [
    {
      title: "标题",
      slotName: "title",
      ellipsis: true,
    },
    {
      title: "创建时间",
      slotName: "createTime",
    },
    {
      title: '上次修改时间',
      slotName: 'updateTime',
    },
    {
      title: '标签',
      slotName: "tagList",
      ellipsis: true,
    },
  ];

  const props = __props;


  const searchParams = ref<PostQueryRequest>({
    searchText: "",
    tags: [],
    pageSize: 8,
    current: 1,
    userId: props.id as any,
  });


  // message.info("id" + props.id);
  const loginUser = store.state.user.loginUser;
  if (props.id === loginUser.id) {
    router.push({
      path: "/mine",
      replace: true
    });
  }

  const myPosts = ref<PostVO[]>([]);
  const isLoading = ref(false);

  const getCurrentUser = async () => {
    const res = await UserControllerService.getUserVoByIdUsingGet(props.id as any);
    if (res.code !== 0) {
      await router.push({
        path: '/404',
        replace: true
      });
      return;
    }
    curUser.value = res.data;
  };

  const loadData = async () => {
    await getCurrentUser();
    isLoading.value = true;
    const acCountRes = await QuestionControllerService.getAcCountByUserIdUsingGet(props.id as any);
    if (acCountRes.code === 0) {
      acCount.value = acCountRes.data ?? 0;
    } else {
      message.error("加载失败，" + acCountRes.message);
    }
    const res = await PostControllerService.listPostVoByPageUsingPost(searchParams.value);
    if (res.code === 0) {
      myPosts.value = res.data.records;
      total.value = res.data.total;
    } else {
      await router.push({
        path: '/404',
        replace: true
      });
    }
    isLoading.value = false;
  };

  const onPageChange = async (page: number) => {
    searchParams.value = {
      ...searchParams.value,
      current: page
    };
    await loadData();
  };

  const toPostPage = (post: PostVO) => {
    router.push({
      path: `/view/post/${post.id}`,
    });
  };

  onMounted(async () => {
    await loadData();
  });

return (_ctx: any,_cache: any) => {
  const _component_a_avatar = _resolveComponent("a-avatar")!
  const _component_a_table = _resolveComponent("a-table")!
  const _component_a_empty = _resolveComponent("a-empty")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (curUser.value?.userAvatar)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createVNode(_component_a_avatar, {
                size: 120,
                style: { backgroundColor: '#14C9C9' },
                class: "bg-primary-subtle shadow",
                shape: "square"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("img", {
                    src: curUser.value.userAvatar,
                    alt: "avatar"
                  }, null, 8, _hoisted_5)
                ]),
                _: 1
              })
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("span", _hoisted_7, _toDisplayString(curUser.value?.userName ?? "没有"), 1),
          _cache[1] || (_cache[1] = _createElementVNode("br", null, null, -1)),
          _createElementVNode("span", _hoisted_8, [
            _cache[0] || (_cache[0] = _createElementVNode("span", { class: "text-dark-emphasis" }, null, -1)),
            _createTextVNode(_toDisplayString(curUser.value?.userAccount || "undefinedAndNull"), 1)
          ]),
          _cache[2] || (_cache[2] = _createElementVNode("br", null, null, -1)),
          _createElementVNode("p", _hoisted_9, _toDisplayString(curUser.value?.userProfile ?? "这个逼很赖，没有写简介"), 1)
        ])
      ]),
      _createElementVNode("div", _hoisted_10, [
        _createElementVNode("div", _hoisted_11, [
          _cache[3] || (_cache[3] = _createElementVNode("span", { class: "details-title" }, " AC数量： ", -1)),
          _createElementVNode("span", _hoisted_12, _toDisplayString(acCount.value), 1)
        ]),
        _createElementVNode("div", _hoisted_13, [
          _cache[4] || (_cache[4] = _createElementVNode("span", { class: "details-title" }, " 发帖数量： ", -1)),
          _createElementVNode("span", _hoisted_14, _toDisplayString(_unref(total)), 1)
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_15, [
      _createElementVNode("div", _hoisted_16, [
        _createElementVNode("div", _hoisted_17, [
          _createElementVNode("div", _hoisted_18, [
            _createElementVNode("h4", null, _toDisplayString(curUser.value?.userName+" " ?? '空空 ') + "同学的发帖记录", 1)
          ]),
          _createElementVNode("div", _hoisted_19, [
            _createVNode(_component_a_table, {
              ref: tableRef.value,
              bordered: true,
              columns: columns,
              data: myPosts.value,
              loading: isLoading.value,
              pagination: {
                 pageSize: searchParams.value.pageSize,
                 current: searchParams.value.current,
                 total: _unref(total),
                },
              tooltip: true,
              class: "pointer",
              onRowClick: toPostPage,
              onPageChange: onPageChange
            }, {
              title: _withCtx(({record}) => [
                _createElementVNode("span", {
                  class: "title",
                  onClick: ($event: any) => (toPostPage(record))
                }, _toDisplayString(record.title), 9, _hoisted_20)
              ]),
              createTime: _withCtx(({ record }) => [
                _createTextVNode(_toDisplayString(_unref(formatDate)(new Date(record.createTime))), 1)
              ]),
              updateTime: _withCtx(({ record }) => [
                _createTextVNode(_toDisplayString(_unref(formatDate)(new Date(record.updateTime))), 1)
              ]),
              tagList: _withCtx(({record}) => [
                (record.tagList.length === 0)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_21, " 啥也木有！ "))
                  : _createCommentVNode("", true),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(record.tagList, (tag) => {
                  return (_openBlock(), _createElementBlock("span", {
                    key: tag.id,
                    class: "badge text-bg-secondary mx-1",
                    style: {"font-weight":"500"}
                  }, _toDisplayString(tag), 1))
                }), 128))
              ]),
              _: 1
            }, 8, ["data", "loading", "pagination"]),
            (myPosts.value.length < 8)
              ? (_openBlock(), _createElementBlock("div", _hoisted_22, [
                  _createVNode(_component_a_empty, { description: "没有更多了" })
                ]))
              : _createCommentVNode("", true)
          ])
        ])
      ])
    ])
  ]))
}
}

})