// 添加请求拦截器
import axios from "axios";
import message from "@arco-design/web-vue/es/message";

axios.defaults.withCredentials = true;
axios.interceptors.request.use(function (config) {
    // todo:在发送请求之前做些什么
    return config;
}, function (error) {
    // todo:对请求错误做些什么
    return Promise.reject(error);
});

axios.interceptors.response.use(function (response) {
    // console.log("响应：", response)
    // 2xx 范围内的状态码都会触发该函数。
    // 对响应数据做点什么
    return response;
}, function (error) {
    // 超出 2xx 范围的状态码都会触发该函数。
    // 对响应错误做点什么
    message.error("请求失败" + error)
    return Promise.reject(error);
});