import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, unref as _unref } from "vue"

const _hoisted_1 = {
  id: "all",
  class: "layout-demo"
}
const _hoisted_2 = { class: "table table-hover" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = {
  key: 0,
  class: "w-auto"
}
const _hoisted_5 = {
  class: "container rounded-2 p-2 text-nowrap",
  style: {"background":"#f6f7f9","width":"auto !important"}
}
const _hoisted_6 = { class: "row" }
const _hoisted_7 = { class: "col" }
const _hoisted_8 = { class: "col" }
const _hoisted_9 = { class: "col" }
const _hoisted_10 = { class: "row my-4" }
const _hoisted_11 = { class: "col" }
const _hoisted_12 = { class: "col" }
const _hoisted_13 = { class: "col" }
const _hoisted_14 = { class: "row" }
const _hoisted_15 = { class: "col" }
const _hoisted_16 = { class: "col" }
const _hoisted_17 = { class: "col" }
const _hoisted_18 = {
  class: "row my-4",
  style: {"max-height":"25vh"}
}
const _hoisted_19 = {
  key: 0,
  class: "w-auto"
}
const _hoisted_20 = {
  class: "container rounded-2 p-2 text-nowrap",
  style: {"background":"#f6f7f9","width":"auto !important"}
}
const _hoisted_21 = { class: "row" }
const _hoisted_22 = { class: "col" }
const _hoisted_23 = { class: "col" }
const _hoisted_24 = { class: "col" }
const _hoisted_25 = { class: "row my-4" }
const _hoisted_26 = { class: "col" }
const _hoisted_27 = { class: "col" }
const _hoisted_28 = { class: "col" }
const _hoisted_29 = { class: "row" }
const _hoisted_30 = { class: "col" }
const _hoisted_31 = { class: "col" }
const _hoisted_32 = { class: "col" }
const _hoisted_33 = {
  key: 0,
  class: "row my-4"
}

import {onMounted, ref} from "vue";
  import message from "@arco-design/web-vue/es/message";
  import CodeEditor from "@/components/CodeEditor.vue";
  import {
    QuestionControllerService,
    QuestionSubmitAddRequest,
    QuestionSubmitControllerService,
    QuestionVO,
  } from "../../../generated";
  import MDViewer from "@/components/MDViewer.vue";
  import {Message} from "@arco-design/web-vue";
  import MDViewerAns from "@/components/MDViewerAns.vue";
  import store from "../../store";
  import MDViewerQuestionSubmit from "@/components/MDViewerQuestionSubmit.vue";
  import formatDate from "../../access/Date";

  interface JudgeInfo {
    message: string;
    memory: string;
    time: string;
  }

  interface QuestionSubmitVO {
    id: string;
    language: string;
    code: string;
    judgeInfo: JudgeInfo;
    questionId: string;
    userId: string;
    createTime: string;
    updateTime: string;
  }

  interface Props {
    id: string;
  }

  
export default /*@__PURE__*/_defineComponent({
  __name: 'ViewQuestionView',
  props: {
    id: { default: () => "" }
  },
  setup(__props: any) {

  const visible = ref(false);
  const sure = ref("确认");

  const props = __props;

  const question = ref<QuestionVO>();
  const isLoading = ref(false);
  const curSubId = ref(1);
  const uid = store.state.user.loginUser.id;
  const list = ref<QuestionSubmitVO[]>([]);
  const lastSubmit = ref<QuestionSubmitVO>();

  const loadData = async () => {
    const res = await QuestionControllerService.getQuestionVoByIdUsingGet(
        props.id as any
    );
    if (res.code === 0) {
      question.value = res.data;
    } else {
      message.error("加载失败，" + res.message);
    }
    const pageRes = await QuestionSubmitControllerService.listQuestionSubmitByPageUsingPost(
        {
          current: 1,
          pageSize: 20,
          questionId: question.value?.id,
          userId: uid,
        });
    if (pageRes.code === 0) {
      list.value = pageRes.data?.records;
      if (list.value && list.value.length > 0) {
        lastSubmit.value = list.value[0];
        if (lastSubmit.value.judgeInfo.memory == null) {
          // message.error("lastSubmit");
          lastSubmit.value.judgeInfo = {
            message: "COMPILE ERROR",
            memory: "0",
            time: "0",
          };
        }
      }
    } else {
      Message.error("历史记录加载失败，" + pageRes.message);
    }
  };


  const form = ref<QuestionSubmitAddRequest>({
    language: "cpp",
    code: "",
    questionId: parseInt(props.id) as number,
  });

  /**
   * 提交代码
   */
  const doSubmit = async () => {

    if (!question.value?.id) {
      Message.error("题目不存在");
      return;
    }
    isLoading.value = true;
    const res = await QuestionSubmitControllerService.doQuestionSubmitUsingPost({
      ...form.value,
      questionId: question.value.id as number,
    });
    if (res.code === 0) {
      // Message.success("提交成功");
      curSubId.value = res.data;
      const pageRes = await QuestionSubmitControllerService.listQuestionSubmitByPageUsingPost(
          {
            current: 1,
            pageSize: 20,
            questionId: question.value?.id,
            userId: uid,
          });
      if (pageRes.code === 0) {
        list.value = pageRes.data?.records;
        if (list.value && list.value.length > 0) {
          lastSubmit.value = list.value[0];
          if (lastSubmit.value.judgeInfo.memory == null) {
            // message.error("lastSubmit");
            lastSubmit.value.judgeInfo = {
              message: "COMPILE ERROR",
              memory: "0",
              time: "0",
            };
          }
        }
        // console.log(lastSubmit)
      } else {
        Message.error("提交失败，" + pageRes.message);
      }
      visible.value = true;
    } else {
      Message.error("提交失败，" + res.message);
    }
    isLoading.value = false;
  };

  const getColor = (message: string | null) => {
    if (!message) {
      return "black";
    }
    if (message.includes("Accepted")) {
      return "green";
    } else if (message.includes("Wrong Answer")) {
      return "red";
    } else if (message.includes("Time Limit Exceeded")) {
      return "orange";
    } else if (message.includes("Memory Limit Exceeded")) {
      return "#2e468c";
    } else if (message.includes("Runtime Error")) {
      return "blue";
    } else if (message.includes("System Error")) {
      return "blue";
    }
    // 编译错误
    return "#8d44ad";
  }

  /**
   * 页面加载时，请求数据
   */
  onMounted(async () => {
    await loadData();
    // 获取.arco-card-body
    // console.log("fuck"+store.state.user.loginUser.userAccount)
  });

  const changeCode = (value: string) => {
    // console.log(value)
    form.value.code = value;
  };

  const getTagColor = (tag: string) => {
    switch (tag) {
      case '简单':
        return 'green';
      case '中等':
        return 'orangered';
      case '困难':
        return 'red';
      default:
        return 'magenta';
    }
  }

  const getMarkdownCode = (code: string, language: string) => {
    return "```" + language + '\n' + code + "\n```";
  }
  const firstUpperCase = (str: string) => {
    return str.charAt(0).toUpperCase() + str.substring(1);
  }
  // 历史提交详情查看
  const historySubmit = ref<QuestionSubmitVO>();
  const historyVisible = ref(false);
  const checkHistorySubmit = (item: QuestionSubmitVO) => {
    historySubmit.value = item;
    historyVisible.value = true;
  }

return (_ctx: any,_cache: any) => {
  const _component_a_descriptions_item = _resolveComponent("a-descriptions-item")!
  const _component_a_descriptions = _resolveComponent("a-descriptions")!
  const _component_a_tag = _resolveComponent("a-tag")!
  const _component_a_space = _resolveComponent("a-space")!
  const _component_a_card = _resolveComponent("a-card")!
  const _component_a_tab_pane = _resolveComponent("a-tab-pane")!
  const _component_a_modal = _resolveComponent("a-modal")!
  const _component_a_tabs = _resolveComponent("a-tabs")!
  const _component_a_typography_paragraph = _resolveComponent("a-typography-paragraph")!
  const _component_a_option = _resolveComponent("a-option")!
  const _component_a_select = _resolveComponent("a-select")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_split = _resolveComponent("a-split")!
  const _component_a_layout_content = _resolveComponent("a-layout-content")!
  const _component_a_layout = _resolveComponent("a-layout")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_layout_footer = _resolveComponent("a-layout-footer")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_a_layout, { class: "card" }, {
      default: _withCtx(() => [
        _createVNode(_component_a_layout_content, null, {
          default: _withCtx(() => [
            _createVNode(_component_a_split, {
              style: {
        height: 'calc(90vh - 50px)',
        width: '100%',
        },
              min: "80px"
            }, {
              first: _withCtx(() => [
                _createVNode(_component_a_typography_paragraph, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_tabs, { "default-active-key": "question" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_tab_pane, {
                          key: "question",
                          title: "题目"
                        }, {
                          default: _withCtx(() => [
                            (question.value)
                              ? (_openBlock(), _createBlock(_component_a_card, {
                                  key: 0,
                                  title: question.value.title
                                }, {
                                  extra: _withCtx(() => [
                                    _createVNode(_component_a_space, { wrap: "" }, {
                                      default: _withCtx(() => [
                                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(question.value.tags, (tag, index) => {
                                          return (_openBlock(), _createBlock(_component_a_tag, {
                                            key: index,
                                            color: getTagColor(tag)
                                          }, {
                                            default: _withCtx(() => [
                                              _createTextVNode(_toDisplayString(tag), 1)
                                            ]),
                                            _: 2
                                          }, 1032, ["color"]))
                                        }), 128))
                                      ]),
                                      _: 1
                                    })
                                  ]),
                                  default: _withCtx(() => [
                                    _createVNode(_component_a_descriptions, {
                                      column: { xs: 1, md: 2, lg: 3 },
                                      title: "判题条件"
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_a_descriptions_item, { label: "时间限制：" }, {
                                          default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(question.value.judgeConfig.timeLimit ?? 0) + "ms ", 1)
                                          ]),
                                          _: 1
                                        }),
                                        _createVNode(_component_a_descriptions_item, { label: "内存限制：" }, {
                                          default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(question.value.judgeConfig.memoryLimit ?? 0) + "kb ", 1)
                                          ]),
                                          _: 1
                                        }),
                                        _createVNode(_component_a_descriptions_item, { label: "堆栈限制：" }, {
                                          default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(question.value.judgeConfig.stackLimit ?? 0) + "kb ", 1)
                                          ]),
                                          _: 1
                                        })
                                      ]),
                                      _: 1
                                    }),
                                    _createVNode(MDViewer, {
                                      value: question.value.content || '',
                                      style: {"color":"#2f2f33 !important"}
                                    }, null, 8, ["value"])
                                  ]),
                                  _: 1
                                }, 8, ["title"]))
                              : _createCommentVNode("", true)
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_a_tab_pane, {
                          key: "comment",
                          title: "评论"
                        }, {
                          default: _withCtx(() => _cache[3] || (_cache[3] = [
                            _createTextVNode(" 没写...")
                          ])),
                          _: 1
                        }),
                        _createVNode(_component_a_tab_pane, {
                          key: "answer",
                          title: "官方题解"
                        }, {
                          default: _withCtx(() => [
                            (question.value)
                              ? (_openBlock(), _createBlock(_component_a_card, {
                                  key: 0,
                                  title: question.value.title
                                }, {
                                  extra: _withCtx(() => [
                                    _createVNode(_component_a_space, { wrap: "" }, {
                                      default: _withCtx(() => [
                                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(question.value.tags, (tag, index) => {
                                          return (_openBlock(), _createBlock(_component_a_tag, {
                                            key: index,
                                            color: getTagColor(tag)
                                          }, {
                                            default: _withCtx(() => [
                                              _createTextVNode(_toDisplayString(tag), 1)
                                            ]),
                                            _: 2
                                          }, 1032, ["color"]))
                                        }), 128))
                                      ]),
                                      _: 1
                                    })
                                  ]),
                                  default: _withCtx(() => [
                                    _createVNode(_component_a_descriptions, {
                                      column: { xs: 1, md: 2, lg: 3 },
                                      title: "判题条件"
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_a_descriptions_item, { label: "时间限制：" }, {
                                          default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(question.value.judgeConfig.timeLimit ?? 0) + "ms ", 1)
                                          ]),
                                          _: 1
                                        }),
                                        _createVNode(_component_a_descriptions_item, { label: "内存限制：" }, {
                                          default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(question.value.judgeConfig.memoryLimit ?? 0) + "kb ", 1)
                                          ]),
                                          _: 1
                                        }),
                                        _createVNode(_component_a_descriptions_item, { label: "堆栈限制：" }, {
                                          default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(question.value.judgeConfig.stackLimit ?? 0) + "kb ", 1)
                                          ]),
                                          _: 1
                                        })
                                      ]),
                                      _: 1
                                    }),
                                    _createVNode(MDViewerAns, {
                                      value: question.value.answer || '',
                                      style: {"min-height":"80vh","color":"#2f2f33 !important"}
                                    }, null, 8, ["value"])
                                  ]),
                                  _: 1
                                }, 8, ["title"]))
                              : _createCommentVNode("", true)
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_a_tab_pane, {
                          key: "submit",
                          title: "我的提交"
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("table", _hoisted_2, [
                              _cache[4] || (_cache[4] = _createElementVNode("thead", { class: "bg-secondary-subtle" }, [
                                _createElementVNode("tr", null, [
                                  _createElementVNode("th", {
                                    class: "th-bg",
                                    scope: "col"
                                  }, "状态"),
                                  _createElementVNode("th", {
                                    class: "th-bg",
                                    scope: "col"
                                  }, "语言"),
                                  _createElementVNode("th", {
                                    class: "th-bg",
                                    scope: "col"
                                  }, "运行时间"),
                                  _createElementVNode("th", {
                                    class: "th-bg",
                                    scope: "col"
                                  }, "运用内存"),
                                  _createElementVNode("th", {
                                    class: "th-bg",
                                    scope: "col"
                                  }, "提交时间")
                                ])
                              ], -1)),
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(list.value, (submit, key) => {
                                return (_openBlock(), _createElementBlock("tbody", { key: key }, [
                                  _createElementVNode("tr", {
                                    class: "pointer",
                                    onClick: ($event: any) => (checkHistorySubmit(submit))
                                  }, [
                                    _createElementVNode("th", {
                                      style: _normalizeStyle({ color: getColor(submit.judgeInfo.message) })
                                    }, _toDisplayString(submit.judgeInfo.message), 5),
                                    _createElementVNode("td", null, _toDisplayString(firstUpperCase(submit.language)), 1),
                                    _createElementVNode("td", null, _toDisplayString(submit.judgeInfo.time) + "ms", 1),
                                    _createElementVNode("td", null, _toDisplayString(submit.judgeInfo.memory) + "kb", 1),
                                    _createElementVNode("td", null, _toDisplayString(_unref(formatDate)(new Date(submit.updateTime))), 1)
                                  ], 8, _hoisted_3)
                                ]))
                              }), 128))
                            ]),
                            _createVNode(_component_a_modal, {
                              visible: historyVisible.value,
                              "onUpdate:visible": _cache[0] || (_cache[0] = ($event: any) => ((historyVisible).value = $event)),
                              "hide-cancel": true,
                              "ok-text": sure.value,
                              draggable: "",
                              width: "62vw"
                            }, {
                              title: _withCtx(() => _cache[5] || (_cache[5] = [
                                _createElementVNode("h4", {
                                  class: "my-1",
                                  style: {"color":"#1c80f2"}
                                }, "历史提交结果", -1)
                              ])),
                              default: _withCtx(() => [
                                (question.value && historySubmit.value)
                                  ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                                      _createElementVNode("div", _hoisted_5, [
                                        _createElementVNode("div", _hoisted_6, [
                                          _createElementVNode("div", _hoisted_7, [
                                            _cache[6] || (_cache[6] = _createElementVNode("div", { class: "row-title" }, [
                                              _createTextVNode("题目"),
                                              _createElementVNode("br")
                                            ], -1)),
                                            _createElementVNode("span", null, _toDisplayString(question.value.title), 1)
                                          ]),
                                          _createElementVNode("div", _hoisted_8, [
                                            _cache[7] || (_cache[7] = _createElementVNode("div", { class: "row-title" }, [
                                              _createTextVNode("用户"),
                                              _createElementVNode("br")
                                            ], -1)),
                                            _createElementVNode("span", null, _toDisplayString(_unref(store).state.user.loginUser.userAccount) + "   " + _toDisplayString(_unref(store).state.user?.loginUser?.userName), 1)
                                          ]),
                                          _createElementVNode("div", _hoisted_9, [
                                            _cache[8] || (_cache[8] = _createElementVNode("div", { class: "row-title" }, [
                                              _createTextVNode("提交时间"),
                                              _createElementVNode("br")
                                            ], -1)),
                                            _createElementVNode("span", null, _toDisplayString(_unref(formatDate)(new Date())), 1)
                                          ])
                                        ]),
                                        _createElementVNode("div", _hoisted_10, [
                                          _createElementVNode("div", _hoisted_11, [
                                            _cache[9] || (_cache[9] = _createElementVNode("div", { class: "row-title" }, [
                                              _createTextVNode("编译器"),
                                              _createElementVNode("br")
                                            ], -1)),
                                            _createElementVNode("span", null, _toDisplayString(firstUpperCase(historySubmit.value.language)), 1)
                                          ]),
                                          _createElementVNode("div", _hoisted_12, [
                                            _cache[10] || (_cache[10] = _createElementVNode("div", { class: "row-title" }, [
                                              _createTextVNode("内存"),
                                              _createElementVNode("br")
                                            ], -1)),
                                            _createElementVNode("span", null, _toDisplayString(historySubmit.value.judgeInfo.memory) + " / " + _toDisplayString(question.value.judgeConfig.memoryLimit) + "kb", 1)
                                          ]),
                                          _createElementVNode("div", _hoisted_13, [
                                            _cache[11] || (_cache[11] = _createElementVNode("div", { class: "row-title" }, [
                                              _createTextVNode("用时"),
                                              _createElementVNode("br")
                                            ], -1)),
                                            _createElementVNode("span", null, _toDisplayString(historySubmit.value.judgeInfo.time) + " / " + _toDisplayString(question.value.judgeConfig.timeLimit) + "ms ", 1)
                                          ])
                                        ]),
                                        _createElementVNode("div", _hoisted_14, [
                                          _createElementVNode("div", _hoisted_15, [
                                            _cache[12] || (_cache[12] = _createElementVNode("div", { class: "row-title" }, [
                                              _createTextVNode("状态"),
                                              _createElementVNode("br")
                                            ], -1)),
                                            _createElementVNode("span", {
                                              style: _normalizeStyle([{ color: getColor(historySubmit.value.judgeInfo.message) }, {"font-weight":"bold"}])
                                            }, _toDisplayString(historySubmit.value.judgeInfo.message), 5)
                                          ]),
                                          _createElementVNode("div", _hoisted_16, [
                                            _cache[13] || (_cache[13] = _createElementVNode("div", { class: "row-title" }, [
                                              _createTextVNode("提交编号"),
                                              _createElementVNode("br")
                                            ], -1)),
                                            _createElementVNode("span", null, _toDisplayString(historySubmit.value.id), 1)
                                          ]),
                                          _createElementVNode("div", _hoisted_17, [
                                            _cache[14] || (_cache[14] = _createElementVNode("div", { class: "row-title" }, [
                                              _createTextVNode("评测时间"),
                                              _createElementVNode("br")
                                            ], -1)),
                                            _createElementVNode("span", null, _toDisplayString(_unref(formatDate)(new Date(historySubmit.value.updateTime))), 1)
                                          ])
                                        ])
                                      ]),
                                      _createElementVNode("div", _hoisted_18, [
                                        _cache[15] || (_cache[15] = _createElementVNode("h5", null, "提交代码", -1)),
                                        _createVNode(MDViewerQuestionSubmit, {
                                          value: getMarkdownCode(historySubmit.value.code, historySubmit.value.language),
                                          style: {"max-height":"25vh"}
                                        }, null, 8, ["value"])
                                      ])
                                    ]))
                                  : _createCommentVNode("", true)
                              ]),
                              _: 1
                            }, 8, ["visible", "ok-text"])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              second: _withCtx(() => [
                _createVNode(_component_a_typography_paragraph, { style: {"height":"calc(90vh - 65px)","overflow":"hidden"} }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_form, {
                      model: form.value,
                      layout: "inline"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_form_item, {
                          field: "language",
                          label: "编程语言",
                          style: {"min-width":"240px"}
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_select, {
                              modelValue: form.value.language,
                              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((form.value.language) = $event)),
                              style: _normalizeStyle([{ width: '320px'}, {"height":"30px"}]),
                              placeholder: "选择编程语言"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_a_option, { value: "cpp" }, {
                                  default: _withCtx(() => _cache[16] || (_cache[16] = [
                                    _createTextVNode("西瓜瓜")
                                  ])),
                                  _: 1
                                }),
                                _createVNode(_component_a_option, { value: "java" }, {
                                  default: _withCtx(() => _cache[17] || (_cache[17] = [
                                    _createTextVNode("爪哇")
                                  ])),
                                  _: 1
                                }),
                                _createVNode(_component_a_option, { value: "python" }, {
                                  default: _withCtx(() => _cache[18] || (_cache[18] = [
                                    _createTextVNode("蟒蛇3")
                                  ])),
                                  _: 1
                                })
                              ]),
                              _: 1
                            }, 8, ["modelValue"])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }, 8, ["model"]),
                    _createVNode(CodeEditor, {
                      "handle-change": changeCode,
                      language: form.value.language,
                      value: form.value.code as string,
                      style: {"min-height":"calc(90vh - 20px)"}
                    }, null, 8, ["language", "value"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_a_layout_footer, {
      class: "container align-items-center",
      style: {"min-width":"100vw"}
    }, {
      default: _withCtx(() => [
        _createVNode(_component_a_button, {
          loading: isLoading.value,
          class: "btn btn-primary h-100 align-items-center p-0 w-25",
          type: "primary",
          onClick: doSubmit
        }, {
          default: _withCtx(() => [
            _createElementVNode("span", null, "提交" + _toDisplayString(form.value.language) + "代码", 1)
          ]),
          _: 1
        }, 8, ["loading"]),
        _createVNode(_component_a_modal, {
          visible: visible.value,
          "onUpdate:visible": _cache[2] || (_cache[2] = ($event: any) => ((visible).value = $event)),
          "hide-cancel": true,
          "ok-text": sure.value,
          draggable: "",
          width: "62vw"
        }, {
          title: _withCtx(() => _cache[19] || (_cache[19] = [
            _createElementVNode("h4", {
              class: "my-1",
              style: {"color":"#1c80f2"}
            }, "本次提交结果", -1)
          ])),
          default: _withCtx(() => [
            (question.value && lastSubmit.value)
              ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
                  _createElementVNode("div", _hoisted_20, [
                    _createElementVNode("div", _hoisted_21, [
                      _createElementVNode("div", _hoisted_22, [
                        _cache[20] || (_cache[20] = _createElementVNode("div", { class: "row-title" }, [
                          _createTextVNode("题目"),
                          _createElementVNode("br")
                        ], -1)),
                        _createElementVNode("span", null, _toDisplayString(question.value.title), 1)
                      ]),
                      _createElementVNode("div", _hoisted_23, [
                        _cache[21] || (_cache[21] = _createElementVNode("div", { class: "row-title" }, [
                          _createTextVNode("用户"),
                          _createElementVNode("br")
                        ], -1)),
                        _createElementVNode("span", null, _toDisplayString(_unref(store).state.user.loginUser.userAccount) + "   " + _toDisplayString(_unref(store).state.user?.loginUser?.userName), 1)
                      ]),
                      _createElementVNode("div", _hoisted_24, [
                        _cache[22] || (_cache[22] = _createElementVNode("div", { class: "row-title" }, [
                          _createTextVNode("提交时间"),
                          _createElementVNode("br")
                        ], -1)),
                        _createElementVNode("span", null, _toDisplayString(_unref(formatDate)(new Date())), 1)
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_25, [
                      _createElementVNode("div", _hoisted_26, [
                        _cache[23] || (_cache[23] = _createElementVNode("div", { class: "row-title" }, [
                          _createTextVNode("编译器"),
                          _createElementVNode("br")
                        ], -1)),
                        _createElementVNode("span", null, _toDisplayString(firstUpperCase(form.value.language)), 1)
                      ]),
                      _createElementVNode("div", _hoisted_27, [
                        _cache[24] || (_cache[24] = _createElementVNode("div", { class: "row-title" }, [
                          _createTextVNode("内存"),
                          _createElementVNode("br")
                        ], -1)),
                        _createElementVNode("span", null, _toDisplayString(lastSubmit.value.judgeInfo.memory) + " / " + _toDisplayString(question.value.judgeConfig.memoryLimit) + "kb", 1)
                      ]),
                      _createElementVNode("div", _hoisted_28, [
                        _cache[25] || (_cache[25] = _createElementVNode("div", { class: "row-title" }, [
                          _createTextVNode("用时"),
                          _createElementVNode("br")
                        ], -1)),
                        _createElementVNode("span", null, _toDisplayString(lastSubmit.value.judgeInfo.time) + " / " + _toDisplayString(question.value.judgeConfig.timeLimit) + "ms ", 1)
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_29, [
                      _createElementVNode("div", _hoisted_30, [
                        _cache[26] || (_cache[26] = _createElementVNode("div", { class: "row-title" }, [
                          _createTextVNode("状态"),
                          _createElementVNode("br")
                        ], -1)),
                        _createElementVNode("span", {
                          style: _normalizeStyle([{ color: getColor(lastSubmit.value.judgeInfo.message) }, {"font-weight":"bold"}])
                        }, _toDisplayString(lastSubmit.value.judgeInfo.message), 5)
                      ]),
                      _createElementVNode("div", _hoisted_31, [
                        _cache[27] || (_cache[27] = _createElementVNode("div", { class: "row-title" }, [
                          _createTextVNode("提交编号"),
                          _createElementVNode("br")
                        ], -1)),
                        _createElementVNode("span", null, _toDisplayString(curSubId.value), 1)
                      ]),
                      _createElementVNode("div", _hoisted_32, [
                        _cache[28] || (_cache[28] = _createElementVNode("div", { class: "row-title" }, [
                          _createTextVNode("评测时间"),
                          _createElementVNode("br")
                        ], -1)),
                        _createElementVNode("span", null, _toDisplayString(_unref(formatDate)(new Date())), 1)
                      ])
                    ])
                  ]),
                  (form.value.code && form.value.language)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_33, [
                        _cache[29] || (_cache[29] = _createElementVNode("h5", null, "提交代码", -1)),
                        _createVNode(MDViewerQuestionSubmit, {
                          value:  getMarkdownCode(form.value.code, form.value.language) 
                        }, null, 8, ["value"])
                      ]))
                    : _createCommentVNode("", true)
                ]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["visible", "ok-text"])
      ]),
      _: 1
    })
  ]))
}
}

})