import { RouteRecordRaw } from "vue-router";
import HomeView from "@/views/HomeView.vue";
import UserLayout from "@/layouts/UserLayout.vue";
import UserLoginView from "@/views/user/UserLoginView.vue";
import UserRegisterView from "@/views/user/UserRegisterView.vue";
import AdminView from "@/views/AdminView.vue";
import NoAuthView from "@/views/error/NoAuthView.vue";
import ACCESS_ENUM from "@/access/accessEnum";
import AddQuestionView from "@/views/question/AddQuestionView.vue";
import ManageQuestionView from "@/views/question/ManageQuestionView.vue";
import UpdateQuestion from "@/views/question/UpdateQuestion.vue";
import QuestionsView from "@/views/question/QuestionsView.vue";
import ViewQuestionView from "@/views/question/ViewQuestionView.vue";
import MineView from "@/views/user/MineView.vue";
import AddPostView from "@/views/post/AddPostView.vue";
import ViewPostView from "@/views/post/ViewPostView.vue";
import UpdatePostView from "@/views/post/UpdatePostView.vue";
import UpdateMyView from "@/views/user/UpdateMyView.vue";
import FavourPostsView from "@/views/post/FavourPostsView.vue";
import OtherUserView from "@/views/user/OtherUserView.vue";
import QuestionSubmitView from "@/views/question/QuestionSubmitView.vue";
import LuckyView from "@/views/LuckyView.vue";

export const routes: Array<RouteRecordRaw> = [
    {
        path: "/user",
        name: "用户",
        component: UserLayout,
        children: [
            {
                path: "/user/login",
                name: "用户登录",
                component: UserLoginView,
            },
            {
                path: "/user/register",
                name: "用户注册",
                component: UserRegisterView,
            },
        ],
        meta: {
            hideInMenu: true,
        },
    },
    {
        path: "/",
        name: "主页",
        component: HomeView,
    },
    {
        path: "/question",
        name: "浏览题目",
        component: QuestionsView,
    },
    {
        path: "/hide",
        name: "隐藏页面",
        component: HomeView,
        meta: {
            hideInMenu: true,
        },
    },
    {
        path: "/noAuth",
        name: "无权限",
        component: NoAuthView,
        meta:{
            hideInMenu: true,
        }
    },
    {
        path: "/mange/question",
        name: "题目管理",
        component: ManageQuestionView,
        meta: {
            access: ACCESS_ENUM.ADMIN,
        },
    },
    {
        path: "/add/question",
        name: "创建题目",
        component: AddQuestionView,
        meta: {
            access: ACCESS_ENUM.ADMIN,
        },
    },
    {
        path: "/update/question",
        name: "更新题目",
        component: UpdateQuestion,
        meta: {
            access: ACCESS_ENUM.ADMIN,
            hideInMenu: true,
        },
    },
    {
        path: "/update/post",
        name: "更新帖子",
        component: UpdatePostView,
        meta: {
            access: ACCESS_ENUM.USER,
            hideInMenu: true,
        },
    },
    {
        path: "/update/mine",
        name: "更新信息",
        component: UpdateMyView,
        meta: {
            access: ACCESS_ENUM.USER,
            hideInMenu: true,
        },
    },
    {
        path: "/add/post",
        name: "发帖",
        component: AddPostView,
        meta: {
            access: ACCESS_ENUM.USER,
        },
    },
    {
        path: "/view/post/:id",
        name: "看帖",
        component: ViewPostView,
        props: true,
        meta: {
            hideInMenu: true,
        },
    },
    {
        path: "/view/favours",
        name: "收藏夹",
        component: FavourPostsView,
        props: true,
        meta: {
            hideInMenu: true,
            access: ACCESS_ENUM.USER,
        },
    },
    {
        path: "/view/question/:id",
        name: "在线做题",
        component: ViewQuestionView,
        props: true,
        meta: {
            access: ACCESS_ENUM.USER,
            hideInMenu: true,
        },
    },
    {
        path: "/view/other/:id",
        name: "别人的主页",
        component: OtherUserView,
        props: true,
        meta: {
            hideInMenu: true,
        },
    },
    {
        path: "/view/submit",
        name: "提交记录",
        component: QuestionSubmitView,
        props: true,
        meta: {
            access: ACCESS_ENUM.USER,
        },
    },
    {
        path: "/luckDraw",
        name: "抽奖",
        component: LuckyView,
    },
    {
        path: "/tool",
        name: "魔法海罗",
        // meta: {
        //     access: ACCESS_ENUM.USER
        // },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/ToolView.vue"),
    },
    {
        path: "/404",
        name: "404",
        component: () => import("../views/error/NotFind404.vue"),
        meta: {
            hideInMenu: true,
        },
    },
    {
        path: "/:pathMatch(.*)", // 匹配所有路由
        redirect: "/404",
    },
    {
        path: "/mine",
        name: "我的",
        component: MineView,
        props: true,
        meta: {
            hideInMenu: true,
            access: ACCESS_ENUM.USER,
        },
    },
];

