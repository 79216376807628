<template>
  <h1 style="text-align: center">修改帖子</h1>
  <div id="addQuestion" style="height: 90vh">
    <a-form :model="form" auto-label-width class="h-100">
      <a-form-item field="title" label="标题">
        <a-input v-model="form.title" placeholder="输入标题"/>
      </a-form-item>
      <a-form-item field="content" label="帖子内容">
        <MDEditor id="md"
                  :handle-change="onContentChange"
                  :value="form.content"
                  style="width: 100%; z-index: 999;"/>
      </a-form-item>
      <a-form-item field="tags" label="标签">
        <a-input-tag v-model="form.tagList" allow-clear placeholder="选择标签"/>
      </a-form-item>
      <a-form-item style="display: flex; width: 100%; align-items: center; justify-content: center !important;">
        <a-button html-type="submit" type="primary" @click="doSubmit">确认发布</a-button>
      </a-form-item>
    </a-form>
  </div>
</template>
<style>
  #addQuestion {
    display: flex;
    justify-content: center;
  }

  .center {
    display: flex;
    justify-content: center;
    width: 100%;
    align-content: center;
    margin-bottom: 40px;
  }

  .arco-form-item-content-flex {
    //display: block !important;
    align-items: normal !important;
    justify-content: flex-start;
  }

  .arco-form-item-content-flex {
    justify-content: center !important;
  }
</style>
<script lang="ts" setup>
  import {onMounted, ref} from 'vue';
  import MDEditor from "@/components/MDEditor.vue";
  import {Message} from "@arco-design/web-vue";
  import {PostControllerService} from "../../../generated";
  import store from "@/store";
  import {useRoute, useRouter} from "vue-router";
  import message from "@arco-design/web-vue/es/message";
  import ACCESS_ENUM from "@/access/accessEnum";

  const router = useRouter();


  const form = ref({
    content: '',
    tagList: [],
    title: ''
  });
  const route = useRoute();
  const id = route.query.id;
  const loadData = async () => {
    if (!id) {
      Message.error("id参数不可为空");
    }
    const res = await PostControllerService.getPostVoByIdUsingGet(id as any);
    if (res.code == 0) {
      form.value = res.data as any;
    } else {
      Message.error("获取数据失败，请查看控制台" + res.message)
    }
  }
  onMounted(async () => {
    const loginUser = store.state.user.loginUser;
    const postUserId = await PostControllerService.getPostVoByIdUsingGet(id as any);
    if (loginUser === ACCESS_ENUM.ADMIN || loginUser.id === postUserId.data?.user?.id) {
      await loadData();
    }else{
      await router.push({
        path: '/noAuth',
        replace: true
      });
    }
  })
  const doSubmit = async () => {
    if (!id) {
      message.error("id为空");
      return;
    }
    // 获取权限
    const userRole = store.state.user.userRole;
    let res;
    if (userRole === ACCESS_ENUM.ADMIN) {
      res = await PostControllerService.updatePostUsingPost({
        ...form.value,
        tags: form.value.tagList,
        id: id as any
      });
    } else {
      res = await PostControllerService.editPostUsingPost({
        ...form.value,
        tags: form.value.tagList,
        id: id as any
      });
    }
    if (res.code == 0) {
      Message.success("修改成功")
      setTimeout(() => {
        router.push({
          path: '/view/post/' + id,
          replace: true
        })
      }, 200);
    } else {
      Message.error("添加失败" + res.message)
    }
  }

  const onContentChange = (value: string) => {
    form.value.content = value;
  };
  setInterval(() => {
    const images = document.querySelectorAll('.medium-zoom-image--opened');
    for (let i = 0; i < images.length; i++) {
      (images[i] as HTMLElement).style.zIndex = String(100); // 示例操作，根据实际需求调整
    }
  }, 300); // 假设每秒执行一次
  onMounted(() => {
    const bytemd = document.querySelector('.bytemd');
    if (bytemd instanceof HTMLElement) {
      bytemd.style.height = "60vh";
    }
  });
</script>

<style scoped>
  #addQuestion {
    /* 隐藏滚动条 */

    ::-webkit-scrollbar {
      display: none;
    }
  }
</style>