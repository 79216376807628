<template>
  <div id="userLoginView" class="container my-5">
    <div class="left-column d-none d-lg-block" style="background: none">
      <!--<video class="w-100 h-100" src="http://image.niwenjia.cn/work.mp4" autoplay loop muted></video>-->
      <Vue3Lottie
          animation-link="http://image.niwenjia.cn/work.json"
          height="500px"
          width="500px"
      />
    </div>
    <div class="right-column card p-0 d-flex"
         style="height: 500px;background-image: linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%);">
      <div class="card-header"><h3>🐱：泥嚎！</h3></div>
      <div class="p-2 my-5 d-flex justify-content-center align-content-center" style="height: 440px;">
        <a-form
            :model="form"
            auto-label-width
            label-align="left"
            style="max-width: 480px; margin: 0 auto"
            @submit="handleSubmit"
        >
          <a-form-item field="userName" label="用户名" tooltip="用户名，不允许重复">
            <a-input v-model="form.userName" class="input-group-text" placeholder="请输入用户名"/>
          </a-form-item>
          <a-form-item field="userAccount" label="账号" tooltip="用于登录，类似微信号，不允许重复">
            <a-input v-model="form.userAccount" class="input-group-text" placeholder="请输入账号名称"/>
          </a-form-item>
          <a-form-item field="pwd" label="密码" tooltip="密码不少于 8 位">
            <a-input-password
                v-model="form.userPassword"
                class="input-group-text"
                placeholder="请输入密码"
            />
          </a-form-item>
          <a-form-item field="checkPwd" label="确认密码" placeholder="请输入密码" tooltip="请确认密码">
            <a-input-password
                v-model="form.checkPassword"
                class="input-group-text"
                placeholder="请确认密码"
            />
          </a-form-item>
          <a-form-item>
            <div class="grid-demo-background">
              <a-space :size="16" direction="vertical" style="display: block;">
                <a-row class="grid-demo">
                  <a-col :span="22">
                    <div>
                      <a-button class="rounded-2" html-type="submit" style="width: 240px" type="primary">
                        <span style="font-weight: 600;">注册</span>
                      </a-button>
                      <p class="my-3">已有账号？ <a href="/user/login">点击登录</a></p>
                    </div>
                  </a-col>
                </a-row>
              </a-space>
            </div>
          </a-form-item>
        </a-form>
      </div>
    </div>
  </div>
</template>

<style scoped>
  #userLoginView {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .left-column, .right-column {
    flex: 1;
  }

  .arco-input-wrapper .arco-input.arco-input-size-large {
    /* prettier-ignore */
    padding-top: 6px;
    /* prettier-ignore */
    padding-bottom: 6px;
    font-size: 14px;
    line-height: 1.5715;
    background-color: white;
  }
</style>

<script lang="ts" setup>
  import {reactive, ref} from "vue";
  import {UserControllerService, UserRegisterRequest} from "../../../generated";
  import {useRouter} from "vue-router";
  import {useStore} from "vuex";
  import {Message} from '@arco-design/web-vue';

  /**
   * 表单信息
   */
  const form = reactive({
    phoneNumber: "",
    userAccount: "",
    userName: "",
    userPassword: "",
    checkPassword: ""
  } as UserRegisterRequest);

  const router = useRouter();
  const store = useStore();

  const onFinish = (value: bigint) => {
    Message.info(`Verification code: ${value}`);
  }
  const len = ref(4);
  /**
   * 提交表单
   * @param data
   */
  const handleSubmit = async () => {
    const res = await UserControllerService.userRegisterUsingPost(form);
    if (res.code === 0) {
      Message.success("注册成功");
      await router.push({
        path: "/user/login",
      });
    } else {
      Message.error("注册失败" + res.message);
    }
  };
</script>
