import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import gfm from '@bytemd/plugin-gfm'
  import {Editor} from '@bytemd/vue-next'
  import highlight from '@bytemd/plugin-highlight'
  import "highlight.js/styles/default.css"
  import 'bytemd/dist/index.css'
  import 'katex/dist/katex.css'
  
  import 'highlight.js/styles/a11y-light.css'
  import highlightssr from '@bytemd/plugin-highlight-ssr'
  import breaks from '@bytemd/plugin-breaks'
  import footnotes from '@bytemd/plugin-footnotes'
  import frontmatter from '@bytemd/plugin-frontmatter'
  import gemoji from '@bytemd/plugin-gemoji'
  import mediumZoom from '@bytemd/plugin-medium-zoom'
  import zhHans from "bytemd/lib/locales/zh_Hans.json"
  import 'highlight.js/styles/vs.css'
  import 'juejin-markdown-themes/dist/juejin.min.css'
  import math from "@bytemd/plugin-math";
  import type {BytemdPlugin} from 'bytemd'
  import hljs from 'highlight.js'
  import {FileControllerService} from "../../generated";
  import message from "@arco-design/web-vue/es/message";

  /**
   * 定义组件属性类型
   */
  interface Props {
    value: string;
    mode?: string;
    handleChange: (v: string) => void;
  }

  
export default /*@__PURE__*/_defineComponent({
  __name: 'MDEditor',
  props: {
    value: { default: () => "" },
    mode: { default: () => "split" },
    handleChange: { type: Function, default: (v: string) => {
      // console.log(v);
    } }
  },
  setup(__props: any) {

  const plugins = [
    gfm(),
    highlight(),
    highlightssr(),
    breaks(),
    frontmatter(),
    footnotes(),
    gemoji(),
    math(),
    mediumZoom(),
    code(),
  ]

  /**
   * 给组件指定初始值
   */
  const props = __props;

  const uploadImage = async (files: File[]) => {
    // todo: files 获取的图片文件，这里处理逻辑
    // console.log(typeof files)
    const res = await FileControllerService.uploadImageUsingPost(files[0]);
    // console.log(res)
    if(res.code !== 0){
      message.error("上传失败"+res.message);
    }
    return [
      {
        title: files.map((i) => i.name),
        // todo: 现在是小猫
        url: res.data
      }
    ]
  }

  const copyToClipboard = (text: string) => navigator.clipboard.writeText(text)

  function code(): BytemdPlugin {
    return {
      viewerEffect({markdownBody}): void | (() => void) {
        const codes = markdownBody.querySelectorAll('code')
        codes.forEach((code, key) => {
          let className: string = code.className
          let lan = className.replace('language-', '').toUpperCase()
          const parentNode: any = code.parentNode
          parentNode.removeChild(code)
          const fatherBox = document.createElement('div')
          fatherBox.innerHTML =
              '<div class="code-header"><svg xmlns="http://www.w3.org/2000/svg" width="54" height="14" viewBox="0 0 54 14"><g fill="none" fill-rule="evenodd" transform="translate(1 1)"><circle cx="6" cy="6" r="6" fill="#FF5F56" stroke="#E0443E" stroke-width=".5"></circle><circle cx="26" cy="6" r="6" fill="#FFBD2E" stroke="#DEA123" stroke-width=".5"></circle><circle cx="46" cy="6" r="6" fill="#27C93F" stroke="#1AAB29" stroke-width=".5"></circle></g></svg><div class="code-action"><span class="code-btn-copy" id="code-btn-copy-' +
              key +
              '">Copy</span><span class="code-lang">' +
              lan +
              '</span></div></div><div class="code-block"><code id="code-content-' +
              key +
              '" class="code-content- ' +
              code.className +
              '">' +
              code.innerHTML +
              '</code></div>'
          parentNode.appendChild(fatherBox)
          let btn: any = document.getElementById('code-btn-copy-' + key)
          btn.onclick = () => {
            copyToClipboard(code.innerText)
            btn.innerHTML = 'Copied'
            // console.log("Fuck")
            // console.log(key+"  "+code.innerText)
            // console.log(codes[key])
          }
          document.querySelectorAll('code.code-content-').forEach((el: any) => {
            hljs.highlightElement(el)
          })
        })
      },
    }
  }



return (_ctx: any,_cache: any) => {
  const _component_Viewer = _resolveComponent("Viewer")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_unref(Editor), {
      id: "md",
      locale: _unref(zhHans),
      mode: _ctx.mode,
      plugins: plugins,
      uploadImages: uploadImage,
      value: _ctx.value,
      class: "editos",
      onChange: _ctx.handleChange
    }, null, 8, ["locale", "mode", "value", "onChange"]),
    _createVNode(_component_Viewer, {
      locale: _unref(zhHans),
      plugins: plugins,
      tabindex: 2,
      value: _ctx.value,
      class: "viewer"
    }, null, 8, ["locale", "value"])
  ]))
}
}

})