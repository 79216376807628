<template>
  <h1 style="text-align: center">发帖</h1>
  <div id="addQuestion" style="height: 90vh">
    <a-form :model="form" class="h-100" auto-label-width>
      <a-form-item field="title" label="标题">
        <a-input v-model="form.title" placeholder="输入标题"/>
      </a-form-item>
      <a-form-item field="content" label="帖子内容">
        <MDEditor id="md"
                  :handle-change="onContentChange"
                  :value="form.content"
                  style="width: 100%; z-index: 999;"/>
      </a-form-item>
      <a-form-item field="tags" label="标签">
        <a-input-tag v-model="form.tags" allow-clear placeholder="选择标签"/>
      </a-form-item>
      <a-form-item style="display: flex; width: 100%; align-items: center; justify-content: center !important;">
        <a-button html-type="submit" type="primary" @click="doSubmit">确认发布</a-button>
      </a-form-item>
    </a-form>
  </div>
</template>
<style>
  #addQuestion {
    display: flex;
    justify-content: center;
  }

  .center {
    display: flex;
    justify-content: center;
    width: 100%;
    align-content: center;
    margin-bottom: 40px;
  }

  .arco-form-item-content-flex {
    //display: block !important;
    align-items: normal !important;
    justify-content: flex-start;
  }

  .arco-form-item-content-flex {
    justify-content: center !important;
  }
</style>
<script lang="ts" setup>
  import {onMounted, ref} from 'vue';
  import MDEditor from "@/components/MDEditor.vue";
  import {Message} from "@arco-design/web-vue";
  import {PostAddRequest, PostControllerService, QuestionControllerService} from "../../../generated";
  import store from "@/store";

  const form = ref({
    content: '',
    tags: [],
    title: ''
  });
  const doSubmit = async () => {
    const res = await PostControllerService.addPostUsingPost(form.value);
    if (res.code == 0) {
      Message.success("添加成功")
      setTimeout(() => {
        window.location.href = `/view/post/${res.data}`;
      }, 200)
    } else {
      Message.error("添加失败" + res.message)
      // console.log("添加失败失败具体结果为：", res)
    }
  }

  const onContentChange = (value: string) => {
    form.value.content = value;
  };
  // setInterval(() => {
  //   const images = document.querySelectorAll('.medium-zoom-image--opened');
  //   for (let i = 0; i < images.length; i++) {
  //     (images[i] as HTMLElement).style.zIndex = String(100); // 示例操作，根据实际需求调整
  //   }
  // }, 300); // 假设每秒执行一次
  onMounted(()=>{
    const bytemd = document.querySelector('.bytemd');
    if(bytemd instanceof HTMLElement){
      bytemd.style.height = "60vh";
    }
  })
</script>

<style scoped>
  #addQuestion {
    /* 隐藏滚动条 */

    ::-webkit-scrollbar {
      display: none;
    }
  }
</style>