<template>
  <h1 style="text-align: center">更改题目</h1>
  <div id="addQuestion">
    <a-form :model="form" auto-label-width>
      <a-form-item field="title" label="标题">
        <a-input v-model="form.title" placeholder="输入标题"/>
      </a-form-item>
      <a-form-item field="content" label="题目内容">
        <MDEditor :handle-change="onContentChange" :value="form.content" style="width: 100%"/>
      </a-form-item>
      <a-form-item field="answer" label="官方题解">
        <MDEditor :handle-change="onAnswerChange" :value="form.answer" style="width: 100%"/>
      </a-form-item>
      <a-form-item field="tags" label="标签">
        <a-input-tag v-model="form.tags" allow-clear placeholder="选择标签"/>
      </a-form-item>
      <a-form-item :content-flex="false" :merge-props="false" label="案例">
        <a-form-item v-for="(judgeCaseItem, index) in form.judgeCase"
                     :key="index"
                     :label="`案例 ${index}`"
                     field="judgeCase"
                     no-style
        >
          <a-form-item
              :key="index"
              :field="`form.judgeCase[${index}].input`"
              :label="`输入案例 ${index}`"
          >
            <a-textarea v-model="judgeCaseItem.input"></a-textarea>
          </a-form-item>
          <a-form-item
              :key="index"
              :field="`form.judgeCase[${index}].output`"
              :label="`输出案例 ${index}`"
          >
            <a-textarea v-model="judgeCaseItem.output"></a-textarea>
          </a-form-item>
          <div
              style="display: flex; margin-top: 12px; margin-bottom: 30px; align-items: center;justify-content: center">
            <a-button status="danger" @click="handleDelete(index)">Delete</a-button>
          </div>
        </a-form-item>
        <div style="margin-top: 33px;display: flex; align-items: center;justify-content: center">
          <a-button status="success" type="outline" @click="handleAdd">新增测试用例</a-button>
        </div>
      </a-form-item>
      <a-form-item :content-flex="false" :merge-props="false" label="题目限制">
        <a-space direction="vertical">
          <a-form-item field="timeLimit" label="时间限制(ms)" validate-trigger="input">
            <a-input-number v-model="form.judgeConfig.timeLimit" min="500" mode="button"
                            placeholder="输入时间限制(ms)..."
                            size="large"/>
          </a-form-item>
          <a-form-item field="memoryLimit" label="空间限制(kb)" validate-trigger="input">
            <a-input-number v-model="form.judgeConfig.memoryLimit" min="500" mode="button"
                            placeholder="输入空间限制(ms)..." size="large"/>
          </a-form-item>
          <a-form-item field="stackLimit" label="堆栈限制(kb)" validate-trigger="input">
            <a-input-number v-model="form.judgeConfig.stackLimit" min="500" mode="button"
                            placeholder="输入栈限制(kb)..."
                            size="large"/>
          </a-form-item>
        </a-space>
      </a-form-item>
      <a-form-item style="display: flex; width: 100%; align-items: center; justify-content: center !important;">
        <a-button html-type="submit" type="primary" @click="doSubmit">Submit</a-button>
      </a-form-item>
    </a-form>
  </div>
</template>
<style>
  #addQuestion {
    display: flex;
    justify-content: center;
  }

  .center {
    display: flex;
    justify-content: center;
    width: 100%;
    align-content: center;
    margin-bottom: 40px;
  }

  .arco-form-item-content-flex {
    //display: block !important;
    align-items: normal !important;
    justify-content: flex-start;
  }

  .arco-form-item-content-flex {
    justify-content: center !important;
  }

</style>
<script lang="ts" setup>
  import {onMounted, ref} from 'vue';
  import MDEditor from "@/components/MDEditor.vue";
  import {Message} from "@arco-design/web-vue";
  import {QuestionControllerService} from "../../../generated";
  import {useRoute, useRouter} from "vue-router";
  import message from "@arco-design/web-vue/es/message";
  import checkAccess from "@/access/checkAccess";
  import store from "@/store";
  import ACCESS_ENUM from "@/access/accessEnum";


  const form = ref({
    title: "",
    content: "",
    tags: [""],
    answer: "",
    submitNum: 0,
    acceptedNum: 0,
    judgeConfig: {
      memoryLimit: 1000,
      stackLimit: 1000,
      timeLimit: 1000
    },
    judgeCase: [
      {
        input: "",
        output: ""
      },
    ],
  });

  /**
   * 加载题目数据
   */
  const route = useRoute();
  const loadData = async () => {
    const id = route.query.id;
    if (!id) {
      return;
    }
    const res = await QuestionControllerService.getQuestionByIdUsingGet(
        id as any
    );
    if (res.code === 0) {
      form.value = res.data as any;
      // json 转 js 对象
      if (!form.value.judgeCase) {
        form.value.judgeCase = [
          {
            input: "",
            output: "",
          },
        ];
      } else {
        form.value.judgeCase = JSON.parse(form.value.judgeCase as any);
      }
      if (!form.value.judgeConfig) {
        form.value.judgeConfig = {
          memoryLimit: 1000,
          stackLimit: 1000,
          timeLimit: 1000,
        };
      } else {
        form.value.judgeConfig = JSON.parse(form.value.judgeConfig as any);
      }
      if (!form.value.tags) {
        form.value.tags = [];
      } else {
        form.value.tags = JSON.parse(form.value.tags as any);
      }
    } else {
      message.error("加载失败，" + res.message);
    }
  };
  const router = useRouter();
  onMounted(() => {
    // 权限检查
    const loginUser = store.state.user.loginUser;
    if(!checkAccess(loginUser, ACCESS_ENUM.ADMIN)){
      router.push({
        path: '/noAuth',
        replace: true
      });
    }
    loadData();
  });

  const handleAdd = () => {
    if (form.value) {
      form.value.judgeCase.push({
        input: '',
        output: ''
      })
    }
  };
  const handleDelete = (index: number) => {
    if (form.value) {
      form.value.judgeCase.splice(index, 1)
    }
  }
  const doSubmit = async () => {
    if (form.value.title === '') {
      Message.error("标题不能为空")
      return
    }
    if (form.value.content === '') {
      // console.log("form.value", form.value.content)
      Message.error("内容不能为空")
      return
    }
    if (form.value.tags.length === 0) {
      Message.error("标签里面至少要有个难度吧~")
      return
    }
    if (form.value.answer === '') {
      Message.error("题目解析不能为空")
      return
    }
    if (!form.value.judgeConfig.timeLimit) {
      Message.error("时间限制不能为空")
      return
    } else if (form.value.judgeConfig.timeLimit < 500) {
      Message.error("时间限制不能小于500ms")
      return
    }
    if (!form.value.judgeConfig.memoryLimit) {
      Message.error("空间限制不能为空")
      return
    } else if (form.value.judgeConfig.memoryLimit < 500) {
      Message.error("空间限制不能小于500kb")
      return
    }
    if (!form.value.judgeConfig.stackLimit) {
      Message.error("堆栈限制不能为空")
      return
    } else if (form.value.judgeConfig.stackLimit < 500) {
      Message.error("堆栈限制不能小于500kb")
      return
    }
    const res = await QuestionControllerService.updateQuestionUsingPost(form.value);
    if (res.code == 0) {
      Message.success("修改成功")
      // 0.5s后跳转至/mange/question
      setTimeout(() => {
        window.location.href = "/mange/question"
      }, 200)
    } else {
      Message.error("添加失败" + res.message)
      // console.log("添加失败失败具体结果为：", res)
    }
  }

  const onContentChange = (value: string) => {
    form.value.content = value;
    // console.log("debug", value)
    // alert(1)
  };

  const onAnswerChange = (answer: string) => {
    form.value.answer = answer
  };

  setTimeout(() => {
    const mdList = document.getElementsByClassName("bytemd bytemd-split");
    if (mdList[0] && mdList[1]) {
      (mdList[0] as HTMLElement).addEventListener("click", () => {
        (mdList[0] as HTMLElement).style.zIndex = '2';
        // Message.success("z-index修改")
      });
      (mdList[1] as HTMLElement).addEventListener("click", () => {
        (mdList[1] as HTMLElement).style.zIndex = '2';
        // Message.success("z-index修改")
      });
    }
  }, 0);
</script>

<style scoped>
  #addQuestion {

  }
</style>