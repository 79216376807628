<template>
  <a-row id="globalHeaderBB" :wrap="false" align="center">
    <a-col flex="auto">
      <a-menu
          :selected-keys="selectedKeys"
          mode="horizontal"
          @menu-item-click="doMenuClick"
      >
        <a-menu-item
            key="0"
            :style="{ padding: 0, marginRight: '38px' }"
            disabled
        >
          <div class="title-bar">
            <img class="logo" src="../assets/ang.svg"/>
            <div class="title">浙纺-OJ</div>
          </div>
        </a-menu-item>
        <a-menu-item v-for="item in visibleRoutes" :key="item.path" style="font-size: 2ch">
          {{ item.name }}
        </a-menu-item>
      </a-menu>
    </a-col>
    <a-col id="my" class="pointer" flex="180px" @click="toLogin" >
      <a-popover position="bottom">
        <div class="button">
          <a-avatar :size=36>
            <img
                alt="avatar"
                :src="store.state.user?.loginUser?.userAvatar"
            />
          </a-avatar>
          {{ store.state.user?.loginUser?.userName ?? "登录/注册" }}
        </div>
        <template  #content>
          <div class="p-2">
            <p class="pointer" style="z-index: 10" @click="toLogin">
              个人主页
            </p>
            <p class="pointer" @click="toFavourPosts">
              我的收藏
            </p>
            <p class="pointer text-danger" @click="logout">
              退出登录
            </p>
          </div>
        </template>
      </a-popover>
    </a-col>
  </a-row>
</template>

<style scoped>
  .pointer {
    cursor: pointer;
  }
</style>

<script lang="ts" setup>
  import {routes} from "@/router/routes";
  import {useRouter} from "vue-router";
  import {computed, ref} from "vue";
  import {useStore} from "vuex";
  import checkAccess from "@/access/checkAccess";
  import ACCESS_ENUM from "@/access/accessEnum";
  import {UserControllerService} from "../../generated";

  const router = useRouter();
  const store = useStore();
  // 获取登录用户
  store.dispatch("user/getLoginUser");
  // console.log("user:",store.state.user.loginUser)

  // 展示在菜单的路由数组
  const visibleRoutes = computed(() => {
    return routes.filter((item, index) => {
      if (item.meta?.hideInMenu) {
        return false;
      }
      // 根据权限过滤菜单
      if (
          !checkAccess(store.state.user.loginUser, item?.meta?.access as string)
      ) {
        return false;
      }
      return true;
    });
  });

  const selectedKeys = ref(["/mine"]);
  // 路由跳转后，更新选中的菜单项
  router.afterEach((to, from, failure) => {
    selectedKeys.value = [to.path];
  });
  const doMenuClick = (key: string) => {
    router.push({
      path: key,
    });
  };

  const toLogin = () => {
    // console.log("信息",store.state.user.loginUser)
    if (store.state.user.loginUser.userRole === ACCESS_ENUM.NOT_LOGIN) {
      router.push({
        path: "/user/login",
      });
    } else {
      router.push({
        path: "/mine",
      });
    }
  }
  // const ans = "show me the code!";
  async function logout() {
    await UserControllerService.userLogoutUsingPost();
    await router.push({
      path: "/user/login",
    });
  }
  const toFavourPosts = () => {
    router.push('/view/favours');
  }
</script>

<style scoped>
  .title-bar {
    display: flex;
    align-items: center;
  }

  .title {
    color: #444;
    margin-left: 16px;
  }

  .logo {
    height: 48px;
  }

</style>
