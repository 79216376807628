<template>
  <div id="home p-2">
    <div class="w-100 d-flex center">
      <!-- 左侧内容 -->
      <div class="" style="width:70%;">
        <!--轮播图开始-->
        <a-carousel
            :default-current="2"
            :style="{
              width: '100%',
              height: '40vh',
            }"
            class="d-none d-lg-block"
            @change="handleChange"
        >
          <a-carousel-item v-for="image in images" :key="image">
            <img
                :src="image"
                :style="{
                 width: '100%',
                 height: '100%'
            }"/>
          </a-carousel-item>
        </a-carousel>
        <!--轮播结束-->

        <!--帖子开始-->
        <div class="input-group my-3 w-100 mx-auto">
          <input v-model="searchParams.searchText" aria-describedby="search"
                 aria-label="Recipient's username"
                 class="form-control orm-control-lg border-2 border-primary-subtle rounded-0"
                 placeholder="输入文本以查询"
                 type="text">
          <button id="search" class="btn btn-outline-primary " type="button" @click="doSearch">搜索</button>
        </div>
        <ul v-for="(item,idx) in datalist" :key="idx" class="list-group my-2">
          <li class="list-group-item">
            <a-comment :author="item.user.userName" :datetime="formatDate(new Date(item.createTime))" align="right">
              <template #actions>
                  <span key="heart" class="action" @click="onLikeChange(item)">
                    <span v-if="item.hasThumb">
                      <IconHeartFill :style="{ color: '#f53f3f' }"/>
                    </span>
                    <span v-else>
                      <IconHeart/>
                    </span>
                    {{ item.thumbNum }}
                  </span>
                <span key="star" class="action" @click="onStarChange(item)">
                    <span v-if="item.hasFavour">
                      <IconStarFill :style="{ color: '#ffb400' }"/>
                    </span>
                    <span v-else>
                      <IconStar/>
                    </span>
                    {{ item.favourNum }}
                  </span>
              </template>
              <template #avatar>
                <a-avatar
                    shape="square"
                    @click="toPost(String(item.id))"
                >
                  <img
                      :src=" item.user.userAvatar"
                      alt="avatar"
                  />
                </a-avatar>
              </template>
              <template #content>
                <div class="post-content" style="max-height: 100px;" @click="toPost(String(item.id))">
                  <a-tag v-for="tag in item.tagList" :key="tag" class="d-none d-sm-inline-flex" color="green"
                         ellipsis style="margin-right: 1vh;" tooltip>
                    {{ tag }}
                  </a-tag>
                  <h4 class="post-title" @click="toPost(String(item.id))"> {{ item.title }}</h4>
                  <span class="d-none d-md-block" @click="toPost(String(item.id))">{{ item.content }}</span>
                </div>
              </template>
            </a-comment>
          </li>
        </ul>
        <!--帖子结束-->
        <a-divider>
          <span style="color: #6b7280; font-size: 10px">看到我代表这里已经没有数据了！</span>
        </a-divider>
      </div>
      <div style="width: 1%"></div>

      <!-- 右侧内容 -->
      <div class="card shadow sticky-top d-xl-block d-none" style="width: 300px; height: 300px; z-index: 0">
        <div class="card-header d-flex justify-content-between align-items-center" style="height: 15%">
          <div>
            <span style="font-weight: bold">{{ store.state.user?.loginUser?.userName ?? "未登录" }}</span>
            <span v-if="userRole === 'user'" class="m-2 badge bg-info">用户</span>
            <span v-else-if="userRole === 'admin' " class="m-2 badge bg-warning">管理员</span>
            <span v-else class="m-2 badge bg-danger-subtle">游客</span>
          </div>
          <a class="link-offset-2 link-underline link-underline-opacity-0" href="#" @click="toQuestions">去写题</a>
        </div>
        <div class="row my-2 p-3" style="height: 30%">
          <div class="col-3 pointer" @click="toAddPost">
            <div class="">
              <div class="icon" style="background: #cce1ff;">
                <svg height="32" p-id="30714" t="1733798152431" version="1.1"
                     viewBox="0 0 1024 1024" width="32" xmlns="http://www.w3.org/2000/svg">
                  <path
                      d="M584.353684 80.842105H112.815158A58.960842 58.960842 0 0 0 53.894737 139.776v825.290105A58.960842 58.960842 0 0 0 112.828632 1024h707.274105a58.960842 58.960842 0 0 0 58.933895-58.933895V375.673263L584.353684 80.842105z"
                      fill="#0076F3" opacity=".3" p-id="30715"></path>
                  <path
                      d="M665.195789 0H193.657263A58.960842 58.960842 0 0 0 134.736842 58.933895v825.290105A58.960842 58.960842 0 0 0 193.670737 943.157895h707.274105a58.960842 58.960842 0 0 0 58.933895-58.933895V294.831158L665.195789 0z"
                      fill="#0076F3" p-id="30716"></path>
                  <path
                      d="M665.195789 0v235.749053a58.960842 58.960842 0 0 0 58.933895 58.947368H959.865263L665.195789 0z"
                      fill="#49A2FF" p-id="30717"></path>
                  <path d="M724.129684 294.696421L959.865263 530.445474V294.696421z" fill="#0068D6"
                        p-id="30718"></path>
                  <path
                      d="M726.568421 363.789474a20.210526 20.210526 0 0 1 0 40.421052H377.263158a20.210526 20.210526 0 0 1 0-40.421052h349.305263zM718.901895 498.526316a20.210526 20.210526 0 0 1 0 40.421052H377.263158a20.210526 20.210526 0 0 1 0-40.421052h341.638737zM592.842105 633.263158a20.210526 20.210526 0 0 1 0 40.421053H377.263158a20.210526 20.210526 0 0 1 0-40.421053h215.578947z"
                      fill="#FFFFFF" p-id="30719"></path>
                </svg>
              </div>
              <p class="bottom-text center">发帖子</p>
            </div>
          </div>
          <div class="col-3 pointer" @click="goLearnDevelopment">
            <div class="">
              <div class="icon" style="background: #fffbcc;">
                <svg height="32" p-id="11233" t="1733797672478"
                     version="1.1" viewBox="0 0 1024 1024" width="32" xmlns="http://www.w3.org/2000/svg">
                  <path
                      d="M503.296 228.352l-38.912-122.88h-437.76v811.52H1000.96V228.352h-497.664z m-154.112 442.368l-74.752 71.68-164.352-158.72 170.496-165.376 74.752 71.68-96.768 93.184 90.624 87.552z m97.792 33.792l-74.752-71.68 193.024-187.392 74.752 71.68-193.024 187.392zM747.52 742.4l-74.752-71.68 90.112-87.04-96.768-93.184 74.752-71.68L911.36 583.68l-163.84 158.72z"
                      fill="#fca400" p-id="11234"></path>
                </svg>
              </div>
              <p a class="bottom-text center">学开发</p>
            </div>
          </div>
          <div class="col-3 pointer" @click="toFavourPosts">
            <div class="">
              <div class="icon" style="background: #f4e3fc;">
                <svg data-spm-anchor-id="a313x.search_index.0.i3.228c3a81lguDvp" height="40" p-id="3168"
                     t="1733899558331" version="1.1"
                     viewBox="0 0 1136 1024" width="32" xmlns="http://www.w3.org/2000/svg">
                  <path
                      class=""
                      d="M1115.391709 368.15586l-138.028541-19.652149-30.776008-4.634941-143.49777-21.876922a48.759578 48.759578 0 0 1-30.776008-16.685787 34.391262 34.391262 0 0 1-7.415906-10.753063l-5.561929-11.031159L625.01496 13.953675a24.565187 24.565187 0 0 0-44.310036 0L446.384337 285.932008 440.915106 296.685071a54.13611 54.13611 0 0 1-7.415905 10.753063A48.388783 48.388783 0 0 1 402.630494 324.494717l-143.775867 20.579137-30.776008 4.634941-135.896467 18.539764a24.935982 24.935982 0 0 0-13.812124 42.456059L185.529862 514.805391V376.498754a15.6661 15.6661 0 0 1 9.269881-13.719425 15.944197 15.944197 0 0 1 16.314992 1.668578l149.430496 116.800511 6.118122 4.913038a34.669358 34.669358 0 0 0 7.137809 4.264145 29.941718 29.941718 0 0 0 21.228029 0l86.209901-26.882657 18.539764-5.840025 83.428936-26.233766a15.480703 15.480703 0 0 1 19.095957 20.39374l-67.762836 184.563347a30.961405 30.961405 0 0 0 3.244458 28.643935l23.174705 30.776008 15.388004 22.247716L652.731906 825.068336a14.739112 14.739112 0 0 1 0 18.539763 13.904823 13.904823 0 0 1-12.607039 6.118122h-18.539764l-178.352526-3.707952a30.961405 30.961405 0 0 0-25.955669 12.143545l-106.603642 143.868566-7.04511 9.269882 23.638199-12.607039L580.426828 865.948515a49.130374 49.130374 0 0 1 46.349409 0l253.160473 133.671696 26.233766 13.534027a22.525813 22.525813 0 0 0 22.433114 0 23.823596 23.823596 0 0 0 13.626726-25.86297l-35.31825-206.718365-7.601303-42.456059L889.021194 676.750226a49.501169 49.501169 0 0 1 13.255931-46.349409l225.721623-220.623188a24.935982 24.935982 0 0 0-12.607039-41.621769z m-845.135127 636.933581l-4.913038-17.798173a22.989307 22.989307 0 0 0 4.913038 17.798173z"
                      data-spm-anchor-id="a313x.search_index.0.i0.228c3a81lguDvp" fill="#d398e0"
                      p-id="3169"></path>
                  <path
                      class=""
                      d="M300.754493 778.718927l7.04511-42.270662 10.753063-61.552015a49.686567 49.686567 0 0 0-14.09022-44.495433L185.529862 514.805391v59.141846a40.880179 40.880179 0 0 1 0 8.250195 30.683309 30.683309 0 0 1-12.328943 17.24198l-77.588911 50.891651-16.036896 10.474967-72.675873 48.944976a15.6661 15.6661 0 0 0 3.707952 27.809645l187.251613 61.644714a30.683309 30.683309 0 0 1 20.022945 20.857235l10.753063 37.913816 7.415906 25.862971 30.776007 101.9687zM278.22868 1011.856455a26.511862 26.511862 0 0 1-7.972098-6.767014l1.853976 6.210821a15.017209 15.017209 0 0 0 14.461016 11.031159 14.461016 14.461016 0 0 0 12.885135-6.118122l4.078748-5.283832h-2.224771a23.360102 23.360102 0 0 1-23.082006 0.926988z"
                      data-spm-anchor-id="a313x.search_index.0.i2.228c3a81lguDvp" fill="#d398e0"
                      p-id="3170"></path>
                  <path
                      d="M417.276908 857.605621a30.868707 30.868707 0 0 1 26.141066-12.050846l178.90872 6.488917h18.539763a13.904823 13.904823 0 0 0 12.60704-6.118122 14.739112 14.739112 0 0 0 0-18.539764l-75.549537-105.027761-15.388004-22.155018-23.082006-30.776007a30.683309 30.683309 0 0 1-3.707953-28.643935l67.392041-184.841444A15.388004 15.388004 0 0 0 584.134781 435.733299l-83.428937 26.141066-18.539764 5.840026-86.858792 27.160754a30.961405 30.961405 0 0 1-21.22803 0 33.464273 33.464273 0 0 1-7.137809-4.356845l-6.118122-4.913037-149.0597-116.800511a15.758799 15.758799 0 0 0-25.306777 11.958147V519.162235l118.005596 111.238582a49.686567 49.686567 0 0 1 14.09022 44.124638l-10.753063 61.552015-7.04511 42.177962-35.410949 206.996462 4.913038 17.890872a29.478224 29.478224 0 0 0 7.972098 6.767014 23.730898 23.730898 0 0 0 23.082006 0h2.224771l7.045111-9.269882z"
                      fill="#76AADC" p-id="3171"></path>
                  <path
                      class="selected"
                      d="M417.276908 857.605621a30.868707 30.868707 0 0 1 26.141066-12.050846l178.90872 6.488917h18.539763a13.904823 13.904823 0 0 0 12.60704-6.118122 14.739112 14.739112 0 0 0 0-18.539764l-75.549537-105.027761-15.388004-22.155018-23.082006-30.776007a30.683309 30.683309 0 0 1-3.707953-28.643935l67.392041-184.841444A15.388004 15.388004 0 0 0 584.134781 435.733299l-83.428937 26.141066-18.539764 5.840026-86.858792 27.160754a30.961405 30.961405 0 0 1-21.22803 0 33.464273 33.464273 0 0 1-7.137809-4.356845l-6.118122-4.913037-149.0597-116.800511a15.758799 15.758799 0 0 0-25.306777 11.958147V519.162235l118.005596 111.238582a49.686567 49.686567 0 0 1 14.09022 44.124638l-10.753063 61.552015-7.04511 42.177962-35.410949 206.996462 4.913038 17.890872a29.478224 29.478224 0 0 0 7.972098 6.767014 23.730898 23.730898 0 0 0 23.082006 0h2.224771l7.045111-9.269882z"
                      data-spm-anchor-id="a313x.search_index.0.i4.228c3a81lguDvp" fill="#bf5cd6"
                      p-id="3172"></path>
                </svg>
              </div>
              <p class="bottom-text center">收藏夹</p>
            </div>
          </div>
          <div class="col-3 pointer" href=" " @click="thanks">
            <div class="">
              <div class="icon" style="background: #ffeaeb;">
                <svg class="" height="32" p-id="7279" t="1734007329627"
                     version="1.1" viewBox="0 0 1024 1024" width="32" xmlns="http://www.w3.org/2000/svg">
                  <path
                      d="M564.162534 162.117228c208.181243 0 377.548274 169.367032 377.548275 377.547913s-169.367032 377.548274-377.548275 377.548274S186.61426 747.846384 186.61426 539.665141 355.981292 162.117228 564.162534 162.117228m0-39.741886c-230.093575 0-417.29016 187.194418-417.29016 417.289799 0 230.095742 187.196585 417.29016 417.29016 417.29016s417.289799-187.194418 417.289799-417.29016c0-230.095381-187.196224-417.289799-417.289799-417.289799z"
                      fill="#fc3e3e" p-id="7280"></path>
                  <path
                      class="selected"
                      d="M488.652807 46.865615c-230.093575 0-417.29016 187.194418-417.29016 417.29016s187.196585 417.289799 417.29016 417.289799 417.29016-187.194418 417.29016-417.289799-187.196585-417.29016-417.29016-417.29016z m169.458065 515.915161c19.075484 0 34.539251 15.460155 34.539251 34.541058 0 19.073678-15.464129 34.531665-34.539251 34.531665h-141.700807v54.31916c0 13.197323-15.462322 23.889781-34.541418 23.889781-19.075484 0-34.539251-10.692458-34.539251-23.889781v-54.31916H319.190769c-19.07151 0-34.535639-15.457987-34.535639-34.531665 0-19.080902 15.464129-34.541057 34.535639-34.541058h128.138627v-64.668075l-0.890828-2.268612H319.190769c-19.07151 0-34.535639-15.461961-34.535639-34.533471 0-19.079096 15.464129-34.541419 34.535639-34.541418h100.19894L356.301354 265.667428c-1.137196-5.639017-1.692066-10.719551-1.692067-15.240879 1.113715-20.32322 11.852773-31.042771 32.1778-32.1778 12.407644 0 22.01348 7.358538 28.789693 22.017093l67.739732 179.511843 66.04586-179.511843c7.886315-14.658916 18.047022-22.017093 30.483927-22.017093 20.321053 2.278004 31.593307 12.989608 33.869866 32.1778 0 4.521329-1.139002 9.601863-3.3863 15.240879l-63.090162 161.102133h110.871892c19.075484 0 34.539251 15.461961 34.539251 34.541418 0 19.07151-15.464129 34.533471-34.539251 34.533472h-137.920751l-3.780056 9.652075v57.28425h141.700084z"
                      data-spm-anchor-id="a313x.search_index.0.i11.11373a81rD6i2U" fill="#fc3e3e"
                      p-id="7281"></path>
                </svg>
              </div>
              <p class="bottom-text center">积功德</p>
            </div>
          </div>
        </div>
        <div class="rounded h-50 w-100 p-1">
          <img alt="超级大肥猫" class="rounded h-100 w-100"
               src="http://snc013327.hn-bkt.clouddn.com/0a8b42538fbd4e929a15ac03c7a75dcb.png" @click="clickFatCat"/>
        </div>
      </div>
    </div>
  </div>
</template>


<style scoped>
  #home {
    background: #f0f2f5;
  }

  .post-content {
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    -webkit-line-clamp: 4;
    /* autoprefixer: ignore next */
    -webkit-box-orient: vertical;
  }

  .post-title {
    color: #333333;
    font-size: 2ch;
    font-weight: bolder;
    cursor: pointer;
  }

  .pointer {
    cursor: pointer;
  }

  .bottom-text {
    color: #6b7280;
    font-weight: 600;
    font-size: 12px;
  }

  .circle {
    border-radius: 50%;
  }

  .icon {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex; /* 使用 Flexbox 布局 */
    justify-content: center; /* 水平居中 */
    align-items: center; /* 垂直居中 */
    margin-bottom: 10%;
  }

  .action {
    display: inline-block;
    padding: 0 4px;
    color: var(--color-text-1);
    line-height: 24px;
    background: transparent;
    border-radius: 2px;
    cursor: pointer;
    transition: all 0.1s ease;
  }

  .action:hover {
    background: var(--color-fill-3);
  }

  .user-message {
    color: #98dcff;
  }

</style>

<script lang="ts" setup>
  import {onMounted, onUnmounted, ref} from 'vue';
  import store from "../store";
  import message from "@arco-design/web-vue/es/message";
  import {useRouter} from "vue-router";
  import {PostControllerService, PostFavourControllerService, PostThumbControllerService} from "../../generated";
  import user from "@/store/user";
  import {IconHeart, IconHeartFill, IconStar, IconStarFill,} from '@arco-design/web-vue/es/icon';
  import formatDate from "@/access/Date";

  interface Post {
    id: number;
    title: string;
    content: string;
    createTime: string;
    tagList: [];
    thumbNum: number;
    favourNum: number;
    user: {
      userAccount: string;
      userName: string;
      userAvatar: string;
      userRole: string;
    };
    hasThumb: boolean;
    hasFavour: boolean;
  }

  const router = useRouter();
  const userRole = store.state.user.loginUser.userRole;
  const value = ref();

  // const getRole = (userRole = ACCESS_ENUM.NOT_LOGIN) => {
  //   switch (userRole) {
  //     case ACCESS_ENUM.ADMIN:
  //       return "管理员";
  //     case ACCESS_ENUM.USER:
  //       return "用户";
  //     default:
  //       return "游客";
  //   }
  // }

  const total = ref(0);
  const datalist = ref<Post[]>([]);
  const searchParams = ref({
    searchText: "",
    current: 1,
    pageSize: 6,
  });

  const loadData = async () => {
    // 清空现有数据
    if (searchParams.value.current === 1) {
      datalist.value = [];
    }
    const res = await PostControllerService.listPostVoByPageUsingPost(searchParams.value);
    if (res.code === 0) {
      // 过滤掉重复的数据
      const newPosts = res.data.records.filter((newPost: Post) =>
          !datalist.value.some(existingPost => existingPost.id === newPost.id)
      );
      datalist.value.push(...newPosts);
      total.value = res.data.total;
    } else {
      message.error("加载失败," + res.message);
    }
  }


  onMounted(() => {
    loadData();
  });
  const goLearnDevelopment = () => {
    // 开一个新的页面
    window.open('http://image.niwenjia.cn/JavaStudy.pdf');
  }
  const todo = () => {
    message.info("功能暂未开放");
  }

  // 跳转到问题页面
  const toQuestions = () => {
    window.location.href = '/question';
  }

  // 跳转到发布帖子页面
  const toAddPost = () => {
    router.push('/add/post');
  }

  const toPost = (id: string) => {
    router.push({
      path: `/view/post/${id}`,
    });
  };

  const getTime = (time: string) => {
    // 2024-12-06T00:30:36.000+00:00 to 2024-12-06 00:30:36
    return time.split("T")[0];
  }

  // 滑动事件监听
  let shouldMessage = true;
  const handleScroll = () => {
    // 获取滚动容器
    const scrollContainer = document.documentElement;
    // 判断是否已滚动到底部
    if (
        scrollContainer.scrollHeight - scrollContainer.scrollTop <= scrollContainer.clientHeight + 50
    ) {
      // 如果当前数据未加载完，则加载下一页
      if (datalist.value.length < total.value) {
        searchParams.value.current += 1; // 页码加1
        loadData(); // 加载下一页数据
      } else {
        // 如果数据已全部加载完，提示用户
        if (shouldMessage) {
          // message.info('没有更多了~');
          shouldMessage = false;
        }
      }
    }
  };
  const doSearch = async () => {
    shouldMessage = true;
    searchParams.value.current = 1;
    datalist.value = []; // 清空现有数据
    await loadData();
    if (datalist.value.length == 0) {
      // message.info('没有找到');
    }
  }
  onMounted(() => {
    loadData(); // 页面初次加载时，加载第一页数据
    // 为滚动事件绑定监听器
    window.addEventListener('scroll', handleScroll);

    // 监听回车
    window.addEventListener('keyup', (event) => {
      // Message.info("keyup: " + event.key);
      if (event.key === 'Enter') {
        doSearch();
      }
    });
  });

  // 在组件销毁时移除滚动监听，避免内存泄漏
  onUnmounted(() => {
    window.removeEventListener('scroll', handleScroll);
  });

  const images = [
    'http://image.niwenjia.cn/a048cf0442114323b216ab354080d147.png',
    'http://image.niwenjia.cn/d30450707d63479ca94719c5c9d47b03.png',
    'http://image.niwenjia.cn/f557f1455953443ab9da984a3ff60603.png',
  ];
  const handleChange = (value: any) => {
    // console.log(value)
  }

  let count = 1;
  const clickFatCat = () => {
    if (count == 2) {
      message.info("广告位招租~");
      count++;
      return;
    }
    message.clear();
    message.success(`你点了${count++}下超级大肥猫`);
  }

  const onLikeChange = async (item: Post) => {
    const res = await PostThumbControllerService.doThumbUsingPost({postId: item.id});
    if (res.code !== 0) {
      message.error("点赞失败" + res.message);
      return;
    }
    item.thumbNum = item.thumbNum + (item.hasThumb ? -1 : 1);
    item.hasThumb = !item.hasThumb;
    datalist.value = [...datalist.value];
  }
  const onStarChange = async (item: Post) => {
    const res = await PostFavourControllerService.doPostFavourUsingPost({postId: item.id});
    if (res.code !== 0) {
      message.error("收藏失败" + res.message);
      return;
    }
    item.favourNum = item.favourNum + (item.hasFavour ? -1 : 1);
    item.hasFavour = !item.hasFavour;
    datalist.value = [...datalist.value];
  };

  const toFavourPosts = () => {
    router.push('/view/favours');
  }
  const thanks = () => {
    const url = 'http://image.niwenjia.cn/77bb799ad59e4ecabec517ad27778544.jpg';
     window.open(url);
    // window.location.href = url;
  }
</script>
