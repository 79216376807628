<template>
  <div id="questionsView">
    <a-form :model="searchParams" class="w-100 justify-content-center" layout="inline">
      <a-form-item field="title" label="名称" style="min-width: 240px;width: 40%">
        <a-input v-model="searchParams.title" placeholder="请输入名称"/>
      </a-form-item>
      <a-form-item field="tags" label="标签" style="min-width: 240px;width: 40%">
        <a-input-tag v-model="searchParams.tags" placeholder="请输入标签"/>
      </a-form-item>
      <a-form-item style="width: 12%">
        <a-button class="w-100" type="primary" @click="doSubmit">提交</a-button>
      </a-form-item>
    </a-form>
    <a-divider size="0"/>
    <a-table
        :columns="columns"
        :data="datalist"
        :pagination="{pageSize: searchParams.pageSize,
        current: searchParams.current,
        total,
        showTotal: false
        }"
        @page-change="onPageChange"
    >
      <template #title="{record}">
        <span class="title" @click="toQuestionPage(record)">{{ record.title }}</span>
      </template>
      <template #tags="{record}">
        <a-tag v-for="tag in record.tags" :key="tag.id" :color="getTagColor(tag)" style="margin-right: 1vh;">
          {{ tag }}
        </a-tag>
      </template>
      <template #acceptedRate="{ record }">
        {{
          `${
              record.submitNum
                  ? `${((record.acceptedNum / record.submitNum) * 100).toFixed(2)}% (${record.acceptedNum}/${record.submitNum})`
                  : "0.00% (0/0)"
          }`
        }}
      </template>
      <template #createTime="{ record }">
        {{ formatDate(record.createTime) }}
      </template>
    </a-table>
  </div>
</template>

<style scoped>
  .title {
    cursor: pointer;
  }

  #questionsView {
    max-width: 1280px;
    margin: 0 auto;
  }
</style>

<script lang="ts" setup>
  import {onMounted, ref, watchEffect} from 'vue';
  import {Question, QuestionControllerService} from "../../../generated";
  import message from "@arco-design/web-vue/es/message";
  import {useRouter} from "vue-router";


  const total = ref(0);
  const datalist = ref([]);
  const searchParams = ref({
    title: "",
    tags: [],
    pageSize: 10,
    current: 1,
  });

  const loadData = async () => {
    const res = await QuestionControllerService.listQuestionVoByPageUsingPost(searchParams.value);
    if (res.code === 0) {
      datalist.value = res.data.records;
      total.value = res.data.total;
    } else {
      message.error("加载失败," + res.message);
    }
  }
  // 初始化时请求数据
  onMounted(() => {
    loadData();
  });

  const columns = [
    {
      title: "题目名称",
      slotName: "title",
    },
    {
      title: "标签",
      slotName: "tags",
    },
    {
      title: "通过率",
      slotName: "acceptedRate",
    },
    {
      title: "创建时间",
      slotName: "createTime",
    },
    // {
    //   slotName: "optional",
    // },
  ];

  const router = useRouter();

  const onPageChange = (page: number) => {
    searchParams.value = {
      ...searchParams.value,
      current: page
    }
  }

  // 监听数据变化
  watchEffect(() => {
    loadData();
  });

  const getTagColor = (tag: string) => {
    switch (tag) {
      case '简单':
        return 'green';
      case '中等':
        return 'orangered';
      case '困难':
        return 'red';
      default:
        return 'magenta';
    }
  }

  // 创建时间改为YYYY-MM-DD格式
  const formatDate = (date: string) => {
    const d = new Date(date);
    return d.toLocaleDateString();
  }
  /**
   * 跳转到做题页面
   * @param question
   */
  const toQuestionPage = (question: Question) => {
    router.push({
      path: `/view/question/${question.id}`,
    });
  };

  /**
   * 确认搜索，重新加载数据
   */
  const doSubmit = () => {
    // 这里需要重置搜索页号
    searchParams.value = {
      ...searchParams.value,
      current: 1,
    };
  };

</script>
