<template>
  <h1 class="center" style="font-size: 130px;">无权限</h1>
  <h1 class="center" style="font-size: 100px;">朋友，你这样是不对的</h1>
  <h1 v-for="i in cnt" :key="i"  class="center"> <span style="font-size: 70px; color: red; font-weight: 600;" class="center;">行为已记录</span></h1>
</template>

<script lang="ts" setup>
  import {ref} from 'vue';

  const cnt = ref(0);
  setInterval(() => {
    cnt.value++;
    // 滚轮向下
    // window.scrollTo(0, document.body.scrollHeight);
  }, 400);
</script>
