import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import {ref} from 'vue';

  
export default /*@__PURE__*/_defineComponent({
  __name: 'NoAuthView',
  setup(__props) {

  const cnt = ref(0);
  setInterval(() => {
    cnt.value++;
    // 滚轮向下
    // window.scrollTo(0, document.body.scrollHeight);
  }, 400);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _cache[1] || (_cache[1] = _createElementVNode("h1", {
      class: "center",
      style: {"font-size":"130px"}
    }, "无权限", -1)),
    _cache[2] || (_cache[2] = _createElementVNode("h1", {
      class: "center",
      style: {"font-size":"100px"}
    }, "朋友，你这样是不对的", -1)),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(cnt.value, (i) => {
      return (_openBlock(), _createElementBlock("h1", {
        key: i,
        class: "center"
      }, _cache[0] || (_cache[0] = [
        _createElementVNode("span", {
          style: {"font-size":"70px","color":"red","font-weight":"600"},
          class: "center;"
        }, "行为已记录", -1)
      ])))
    }), 128))
  ], 64))
}
}

})